const SET_STATE = 'scratch-gui/overlay/SET_STATE';
const SET_ACCEPT = 'scratch-gui/overlay/SET_ACCEPT';
const SET_CANCEL = 'scratch-gui/overlay/SET_CANCEL';

const initialState = {
    isVisible: false,
    textContent: '',
    isConfirm: null,
    isSelected: false,
    resolve: null
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_STATE:
        return Object.assign({}, state, {
            isVisible: true,
            textContent: action.textContent,
            isConfirm: action.isConfirm,
            resolve: action.resolve
        });
    case SET_ACCEPT:
        if (state.resolve) state.resolve(true);
        return Object.assign({}, state, {
            isVisible: false,
            resolve: null
        });
    case SET_CANCEL:
        if (state.resolve) state.resolve(false);
        return Object.assign({}, state, {
            isVisible: false,
            resolve: null
        });
    default:
        return state;
    }
};

const setState = function (textContent, isConfirm) {
    return {type: SET_STATE, textContent: textContent, isConfirm: isConfirm};
};
const onAccept = function () {
    return {type: SET_ACCEPT};
};
const onCancel = function () {
    return {type: SET_CANCEL};
};

const onAlert = function (text, resolve){
    return {type: SET_STATE, textContent: text, isConfirm: false, resolve: resolve};
};
const onConfirm = function (text, resolve){
    return {type: SET_STATE, textContent: text, isConfirm: true, resolve: resolve};
};

export {
    reducer as default,
    initialState as overlayStateInitialState,
    setState,
    onAccept,
    onCancel,
    onAlert,
    onConfirm
};
