import React from 'react';
import {connect} from 'react-redux';
import styles from './select-mode.css';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import {injectIntl} from 'react-intl';
import TextCard, {LargeTextCard} from '../../text-card/text-card.jsx';
import {config} from 'dotenv';
config();
const env = process.env;
const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;

const LOGO_IMAGE = env.DEPLOY_MODE === 'local' ?
    `${env.LOCAL_API_SERVER}ui/pikachu-programming-logo.png` :
    `${AWS_S3_URL}assets/ui/pikachu-programming-logo.png`;
// const LOGO_SIZE = 500;

class SelectMode extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectClassMode',
            'handleSelectFreeMode',
            'handleSelectForTeacher',
            'handlePreventDragDropRightClick'
        ]);
    }

    componentDidMount () {
        this.onSetImageToCanvas('logoCanvas', LOGO_IMAGE);
    }

    onSetImageToCanvas (id, src) {
        const image = new Image();
        image.onload = () => {
            const canvas = document.getElementById(id);
            if (!canvas) return;
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            // const aspectRate = image.height / image.width;
            ctx.drawImage(image, 0, 0);
        };
        image.src = src;
    }

    handleSelectClassMode () {
        this.props.onSelectClassMode();
    }

    handleSelectFreeMode () {
        this.props.onSelectFreeMode();
    }

    handleSelectForTeacher () {
        this.props.onSelectTeacherTool();
    }
    handlePreventDragDropRightClick = event => {
        event.preventDefault();
    };

    render () {
        return (
            <>
                <div className={styles.container}>
                    <div
                        className={styles.logoContainer}
                        onDragStart={this.handlePreventDragDropRightClick}
                        onContextMenu={this.handlePreventDragDropRightClick}
                    >
                        <canvas
                            id="logoCanvas"
                            onDragStart={this.handlePreventDragDropRightClick}
                            onContextMenu={this.handlePreventDragDropRightClick}
                        />
                    </div>
                    <div className={styles.selectModeContainer} >
                        <div className={styles.selectModeCards}>
                            <TextCard
                                onClick={this.handleSelectClassMode}
                                reversalMode
                            >
                                <h2><ruby>{'授業'}<rt>{'じゅぎょう'}</rt></ruby>{'モード'}</h2>
                            </TextCard>

                            <TextCard
                                onClick={this.handleSelectFreeMode}
                                reversalMode
                            >
                                <h2>{'フリーモード'}</h2>
                            </TextCard>
                        </div>
                    </div>
                    {this.props.selectedTeacher &&
                        <div className={styles.forTeacherContainer} >
                            <LargeTextCard
                                onClick={this.handleSelectForTeacher}
                            >
                                <h2 >{'先生のみなさまへ'}</h2>
                                <p>
                                    {'本ツールの使用方法の説明、「授業内容」別の指導案・指導用スライド'}
                                </p>
                            </LargeTextCard>
                        </div>
                    }
                </div>
            </>
        );
    }
}

SelectMode.propTypes = {
    onSelectClassMode: PropTypes.func,
    onSelectFreeMode: PropTypes.func,
    selectedTeacher: PropTypes.bool,
    onSelectTeacherTool: PropTypes.func
};

const mapStateToProps = state => ({
    selectedTeacher: state.scratchGui.userState
});

const mapDispatchToProps = () => ({
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectMode));
