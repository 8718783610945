import {config} from 'dotenv';

config();
const env = process.env;
const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;
const ORIGIN_URL = env.DEPLOY_MODE === 'local' ? env.LOCAL_API_SERVER : `${AWS_S3_URL}assets/`;

const CURRICURUM_DIR = num => `${ORIGIN_URL}teacher-tools/curricurum${num}`;

const TEACHER_DL_ZIP_ = `/all-data.zip`;

export const toolDatas = [
    {
        id: 1,
        dir: CURRICURUM_DIR(1),
        allDatas: {
            name: 'ポケモンのゲームを作ってみよう_指導用資料',
            src: TEACHER_DL_ZIP_
        },
        slides:
            [
                {
                    name: '基本編',
                    src: `/pdfs/slide-basic.pdf`,
                    thumbnail: `/thumbnail/basic.JPG`
                },
                {
                    name: '応用編',
                    src: `/pdfs/slide-applied.pdf`,
                    thumbnail: `/thumbnail/applied.JPG`
                }
            ],
        teachingPlans:
            [
                {
                    name: '標準版',
                    src: `/pdfs/teachingplan-default.pdf`,
                    thumbnail: `/thumbnail/teachingplan-default.jpg`
                },
                {
                    name: '短縮版',
                    src: `/pdfs/teachingplan-short.pdf`,
                    thumbnail: `/thumbnail/teachingplan-short.jpg`
                }
            ],
        hintCards:
            [
                {
                    src: `/pdfs/hintcards.pdf`,
                    thumbnail: `/thumbnail/hintcard.png`
                }
            ]

    },
    {
        id: 2,
        dir: CURRICURUM_DIR(2),
        allDatas: {
            name: '多角形と円_指導用資料',
            src: TEACHER_DL_ZIP_
        },
        slides:
            [
                {
                    src: `/pdfs/math-slide.pdf`,
                    thumbnail: `/thumbnail/math-slide.JPG`
                }
            ],
        teachingPlans:
            [
                {
                    src: `/pdfs/math-teachingplan.pdf`,
                    thumbnail: `/thumbnail/math-teachingplan.jpg`
                }
            ],
        workSheet: [
            {
                src: `/pdfs/math-worksheet.pdf`,
                thumbnail: `/thumbnail/math-worksheet.png`
            }
        ]
    },
    {
        id: 3,
        dir: CURRICURUM_DIR(3),
        allDatas: {
            name: 'ポケモンたちと物語を作ってみよう_指導用資料',
            src: TEACHER_DL_ZIP_
        },
        slides:
            [
                {
 
                    src: `/pdfs/japanese-slide.pdf`,
                    thumbnail: `/thumbnail/japanese-slide.JPG`
                }
            ],
        teachingPlans:
            [
                {
                    src: `/pdfs/japanese-teachingplan.pdf`,
                    thumbnail: `/thumbnail/japanese-teachingplan.jpg`
                }
            ],
        workSheet: [
            {
                src: `/pdfs/japanese-worksheet.pdf`,
                thumbnail: `/thumbnail/japanese-worksheet.png`
            }
        ]
    }
];
