import React from 'react';
import {FormattedMessage} from 'react-intl';
import {config} from 'dotenv';
config();
const env = process.env;
const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;
const ASSETS_URL = env.DEPLOY_MODE === 'local' ? `${env.LOCAL_API_SERVER}tips/` : `${AWS_S3_URL}assets/tips/`;

export default {
    // 'hint-Movie-A': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Add mote Pikachu"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.add-more-pikachu.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: A,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_A.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-B'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-A'
    // },
    // 'hint-Movie-B': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Add Backdrop"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.add-backdrop.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: B,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_B.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-C'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-B'
    // },
    // 'hint-Movie-C': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Change Pose"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.change-pose.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: C,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_C.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-D'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-C'
    // },
    // 'hint-Movie-D': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Sound Effect"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.sound-effect.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: D,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_D.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-E'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-D'
    // },
    // 'hint-Movie-E': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Display the number of catches as a score"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.display-catch-score.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: E,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_E.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-F'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-E'
    // },
    // 'hint-Movie-F': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Catch by clicking with the mouse"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.catch-by-click.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: F,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_F.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-G'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-F'
    // },
    // 'hint-Movie-G': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Reduce the Rate of Appearance"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.reduce-rate-appearance.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: G,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_G.mp4'

    //        }, {
    //            deckIds: [
    //                'hint-Movie-H'
    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-G'
    // },
    // 'hint-Movie-H': {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Comment if you catch 10 pcs"
    //            description="Name for the 'Add ' how-to"
    //            id="gui.howtos.comment-ten-pcs.name"
    //        />
    //    ),
    //    tags: ['tipsMovie', 'scratchPokemonGameMovie'],
    //    img: H,
    //    steps: [
    //        {
    //            video: 'static/videos/ICT_Hint_H.mp4'

    //        }, {
    //            deckIds: [

    //            ]
    //        }
    //    ],
    //    urlId: 'hint-Movie-H'
    // },
    'move-monsterball': {
        name: (
            <FormattedMessage
                defaultMessage="Move Mosterball"
                description="Name for the 'Add Effects' how-to"
                id="gui.howtos.move-monsterball.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['basic', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/basic_Ed_move_monsterball.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Move Monsterball"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.move-monsterball"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/basic_Ed_move_monsterball.png`
            }, {
                deckIds: [
                    'move-pikachu'
                ]
            }
        ],
        urlId: 'move-monsterball'
    },
    'move-pikachu': {
        name: (
            <FormattedMessage
                defaultMessage="Move Pikachu"
                description="Name for the 'Add Effects' how-to"
                id="gui.howtos.move-pikachu.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['basic', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/basic_Ed_move_Pikachu.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Move Pikachu"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.move_pikachu"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/basic_Ed_move_Pikachu.png`
            }, {
                deckIds: [
                    'get-pikachu'
                ]
            }
        ],
        urlId: 'move-pikachu'
    },
    'get-pikachu': {
        name: (
            <FormattedMessage
                defaultMessage="Get Pikachu"
                description="Name for the 'Add Effects' how-to"
                id="gui.howtos.get-pikachu.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['basic', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/basic_Ed_get_Pikachu.png`,
        steps: [
            {
                title: (
                    <FormattedMessage
                        defaultMessage="Get Pikachu"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.get_pikachu"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/basic_Ed_get_Pikachu.png`
            }, {
                deckIds: [
                    'add-more-pikachu'
                ]
            }
        ],
        urlId: 'get-pikachu'
    },

    'add-more-pikachu': {
        name: (
            <FormattedMessage
                defaultMessage="Add mote Pikachu"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.add-more-pikachu.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN', 'CHARIZARD', 'BOOK'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-A-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Add Pikachu 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.add-more-pikachu-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-A-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Add Pikachu 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.add-more-pikachu-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-A-ura.png`
            },
            {
                deckIds: [
                    'add-backdrop'
                ]
            }
        ],
        urlId: 'add-more-pikachu'
    },

    'add-backdrop': {
        name: (
            <FormattedMessage
                defaultMessage="Add Backdrop"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.add-backdrop.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN', 'CHARIZARD', 'BOOK'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-B-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Add Backdrop 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.add-backdrop-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-B-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Add Backdrop 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.add-backdrop-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-B-ura.png`
            },
            {
                deckIds: [
                    'change-pose'
                ]
            }
        ],
        urlId: 'add-backdrop'
    },

    'change-pose': {
        name: (
            <FormattedMessage
                defaultMessage="Change Pose"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.change-pose.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN', 'CHARIZARD', 'BOOK'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-C-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Change Pose 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.change-pose-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-C-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Change Pose 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.change-pose-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-C-ura.png`
            },
            {
                deckIds: [
                    'sound-effect'
                ]
            }
        ],
        urlId: 'change-pose'
    },
    'sound-effect': {
        name: (
            <FormattedMessage
                defaultMessage="Sound Effect"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.sound-effect.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN', 'CHARIZARD', 'BOOK'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-D-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Sound Effect 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.sound-effect-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-D-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Sound Effect 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.sound-effect-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-D-ura.png`
            },
            {
                deckIds: [
                    'display-catch-score'
                ]
            }
        ],
        urlId: 'sound-effect'
    },
    'display-catch-score': {
        name: (
            <FormattedMessage
                defaultMessage="Display the number of catches as a score"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.display-catch-score.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-E-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Display Catch Score 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.display-catch-score-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-E-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Display Catch Score 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.display-catch-score-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-E-ura.png`
            },
            {
                deckIds: [
                    'catch-by-click'
                ]
            }
        ],
        urlId: 'display-catch-score'
    },
    'catch-by-click': {
        name: (
            <FormattedMessage
                defaultMessage="Catch by clicking with the mouse"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.catch-by-click.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-F-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Catch by clicking 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.catch-by-click-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-F-omote.png`
            },
            //{
            //    title: (

            //        <FormattedMessage
            //            defaultMessage="Display Catch Score 2"
            //            description="Step name for 'Add Effects' step"
            //            id="gui.howtos.catch-by-click-2"
            //        />
            //    ),
            //    image: `${ASSETS_URL}steps-pokemon/hint-F-ura.png`
            //},
            {
                deckIds: [
                    'reduce-rate-appearance'
                ]
            }
        ],
        urlId: 'catch-by-click'
    },

    'reduce-rate-appearance': {
        name: (
            <FormattedMessage
                defaultMessage="Reduce the Rate of Appearance"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.reduce-rate-appearance.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-G-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Reduce the Rate of Appearance 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.reduce-rate-appearance-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-G-omote.png`
            },
            //{
            //    title: (

            //        <FormattedMessage
            //            defaultMessage="Reduce the Rate of Appearance 2"
            //            description="Step name for 'Add Effects' step"
            //            id="gui.howtos.reduce-rate-appearance-2"
            //        />
            //    ),
            //    image: `${ASSETS_URL}steps-pokemon/hint-G-ura.png`
            //},
            {
                deckIds: [
                    'comment-ten-pcs'
                ]
            }
        ],
        urlId: 'reduce-rate-appearance'
    },
    'comment-ten-pcs': {
        name: (
            <FormattedMessage
                defaultMessage="Comment if you catch 10 pcs"
                description="Name for the 'Add ' how-to"
                id="gui.howtos.comment-ten-pcs.name"
            />
        ),
        modes: ['FREE', 'CLASS_MAIN'],
        tags: ['applied', 'scratchPokemonGameCard'],
        img: `${ASSETS_URL}steps-pokemon/hint-H-omote.png`,
        steps: [
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Comment if you catch 10 pcs 1"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.comment-ten-pcs-1"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-H-omote.png`
            },
            {
                title: (

                    <FormattedMessage
                        defaultMessage="Comment if you catch 10 pcs 2"
                        description="Step name for 'Add Effects' step"
                        id="gui.howtos.comment-ten-pcs-2"
                    />
                ),
                image: `${ASSETS_URL}steps-pokemon/hint-H-ura.png`
            },
            {
                deckIds: [
                    
                ]
            }
        ],
        urlId: 'comment-ten-pcs'
    }
};
