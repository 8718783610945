import messages from './pokemon-tag-message.js';
// 以下のタグを追加カリキュラムで追加
// {tag: 'flyCharizard', intlLabel: messages.flyCharizard},
// {tag: 'pokemonStoryMaking', intlLabel: messages.pokemonStoryMaking}

const freeModeTipsTag = [
    /* {tag: 'scratchPokemonGameMovie', intlLabel: messages.scratchPokemonGameMovie},*/
    {tag: 'scratchPokemonGameCard', intlLabel: messages.scratchPokemonGameCard}
];
const scratchPokemonGameTipsTag = [
    /* {tag: 'tipsMovie', intlLabel: messages.tipsMovie},*/
    {tag: 'basic', intlLabel: messages.basic},
    {tag: 'applied', intlLabel: messages.applied}
];

const scratchPokemonCharizardTag = [
    /* {tag: 'tipsMovie', intlLabel: messages.tipsMovie},*/
];
const scratchPokemonBookTag = [
    /* {tag: 'tipsMovie', intlLabel: messages.tipsMovie},*/
];
export {freeModeTipsTag, scratchPokemonGameTipsTag, scratchPokemonCharizardTag, scratchPokemonBookTag};
