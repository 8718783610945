import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import {injectIntl} from 'react-intl';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import styles from 'react-tabs/style/react-tabs.css';
import stylesDefault from './teacher-tool.css';

import {data} from '../../../lib/curriculums/data.jsx';
import CurricurumTools from './teacher-tools/curricurum-tools.jsx';

import {toolDatas} from '../../../lib/curriculums/teacher-tool-data.jsx';

import {config} from 'dotenv';
config();
const env = process.env;

const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;
const ORIGIN_URL = env.DEPLOY_MODE === 'local' ? env.LOCAL_API_SERVER : `${AWS_S3_URL}assets/`;

const LOGO_IMAGE = `${ORIGIN_URL}ui/pikachu-programming-logo.png`;
const LOGO_SIZE = 300;

const COMMOM_TEACHER_TOOLS_DIR = `${ORIGIN_URL}teacher-tools/common`;

const STARTING_PDF = `${COMMOM_TEACHER_TOOLS_DIR}/starting.pdf`;
const STARTING_PDF_THUMBNAIL = `${COMMOM_TEACHER_TOOLS_DIR}/starting.jpg`;

const TAB_LIST = data;
const TOOL_LIST = [
    {
        id: 0,
        tool: <CurricurumTools
            key={0}
            toolDatas={toolDatas[0]}
        />
    },
    {
        id: 1,
        tool: <CurricurumTools
            key={0}
            toolDatas={toolDatas[1]}
        />
    },
    {
        id: 2,
        tool: <CurricurumTools
            key={0}
            toolDatas={toolDatas[2]}
        />
    }
];

class TeacherTool extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handlePreventDragDropRightClick',
            'handleSelectStarting',
            'handleSelectTab'
        ]);
        this.state = {selectedTabIndex: 0};

    }
    componentDidMount () {
        this.onSetImageToCanvas('logoCanvas', LOGO_IMAGE);
    }

    onSetImageToCanvas (id, src) {
        const image = new Image();
        image.onload = () => {
            const canvas = document.getElementById(id);
            if (!canvas) return;
            canvas.width = LOGO_SIZE;
            canvas.height = LOGO_SIZE;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, LOGO_SIZE, LOGO_SIZE);
        };
        image.src = src;
    }

    handleSelectStarting () {
        window.open(STARTING_PDF, '_blank');
    }

    handlePreventDragDropRightClick = event => {
        event.preventDefault();
    };

    handleSelectTab (tabIndex) {
        const tempTabIndex = tabIndex;
        this.setState({selectedTabIndex: tempTabIndex});
    }

    render () {
        
        return (
            <div className={stylesDefault.teacherToolBody}>
                <div
                    className={stylesDefault.logoContainer}
                    onDragStart={this.handlePreventDragDropRightClick}
                    onContextMenu={this.handlePreventDragDropRightClick}
                >
                    <canvas
                        id="logoCanvas"
                        onDragStart={this.handlePreventDragDropRightClick}
                        onContextMenu={this.handlePreventDragDropRightClick}
                    />
                    
                </div>

                <div className={stylesDefault.toolContainer}>
                    <div className={stylesDefault.startingTextContainer}>
                        <h2>{'初めて利用される先生方へ'}</h2>
                        <div className={stylesDefault.startingTextWrapper}>
                            <div
                                className={stylesDefault.startingText}
                                onClick={this.handleSelectStarting}
                            >

                                <h3>{'はじめにお読みください'}</h3>
                                <img src={STARTING_PDF_THUMBNAIL} />
                                <p>{'クリックすると別タブで開きます'}<br /> {'ダウンロード可能です'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={stylesDefault.toolContainer}>
                    <Tabs
                        onSelect={this.handleSelectTab}
                        defaultIndex={0}
                    >
                        <TabList className={classNames(styles.reactTabsTabList, stylesDefault.tabs)}>
                            {
                                TAB_LIST.map(
                                    tab => {
                                        const onSelectedStyle =
                                            `classmode_${this.state.selectedTabIndex + 1}` === tab.id ?
                                                stylesDefault.selectedTab : null;
                                        return (<Tab
                                            key={tab.id}
                                            className={classNames(stylesDefault.tab, onSelectedStyle)}
                                        >
                                            {tab.titleJa}
                                        </Tab>);
                                    })
                            }
                        </TabList>

                        {
                            TOOL_LIST.map(
                                tool => (
                                    <TabPanel
                                        key={tool.id}
                                        className={stylesDefault.toolContainer}
                                    >
                                        <h1>「{TAB_LIST[tool.id].titleJa}」 先生用資料</h1>
                                        {tool.tool}
                                    </TabPanel>))
                        }
                    </Tabs>
                </div>
            </div>
        );
    }
}

// TeacherTool.propTypes = {
// };

export default injectIntl(TeacherTool);
