import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import CompetitionDevUI from '../components/competition-dev/competition-dev.jsx';

const messages = defineMessages({
    competitionTitle: {
        defaultMessage: 'CompetitionDev',
        description: 'Competition Title',
        id: 'gui.menuBar.competitionDevTitle'
    }
});


class COmpetitionDev extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);
    }

    handleClose () {
        this.props.onRequestClose();
    }

    render () {
        return (
            <CompetitionDevUI
                id={'competiion-modal'}
                title={this.props.intl.formatMessage(messages.competitionTitle)}
                onRequestClose={this.handleClose}
                onLoadProject={this.props.onLoadProject}
                onUpLoadProject={this.props.onUpLoadProject}
            />
        );
    }
}

COmpetitionDev.propTypes = {
    intl: intlShape,
    onRequestClose: PropTypes.func,
    onLoadProject: PropTypes.func,
    onUpLoadProject: PropTypes.func
};

export default injectIntl(COmpetitionDev);
