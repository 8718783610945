import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';

import VM from 'scratch-vm';

import dropdownCaret from './dropdown-caret.svg';
import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
// import {MenuItem} from '../menu/menu.jsx';
// import IndexedDBSaver from '../../containers/indexedDB-saver.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import SettingsMenu from './settings-menu.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import {
    openTipsLibrary,
    openFreeModeSaveUI,
    openFreeModeLoadUI,
    openPokemonHelpLibrary,
    openPokemonTipsLibrary,
    openFirstTimeUI,
    openLoadUI,
    openCompetition,
    openCompetitionDev

} from '../../reducers/modals';
import {
    closeCardHelps,
    selectFeature
} from '../../reducers/cards-help';
import {
    closeCards
} from '../../reducers/cards';

import {updateToolbox} from '../../reducers/toolbox';
import {setPlayer} from '../../reducers/mode';
import {setProjectUnchanged} from '../../reducers/project-changed';
import {setAutosaveState} from '../../reducers/autosave-state';
import {setProjectID} from '../../reducers/project-id';

import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openModeMenu,
    closeModeMenu,
    modeMenuOpen,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu
} from '../../reducers/menus';

import collectMetadata from '../../lib/collect-metadata';

import styles from './menu-bar-pokemon.css';
import menubarStyles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';

import aboutIcon from './icon--about.svg';
import backIcon from '../cards/icon--prev.svg';
import pikachuLogo from './pikachu-logo.svg';
import scratchLogo from './logo.png';
import editIcon from './icon--edit.svg';

// import sharedMessages from '../../lib/shared-messages';

const ariaMessages = defineMessages({
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    },
    openSaveUI: {
        id: 'gui.menuBar.openSaveUI',
        defaultMessage: 'Open SaveLoad',
        description: 'openSaveLoadUI'
    },
    openLoadUI: {
        id: 'gui.menuBar.openLoadUI',
        defaultMessage: 'Open SaveLoad',
        description: 'openSaveLoadUI'
    },
    save: {
        id: 'gui.menuBar.save',
        defaultMessage: 'Save',
        description: 'Save Project'
    },
    hint: {
        id: 'gui.menuBar.hint',
        defaultMessage: 'Hint',
        description: 'accessibility text for the hint button'
    },
    autosave: {
        id: 'gui.menuBar.autosave',
        defaultMessage: 'Autosave',
        description: 'accessibility text for the autosave toggle'
    },
    overwriteSave: {
        id: 'gui.menuBar.overwriteSave',
        defaultMessage: 'overwriteSave',
        description: 'accessibility text for the overwrite save'
    },
    nowSaving: {
        id: 'gui.menuBar.nowSaving',
        defaultMessage: 'ほぞんちゅうです',
        description: 'accessibility text for the nowSaving'
    },
    didSave: {
        id: 'gui.menuBar.didSave',
        defaultMessage: 'ほぞんしました',
        description: 'accessibility text for the didSave'
    },
    competitionTab: {
        id: 'gui.menuBar.competitionTab',
        defaultMessage: 'たいかいはこちら',
        description: 'accessibility text for the competitionTab'
    },
    forDeveloper: {
        id: 'gui.menuBar.forDeveloper',
        defaultMessage: '開発者用',
        description: 'accessibility text for the forDeveloper'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};


require('dotenv').config();
const env = process.env;

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            // 'handleClickNew',
            // 'handleClickRemix',
            // 'handleClickSave',
            // 'handleClickSaveAsCopy',
            // 'handleClickSeeCommunity',
            // 'handleClickShare',
            // 'handleSetMode',
            'handleKeyPress',
            'handleRestoreOption',
            'getSaveToComputerHandler',
            'restoreOptionMessage',
            'handleSelectToggle',
            'handleSelectToppage',
            'handleSelectCompetition',
            'handleSelectCompetitionDev',
            'handleOpenSaveUI',
            'handleOpenLoadUI',
            'handleClickLogo',
            'handleSaveProject',
            'handleHintCardButtonClick',
            'backToToppage'
        ]);
    }
    
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);

        this.onSetImageToCanvas('logo_img', pikachuLogo);

    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    onSetImageToCanvas (id, src) {
        const image = new Image();
        image.onload = () => {
            const canvas = document.getElementById(id);
            if (!canvas) return;
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
        };
        image.src = src;
    }

    handleSelectCompetition () {
        this.props.onCloseCards();
        this.props.onOpenCompetition();
    }

    handleSelectCompetitionDev () {
        this.props.onCloseCards();
        this.props.onOpenCompetitionDev();
    }
    handleSelectToggle () {
        console.log('change autosave state');
        this.props.onSetAutosaveState(!this.props.isActiveAutosave);
    }

    handleOpenSaveUI () {
        this.props.onCloseCards();
        this.props.openFreeModeSaveUI();
    }

    handleOpenLoadUI () {
        this.props.onCloseCards();
        this.props.openFreeModeLoadUI();
    }

    handleSaveProject () {
        this.props.onSaveProject();
    }

    handleSelectToppage () {
        this.backToToppage();
    }

    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress (event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    handleClickLogo () {
        this.backToToppage();
    }

    backToToppage = async () => {
        let accept = true;
        if (this.props.projectChanged || this.props.projectSavingState === true) {
            accept = await window.ownConfirm(
                <>
                    <p><ruby>保存<rt>ほぞん</rt></ruby>されていないデータがあります。</p>
                    <p>このまま「OK」を<ruby>押<rt>お</rt></ruby>すとロード<ruby>画面<rt>がめん</rt></ruby>に<ruby>戻<rt>もど</rt></ruby>
                    り、<ruby>保存<rt>ほぞん</rt></ruby>されていないデータは<ruby>失<rt>うしな</rt></ruby>われてしまいます。</p>
                    <p><ruby>本当<rt>ほんとう</rt></ruby>に<ruby>移動<rt>いどう</rt></ruby>しますか？</p>
                </>
            );
            
        }
        if (accept) {
            // this.props.onSaveProject();
            this.props.setProjectUnchanged();
            this.props.resetProjectID();
            // this.props.onOpenFirstTimeUI();
            this.props.onCloseCards();
            document.dispatchEvent(new Event('exitProject'));
            this.props.onOpenLoadUI();
        }
    }

    handleHintCardButtonClick () {
        this.props.onHintCardButtonClick();
        this.props.onOpenPokemonTipsLibrary();
    }
    getSaveToComputerHandler (downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();

            downloadProjectCallback(); // sb3-downloader - downloadProject - callback

            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    wrapAboutMenuCallback (callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    render () {
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                   

                    <div className={styles.fileGroup}>
                        <div className={styles.fileGroup}>
                            <div className={classNames(styles.menuBarItem)}>
                                {
                                    /*
                                    <img
                                        id="logo_img"
                                        alt="Scratch"
                                        className={classNames(styles.pikachuLogo, {
                                            [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                        })}
                                        draggable={false}
                                        src={pikachuLogo}
                                        onClick={this.handleClickLogo}
                                        onContextMenu={e => {
                                            e.preventDefault();
                                        }}
                                    />
                                    */
                                    <canvas
                                        id="logo_img"
                                        alt="Scratch"
                                        className={classNames(styles.pikachuLogo, {
                                            [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                        })}
                                        draggable={false}
                                        onClick={this.handleClickLogo}
                                        onContextMenu={e => {
                                            e.preventDefault();
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        {
                            this.props.selectedClassMode === 'FREE' ?
                                <>
                                    <div
                                        aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                                        className={classNames(styles.menuBarItem, styles.hoverable)}
                                        onClick={this.handleOpenSaveUI}
                                    >
                                        <span className={styles.tutorialsLabel}>
                                            <FormattedMessage {...ariaMessages.openSaveUI} />
                                        </span>
                                    </div>
                                    <div
                                        aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                                        className={classNames(styles.menuBarItem, styles.hoverable)}
                                        onClick={this.handleOpenLoadUI}
                                    >
                                        <span className={styles.tutorialsLabel}>
                                            <FormattedMessage {...ariaMessages.openLoadUI} />
                                        </span>
                                    </div>
                                </> : <div
                                    aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                                    className={classNames(styles.menuBarItem, styles.hoverable)}
                                    onClick={this.handleSaveProject}
                                >
                                    <span className={styles.tutorialsLabel}>
                                        <FormattedMessage {...ariaMessages.overwriteSave} />
                                    </span>
                                </div>
                        }

                        {/* <button onClick={() => this.props.onUpLoadProject()}>Upload</button>*/}
                        {/* <button onClick={() => this.props.onDownLoadProject()}>DownLoad</button>*/}
                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.autosave)}
                            className={styles.menuBarItem}
                        >
                            <div className={styles.autoSaveItemContainer}>
                                <span className={styles.collapsibleLabel}>
                                    {'オートセーブ'}
                                </span>
                                <label
                                    className={styles.toggleButton}
                                    checked={this.props.isActiveAutosave}
                                >
                                    <input
                                        id="toggle"
                                        type="checkbox"
                                        onClick={this.handleSelectToggle}
                                        checked={this.props.isActiveAutosave}
                                        readOnly
                                    />
                                </label>
                            </div>
                            
                        </div>
                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.autosave)}
                            className={styles.menuBarSaveMessage}
                        >
                            {
                                this.props.projectSavingState === true &&
                                <span className={styles.tutorialsLabel}>
                                    <FormattedMessage {...ariaMessages.nowSaving} />
                                </span>
                            }
                            {
                                this.props.projectSavingState === false &&
                                <span className={styles.tutorialsLabel}>
                                    <FormattedMessage {...ariaMessages.didSave} />
                                </span>
                            }
                        </div>
                        <Divider className={classNames(styles.divider)} />

                        <div className={styles.fileGroup}>
                            {(this.props.canChangeTheme || this.props.canChangeLanguage) && (<SettingsMenu
                                canChangeLanguage={this.props.canChangeLanguage}
                                canChangeTheme={this.props.canChangeTheme}
                                isRtl={this.props.isRtl}
                                onRequestClose={this.props.onRequestCloseSettings}
                                onRequestOpen={this.props.onClickSettings}
                                settingsMenuOpen={this.props.settingsMenuOpen}
                            />)}
                            <div
                                className={classNames(menubarStyles.menuBarItem, menubarStyles.hoverable, {
                                    [menubarStyles.active]: this.props.editMenuOpen
                                })}
                                onMouseUp={this.props.onClickEdit}
                            >
                                <img src={editIcon} />
                                <span className={menubarStyles.collapsibleLabel}>
                                    <FormattedMessage
                                        defaultMessage="Edit"
                                        description="Text for edit dropdown menu"
                                        id="gui.menuBar.edit"
                                    />
                                </span>
                                <img src={dropdownCaret} />
                                <MenuBarMenu
                                    className={classNames(menubarStyles.menuBarMenu)}
                                    open={this.props.editMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseEdit}
                                >
                                    <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                        <MenuItem
                                            className={classNames({[menubarStyles.disabled]: !restorable})}
                                            onClick={this.handleRestoreOption(handleRestore)}
                                        >
                                            {this.restoreOptionMessage(deletedItem)}
                                        </MenuItem>
                                    )}</DeletionRestorer>
                                    <MenuSection>
                                        <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                            <MenuItem onClick={toggleTurboMode}>
                                                {turboMode ? (
                                                    <FormattedMessage
                                                        defaultMessage="Turn off Turbo Mode"
                                                        description="Menu bar item for turning off turbo mode"
                                                        id="gui.menuBar.turboModeOff"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="Turn on Turbo Mode"
                                                        description="Menu bar item for turning on turbo mode"
                                                        id="gui.menuBar.turboModeOn"
                                                    />
                                                )}
                                            </MenuItem>
                                        )}</TurboMode>
                                    </MenuSection>
                                </MenuBarMenu>

                            </div>
                        </div>

                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                            className={classNames(styles.menuBarItem, styles.hoverable)}
                            onClick={this.handleSelectToppage}
                        >
                            <img
                                className={styles.helpIcon}
                                src={backIcon}
                            />
                            <span className={styles.tutorialsLabel}>
                                {'もどる'}
                            </span>
                        </div>

                        <Divider className={classNames(styles.divider)} />
                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                            className={classNames(styles.menuBarItem, styles.hoverable)}
                            onClick={this.props.onOpenPokemonHelpLibrary}
                        >
                            <img
                                className={styles.helpIcon}
                                src={helpIcon}
                            />
                            <span className={styles.tutorialsLabel}>
                                {/* <FormattedMessage {...ariaMessages.hint} /> */}
                                {'ヘルプ'}
                            </span>
                        </div>


                        {env.ENV_MODE === 'develop' &&
                            <>
                                <div
                                    aria-label={this.props.intl.formatMessage(ariaMessages.autosave)}
                                    className={classNames(styles.menuBarItem, styles.hoverable)}
                                    onClick={this.handleSelectCompetition}
                                >
                                    <span className={styles.tutorialsLabel}>
                                        <FormattedMessage {...ariaMessages.competitionTab} />
                                    </span>
                                </div>

                                <div
                                    aria-label={this.props.intl.formatMessage(ariaMessages.autosave)}
                                    className={classNames(styles.menuBarItem, styles.hoverable)}
                                    onClick={this.handleSelectCompetitionDev}
                                >
                                    <span className={styles.tutorialsLabel}>
                                        <FormattedMessage {...ariaMessages.forDeveloper} />
                                    </span>
                                </div>
                            </>

                        }
                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                            className={classNames(styles.menuBarItem, styles.hoverable)}
                            onClick={this.handleHintCardButtonClick}
                        >
                            
                            <span className={styles.tutorialsLabel}>
                                {/* <FormattedMessage {...ariaMessages.hint} /> */}
                                {'ヒントカード'}
                            </span>
                        </div>

                    </div>

                    
                </div>

                
            </Box>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    currentLocale: PropTypes.string.isRequired,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    isUpdating: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    mode1920: PropTypes.bool,
    mode1990: PropTypes.bool,
    mode2020: PropTypes.bool,
    mode220022BC: PropTypes.bool,
    modeMenuOpen: PropTypes.bool,
    modeNow: PropTypes.bool,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickMode: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickSettings: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    //
    onOpenPokemonTipsLibrary: PropTypes.func,
    onOpenPokemonHelpLibrary: PropTypes.func,
    onOpenFirstTimeUI: PropTypes.func,
    onOpenLoadUI: PropTypes.func,
    onOpenCompetition: PropTypes.func,
    onOpenCompetitionDev: PropTypes.func,
    //
    onProjectTelemetryEvent: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onRequestCloseMode: PropTypes.func,
    onRequestCloseSettings: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onSetTimeTravelMode: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    settingsMenuOpen: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    username: PropTypes.string,
    userOwnsProject: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,

    openFreeModeSaveUI: PropTypes.func,
    openFreeModeLoadUI: PropTypes.func,
    onSaveProject: PropTypes.func,
    onUpLoadProject: PropTypes.func,
    onDownLoadProject: PropTypes.func,

    isActiveAutosave: PropTypes.bool,
    selectedClassMode: PropTypes.string,

    onCloseCards: PropTypes.func,
    onHintCardButtonClick: PropTypes.func,

    setProjectUnchanged: PropTypes.func,
    onSetAutosaveState: PropTypes.func,
    resetProjectID: PropTypes.func,

    projectSavingState: PropTypes.bool
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => {}
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        currentLocale: state.locales.locale,
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        modeMenuOpen: modeMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        projectId: state.scratchGui.projectId,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        settingsMenuOpen: settingsMenuOpen(state),
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        isActiveAutosave: state.scratchGui.autosaveState,
        projectSavingState: state.scratchGui.savingState.project,
        metaSavingState: state.scratchGui.savingState.meta,
        projectChanged: state.scratchGui.projectChanged,
        selectedClassMode: state.scratchGui.selectedClassMode,
        toolboxXML: state.scratchGui.toolbox.toolboxXML,
        initToolboxXML: state.scratchGui.toolbox.initToolboxXML,
        initToolboxFlag: state.scratchGui.toolbox.initToolboxFlag
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    //
    onOpenPokemonTipsLibrary: () => dispatch(openPokemonTipsLibrary()),
    onOpenPokemonHelpLibrary: () => dispatch(openPokemonHelpLibrary()),
    onOpenFirstTimeUI: () => dispatch(openFirstTimeUI()),
    onOpenLoadUI: () => dispatch(openLoadUI()),
    onOpenCompetition: () => dispatch(openCompetition()),
    onOpenCompetitionDev: () => dispatch(openCompetitionDev()),
    //
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickMode: () => dispatch(openModeMenu()),
    onRequestCloseMode: () => dispatch(closeModeMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickSettings: () => dispatch(openSettingsMenu()),
    onRequestCloseSettings: () => dispatch(closeSettingsMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    openFreeModeSaveUI: () => dispatch(openFreeModeSaveUI()),
    openFreeModeLoadUI: () => dispatch(openFreeModeLoadUI()),
    resetProjectID: () => dispatch(setProjectID(null)),
    onSetAutosaveState: state => dispatch(setAutosaveState(state)),

    setProjectUnchanged: () => dispatch(setProjectUnchanged()),

    onCloseCards: () => {
        dispatch(selectFeature([]));
        dispatch(closeCardHelps());
        dispatch(closeCards());
    },
    updateToolboxState: toolboxXML => {
        dispatch(updateToolbox(toolboxXML));
    }
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
