/* eslint-disable react/prop-types */
// import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
// import { injectIntl } from 'react-intl';
// import classNames from 'classnames';

import styles from './popup-overlay.css';

import {onAccept, onCancel} from '../../reducers/overlay-state';

class PopupOverlayComponent extends React.Component {
    render () {
        return (
            <div className={styles.overlayMask}>
                <div
                    className={styles.overlayContent}
                    onClick={this.handleStopPropagation}
                >
                    <div className={styles.contentBody}>
                        <div className={styles.textWrapper}>
                            {this.props.overlayText}
                        </div>
                        <div className={styles.buttonWrapper}>
                            <div
                                className={styles.buttonFrame}
                                onClick={this.props.onAccept}
                            >
                                <div
                                    className={styles.buttonBody}
                                    onClick={this.props.onAccept}
                                >
                                    OK
                                </div>
                            </div>
                            {
                                this.props.isConfirm &&
                                <div
                                    className={styles.buttonFrame}
                                    onClick={this.props.onCancel}
                                >
                                    <div
                                        className={styles.buttonBody}
                                        onClick={this.props.onCancel}
                                    >
                                        キャンセル
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PopupOverlayComponent.proptypes = {
    overlayText: PropTypes.string,
    isConfirm: PropTypes.bool,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func
};

const mapStateToProps = state => ({
    overlayText: state.scratchGui.overlayState.textContent,
    isConfirm: state.scratchGui.overlayState.isConfirm
});

const mapDispatchToProps = dispatch => ({
    onAccept: () => dispatch(onAccept()),
    onCancel: () => dispatch(onCancel())
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupOverlayComponent);
