import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from '../delete-button/delete-button.jsx';
import styles from './sprite-selector-item.css';
import {ContextMenuTrigger} from 'react-contextmenu';
import {DangerousMenuItem, ContextMenu, MenuItem} from '../context-menu/context-menu.jsx';
import {FormattedMessage} from 'react-intl';

// react-contextmenu requires unique id to match trigger and context menu
let contextMenuId = 0;

// eslint-disable-next-line func-style, require-jsdoc
function onSetImageToCanvas (id, src) {
    const image = new Image();
    image.onload = () => {
        const element = document.getElementById(id);
        const ctx = element.getContext('2d');

        const canvasHeight = image.height / 4;
        const canvasWidth = image.width / 4;


        element.width = canvasWidth;
        element.height = canvasHeight;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        /*
        const imageAspectRate = image.width / image.height;
        const elementAspectRate = element.width / element.height;
        let dWidth;
        let dHeight;
        if (imageAspectRate < elementAspectRate) {
            dWidth = element.height * imageAspectRate;
            dHeight = element.height;
        } else {
            dWidth = element.width;
            dHeight = element.width / imageAspectRate;
        }
        const dx = (element.width - dWidth) / 2;
        const dy = (element.height - dHeight) / 2;

        ctx.drawImage(image, 0, 0, image.width, image.height, dx, dy, dWidth, dHeight);
        */
        ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };
    image.src = src;
}

const SpriteSelectorItem = props => {
    if (props.dragType !== 'SOUND') onSetImageToCanvas(props.id, props.costumeURL);
    return (
        <ContextMenuTrigger
            attributes={{
                className: classNames(props.className, styles.spriteSelectorItem, {
                    [styles.isSelected]: props.selected
                }),
                onClick: props.onClick,
                onMouseEnter: props.onMouseEnter,
                onMouseLeave: props.onMouseLeave,
                onMouseDown: props.onMouseDown,
                onTouchStart: props.onMouseDown
            }}
            disable={props.preventContextMenu}
            id={`${props.name}-${contextMenuId}`}
            ref={props.componentRef}
        >
            {typeof props.number === 'undefined' ? null : (
                <div className={styles.number}>{props.number}</div>
            )}
            {props.costumeURL ? (
                <div className={styles.spriteImageOuter}>
                    <div className={styles.spriteImageInner}>
                        {
                            props.dragType === 'SOUND' ?
                                <img
                                    className={styles.spriteImage}
                                    draggable={false}
                                    src={props.costumeURL}
                                /> : <canvas
                                    id={props.id}
                                    className={styles.spriteImage}
                                    draggable={false}
                                    width="90"
                                    height="60"
                                />
                        }
                    </div>
                </div>
            ) : null}
            <div className={styles.spriteInfo}>
                <div className={styles.spriteName}>{props.name}</div>
                {props.details ? (
                    <div className={styles.spriteDetails}>{props.details}</div>
                ) : null}
            </div>
            {(props.selected && props.onDeleteButtonClick) ? (
                <DeleteButton
                    className={styles.deleteButton}
                    onClick={props.onDeleteButtonClick}
                />
            ) : null}
            {props.onDuplicateButtonClick || props.onDeleteButtonClick || props.onExportButtonClick ? (
                <ContextMenu id={`${props.name}-${contextMenuId++}`}>
                    {props.onDuplicateButtonClick ? (
                        <MenuItem onClick={props.onDuplicateButtonClick}>
                            <FormattedMessage
                                defaultMessage="duplicate"
                                description="Menu item to duplicate in the right click menu"
                                id="gui.spriteSelectorItem.contextMenuDuplicate"
                            />
                        </MenuItem>
                    ) : null}

                    {props.onDeleteButtonClick ? (
                        <DangerousMenuItem onClick={props.onDeleteButtonClick}>
                            <FormattedMessage
                                defaultMessage="delete"
                                description="Menu item to delete in the right click menu"
                                id="gui.spriteSelectorItem.contextMenuDelete"
                            />
                        </DangerousMenuItem>
                    ) : null}
                </ContextMenu>
            ) : null}
        </ContextMenuTrigger>
    );
};

SpriteSelectorItem.propTypes = {
    className: PropTypes.string,
    componentRef: PropTypes.func,
    costumeURL: PropTypes.string,
    dragType: PropTypes.string,
    details: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.number,
    onClick: PropTypes.func,
    onDeleteButtonClick: PropTypes.func,
    onDuplicateButtonClick: PropTypes.func,
    onExportButtonClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    preventContextMenu: PropTypes.bool,
    selected: PropTypes.bool.isRequired
};

export default SpriteSelectorItem;
