exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html {\n    /*Safari Firefox*/\n    height: 100%;\n    -ms-scroll-chaining: none;\n        overscroll-behavior: none;\n    overflow: auto;\n    -moz-text-size-adjust: 100%;\n     -ms-text-size-adjust: 100%;\n         text-size-adjust: 100%;\n    -webkit-text-size-adjust: 100%;\n}\nbody {\n    /*Chrome Edge Chromium*/\n    height: 100%;\n    -ms-scroll-chaining: none;\n        overscroll-behavior: none;\n    margin: 0;\n}\n.index_app_2mqDO {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n", ""]);

// exports
exports.locals = {
	"app": "index_app_2mqDO"
};