import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {setAllowInputState} from '../reducers/allow-input-state';
import ModalComponent from '../components/noheader-modal/noheader-modal.jsx';

class NoHeaderModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handlePopState',
            'pushHistory'
        ]);
    }
    componentDidMount () {
        // Add a history event only if it's not currently for our modal. This
        // avoids polluting the history with many entries. We only need one.
        // this.pushHistory(this.id, (history.state === null || history.state !== this.id));
        this.props.onSetAllowInputState(false);
    }
    componentWillUnmount () {
        // this.removeEventListeners();
        this.props.onSetAllowInputState(true);
    }

    handlePopState () {
        // Whenever someone navigates, we want to be closed
        this.props.onRequestClose();
    }
    get id () {
        return `noheader-modal-${this.props.id}`;
    }
    pushHistory (state, push) {
        if (push) return history.pushState(state, this.id);
        history.replaceState(state, this.id);
    }
    render () {
        return <ModalComponent {...this.props} />;
    }
}

NoHeaderModal.propTypes = {
    id: PropTypes.string.isRequired,
    isRtl: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onRequestOpen: PropTypes.func,
    isShowingBackButton: PropTypes.bool,
    onSetAllowInputState: PropTypes.func
};

const mapStateToProps = state => ({
    isRtl: state.locales.isRtl
});

const mapDispatchToProps = dispatch => ({
    onSetAllowInputState: state => dispatch(setAllowInputState(state))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoHeaderModal);
