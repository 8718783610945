exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*hsla(215, 100%, 95%, 1);*/ /* #E5F0FF */ /* hsla(215, 75%, 95%, 1); */ /* #E9F1FC */ /*hsla(215, 50%, 90%, 1);*/ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* default */ /*$looks-secondary: hsla(260, 60%, 60%, 1); */ /* #855CD6 */ /*$looks-transparent: hsla(260, 60%, 60%, 0.35); /* 35% transparent version of looks-tertiary */ /* ict custom color */ /* #0048FF */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #714EB6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ :root {\n    --text-shadow-dark: darkblue;\n    --text-shadow-test: orange;\n    --text-shadow-emorange: #ff8d00;\n    --text-shadow-light: white;\n} .progress-ring_progress-ring_2ah6I {\n    margin: 0.5rem;\n} .progress-ring_progress-ring-svg_2y_x6 {\n    fill: transparent;\n} .progress-ring_progress-ring_2ah6I .progress-ring_progress-ring-ring_2wHxN {\n    stroke: hsla(260, 60%, 60%, 0.15);\n} .progress-ring_progress-ring_2ah6I .progress-ring_progress-ring-value_2c1U2 {\n    stroke: hsla(120, 40%, 50%, 1);\n    stroke-linecap: round;\n}\n", ""]);

// exports
exports.locals = {
	"progress-ring": "progress-ring_progress-ring_2ah6I",
	"progressRing": "progress-ring_progress-ring_2ah6I",
	"progress-ring-svg": "progress-ring_progress-ring-svg_2y_x6",
	"progressRingSvg": "progress-ring_progress-ring-svg_2y_x6",
	"progress-ring-ring": "progress-ring_progress-ring-ring_2wHxN",
	"progressRingRing": "progress-ring_progress-ring-ring_2wHxN",
	"progress-ring-value": "progress-ring_progress-ring-value_2c1U2",
	"progressRingValue": "progress-ring_progress-ring-value_2c1U2"
};