const SET_USER_STATE = 'projectTitle/SET_USER_STATE';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialState = null;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_USER_STATE:
        return action.userState;
    default:
        return state;
    }
};
const setUserState = state => ({
    type: SET_USER_STATE,
    userState: state
});

export {
    reducer as default,
    initialState as userStateInitialState,
    setUserState
};
