/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable no-loop-func */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable indent */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import dotenv from 'dotenv';
/* import VM from 'scratch-vm';*/
import styles from '../components/competition-dev/competition-dev.css';

import prefContents from '../lib/libraries/select-school-ui.json';
import backdropTags from '../lib/libraries/backdrop-tags';
import SelectSchoolUIComponent from '../components/select-school-ui/select-school-ui-component.jsx';
import CompetitionUIComponent from '../components/select-school-ui/competition-ui-component.jsx';
import {connectDB} from '../lib/indexedDB.jsx';

import axios from 'axios';
// import {v4 as uuidv4} from 'uuid';

dotenv.config();
const env = process.env;

const SCHOOLDATA_API_PARAM = (prefCode, cityName) => `?prefCode=${prefCode}&cityName=${cityName}`;
const DB_URL = env.ENV_MODE === 'develop' ? env.DEV_DB_URL : env.PRD_DB_URL;
// const DB_URL = 'http://localhost:3000/';

const DEPLOY_MODE = process.env.DEPLOY_MODE;
const GET_SCHOOLDATA_API_URL = `${DB_URL}get/schooldata/`;

const schoolDataPostUrl = DB_URL + env.SCHOOL_DATA_POST_URL;
// const projectDataPostUrl = DB_URL + env.PROJECT_DATA_POST_URL;


// const CITY_API_URL = env.CITY_API_URL;
const CITY_API_URL = prefNumber => `${DB_URL}get/city/?prefCode=${prefNumber}`;

const API_KEY = env.API_KEY;
const COMPETITION_TAG = env.COMPETITION_TAG;

// const SPLIT_DATA_SIZE = 1024 * 256;
const MAX_SEND_DATA_SIZE = 1024 * 1024 * 16;

const PROJECTNAME_LIMIT_LENGTH = 64;
const COMMENT_LIMIT_LENGTH = 256;

const USERNAME_LIMIT_LENGTH = 30;

const POST_PROJECT_TIMEOUT_MILLISECOND = 1000 * 60;

const removeNewline = text => text.replace(/[\n\r]/g, '');

class SelectSchoolUI extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onSetName',
            'onExport',
            'onExportSplitData',
            'deleteOptions',

            'handleClose',
            'handleSelectPref',
            'handleSelectPrefTextMode',
            'handleSelectCity',
            'handleSelectSchool',
            'handleExportSchoolLog',
            'handleExportUserData',
            'handleExportCompetition',
            'handleSelectPref',
            'handleChangeSelectMode',

            'handleSetCityName',
            'handleSetSchoolName',
            'handleSetUserName',
            'handleSetProjectName',
            'handleSetComment',
            'handleError',
            'onSetUserId',
            'onCheckUserId',
            'handleSelectTag',
            'handleUpdateUser',
            'handleLoadUserData',
            'getTag',
            'handleChangeToSchoolTeacher',
            'handleSelectOpenPage'
        ]);


        this.state = {
            isTextInputMode: false,
            schoolName: '',
            userName: '',
            cityName: '',
            cityCode: '',
            prefName: '',
            projectName: '',
            comment: '',

            tag: 'Default',

            cityOptions: [],
            schoolOptions: [],

            isLoadedcity: false,
            isLoadedschool: false,

            isAvailableSubmittion: true,

            prefNumber: null,

            isErrorSending: false,

            isLoadedUserId: false,

            userId: null,
            isSenndingData: false
        };
    }

    componentDidMount () {
        this.onCheckUserId()
            .catch(async () => {
                await window.ownAlert(
                    <p>
                        <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    </p>
                );
                this.handleClose();
            });
    }


    onSetName (name, id) {
        if (this.state[id] !== name) {
            this.setState({
                [id]: name
            });
        }
    }

    handleSetCityName (e) {
        const removeNewlineText = removeNewline(e.target.value);
        e.target.value = removeNewlineText;
        this.onSetName(removeNewlineText, 'cityName');
    }

    handleSetSchoolName (e) {
        const removeNewlineText = removeNewline(e.target.value);
        e.target.value = removeNewlineText;
        this.onSetName(removeNewlineText, 'schoolName');
    }

    handleSetUserName (e) {
        const removeNewlineText = removeNewline(e.target.value);
        e.target.value = removeNewlineText;
        this.onSetName(removeNewlineText, 'userName');
    }

    handleSetProjectName (e) {
        const removeNewlineText = removeNewline(e.target.value);
        e.target.value = removeNewlineText;
        this.onSetName(removeNewlineText, 'projectName');
    }

    handleSetComment (e) {
        this.onSetName(e.target.value, 'comment');
    }

    handleClose () {
        this.props.onRequestClose();
    }

    handleSelectTag (selectedTag) {
        this.setState({
            tag: selectedTag
        });
    }

    deleteOptions (name) {
        this.setState({
            [`${name}Name`]: ''
        });
        if (name === 'city' || name === 'school') {
            this.setState({
                [`isLoaded${name}`]: false,
                [`${name}Options`]: []
            });
            if (name === 'city') {
                this.onSetName('', 'cityCode');
            }
        }
    }

    CreateCityOptions (data) {
        const options = [];
        data.forEach(city => {
            const option = {
                value: city.cityCode,
                id: city.cityCode,
                label: city.cityName
            };
            options.push(option);
        });
        this.setState({
            isLoadedcity: true,
            cityOptions: options
        });
    }

    CreateSchoolOptions (data) {
        const options = data.map(schoolData => ({
                value: schoolData.school_position,
                id: schoolData.school_name,
                label: schoolData.school_name,
                extra: schoolData.school_code
            }));


        this.setState({
            isLoadedschool: true,
            schoolOptions: options
        });
    }

    handleLoadUserData () {
        if (!this.state.userId || this.state.userId === '') return;
        return axios({
            method: 'post',
            url: `${DB_URL}get/user/`,
            data: JSON.stringify({
                userId: this.state.userId
            }),
            headers: {
                    'Content-Type': 'application/json',
                    'api-key': API_KEY
                },
            proxy: false,
            timeout: 1000 * 10

        })
            .then(res => {
                if (res.data.length > 0) return res.data[0];
                return null;
            })
            .catch(async () => {
                await window.ownAlert(
                    <p>
                        <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    </p>
                );
            });
    }

    handleSelectPrefTextMode (e) {
        const prefName = e.label;
        const prefNumber = e.value;
        // console.log(prefName);
        this.onSetName(prefName, 'prefName');
        this.onSetName(prefNumber, 'prefNumber');
    }

    handleSelectPref (e) {
        // const prefName = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        // const prefNumber = e.target.value;

        const prefName = e.label;
        const prefNumber = e.value;

        this.deleteOptions('city');
        this.deleteOptions('school');

        fetch(CITY_API_URL(prefNumber), {headers: {'api-key': API_KEY}})
            .then(res => res.json())
            .then(data => data.result.filter(d => d.bigCityFlag !== '1'))
            .then(cityData => {
                this.onSetName(prefName, 'prefName');
                this.onSetName(prefNumber, 'prefNumber');

                this.CreateCityOptions(cityData);
            })
            .catch(async () => {
                await window.ownAlert(
                    <p>
                        <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    </p>
                );
                this.setState({isErrorSending: true});
            });
    }

    handleSelectCity (e) {
        // const cityName = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        const cityName = e.label;
        const cityCode = e.value;
        const prefCode = this.state.prefNumber.toString().padStart(2, '0');

        this.deleteOptions('school');
        fetch(GET_SCHOOLDATA_API_URL + SCHOOLDATA_API_PARAM(prefCode, cityName), {headers: {'api-key': API_KEY}})
            .then(response => response.json())
            .then(data => {
                this.CreateSchoolOptions(data);

                this.onSetName(cityName, 'cityName');
                this.onSetName(cityCode, 'cityCode');
            })
            .catch(async () => {
                await window.ownAlert(
                    <p>
                        <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    </p>
                );
                this.setState({isErrorSending: true});
            });
    }

    handleSelectSchool (e) {
        // const schoolName = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        const schoolName = e.label;
        // console.log(schoolName);
        this.onSetName(schoolName, 'schoolName');
    }

    handleChangeSelectMode () {
        this.setState(state => ({
            isTextInputMode: !state.isTextInputMode
        }));
        this.deleteOptions('pref');
        this.deleteOptions('city');
        this.deleteOptions('school');
    }

    onExport (data, url, header = {'Content-Type': 'application/json'}) {
        // POST
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: header,
            proxy: false,
            timeout: 1000 * 10

        })
        .catch(async error => {
            // eslint-disable-next-line no-console
            console.log('post errorcode : ', error);
            await window.ownAlert(
                <p>
                    <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                    インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                </p>
            );
            this.setState({
                isAvailableSubmittion: true,
                isErrorSending: true
            });
        })
        .then(async res => {
            if (res.data !== 'error') {
                await window.ownAlert(<p><ruby>送信<rt>そうしん</rt></ruby>が<ruby>完了<rt>かんりょう</rt></ruby>しました</p>);
                this.handleClose();
            }
        });
    }

    onExportSplitData (data, url, header = {'Content-Type': 'application/json'}) {
        // POST
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: url,
                data: data,
                headers: header,
                proxy: false

            })
                .then(res => {
                    if ((res.data === 'error')) {
                        reject('error response');
                        
                    } else {
                        resolve();
                    }

                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log('post errorcode : ', error);
                    reject(error);
                });
        });
    }

    handleExportSchoolLog () {
        this.setState({
            isAvailableSubmittion: false
        });
        const sendData = {
            schoolName: this.state.schoolName.replace("'", '’'),
            prefId: this.state.prefNumber,
            cityName: this.state.cityName.replace("'", '’'),
            cityCode: this.state.cityCode,
            needCheck: false,
            isFacility: !this.props.isSchoolTeacher
        };
        if (this.state.isTextInputMode) {
            sendData.needCheck = true;
        }
        // console.log(sendData);
        this.onExport(sendData, schoolDataPostUrl);
    }

    handleExportUserData () {
        const userName = this.state.userName.length > USERNAME_LIMIT_LENGTH ?
            this.state.userName.substr(0, USERNAME_LIMIT_LENGTH) : this.state.userName;

        this.setState({
            isAvailableSubmittion: false
        });

        axios(
            {
                method: 'post',
                url: `${DB_URL}post/user/`,
                data: {
                    userName: userName.replace("'", '’'),
                    schoolName: this.state.schoolName.replace("'", '’'),
                    prefId: this.state.prefNumber,
                    cityName: this.state.cityName.replace("'", '’'),
                    cityCode: this.state.cityCode
                },
                headers: {'Content-Type': 'application/json'},
                proxy: false,
                timeout: 1000 * 20
            }
        )
            .then(async res => {
                if (res.data === 'error') {
                    throw new Error('error response');
                }

                await window.ownAlert(
                    <p>
                        ユーザーデータの<ruby>登録<rt>とうろく</rt></ruby>が
                        <ruby>完了<rt>かんりょう</rt></ruby>しました
                    </p>
                );

                this.onSetUserId(res.data);
                this.setState({
                    isAvailableSubmittion: true
                });
            })
            .catch(async error => {
                // eslint-disable-next-line no-console
                console.log('post errorcode : ', error);
                if (!this.state.isErrorSending) {

                    await window.ownAlert(
                        <p>
                            <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                            インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                        </p>
                    );
                    this.setState({
                        isErrorSending: true,
                        isSendingData: false
                    });
                }
            });
    }

    onSetUserId (id) {
        this.setState({userId: id});
        return new Promise(async (resolve, reject) => {
            const db = await connectDB();

            const transaction = db.transaction(['playerData'], 'readwrite');
            const objItem = transaction.objectStore('playerData');
            const request = objItem.put(
                {
                    id: 'UserId',
                    data: id
                }
            );
            request.onerror = error => reject(error);
            request.onsuccess = () => {
                // eslint-disable-next-line no-console
                console.log('set : ', id);
                resolve();
            };
        });
    }

    onCheckUserId () {
        return new Promise(async (resolve, reject) => {
            const db = await connectDB();
            const objstore = db.transaction(['playerData'], 'readonly').objectStore('playerData');
            const getReq = objstore.get('UserId');

            getReq.onsuccess = getevent => {
                const result = getevent.target.result;
                if (typeof result === 'undefined') {
                    // console.log('none');
                    this.setState({
                        isLoadedUserId: true
                    });
                    resolve();

                } else {
                    const userId = result.data;
                    axios({
                        method: 'get',
                        url: `${DB_URL}get/user/?userId=${userId}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'api-key': API_KEY
                        },
                        proxy: false,
                        timeout: 1000 * 10

                    })
                        .then(res => {
                            if (res.data.length > 0) {
                                this.setState({
                                    isLoadedUserId: true,
                                    userId: userId
                                });
                                resolve(result.data);
                            }
                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            };

            getReq.onerror = error => reject(error);
        });
    }

    handleError () {
        this.setState(state => ({isErrorSending: !state.isErrorSending}));
    }

    getTag () {
        const tag = DEPLOY_MODE === 'local' ?
            this.state.tag : COMPETITION_TAG;
        return tag ?
            tag : 'Default';
    }

    handleExportCompetition () {
        return new Promise(async mainResolve => {
            try {
                this.setState({
                    isAvailableSubmittion: false
                });

                const projectname = this.state.projectName.length > PROJECTNAME_LIMIT_LENGTH ?
                    this.state.projectName.substr(0, PROJECTNAME_LIMIT_LENGTH).replace("'", '’') :
                    this.state.projectName.replace("'", '’');

                let comment = this.state.comment.length > COMMENT_LIMIT_LENGTH ?
                    this.state.comment.substr(0, COMMENT_LIMIT_LENGTH).replace("'", '’') :
                    this.state.comment.replace("'", '’');

                comment = comment.match(/\S/g) ? comment : 'コメントなし';

                if (!await window.ownConfirm(
                    <>
                        <p>
                            こちらの<ruby>情報<rt>じょうほう</rt></ruby>で<ruby>作品<rt>さくひん</rt></ruby>を
                            <ruby>応募<rt>おうぼ</rt></ruby>します。<br /><ruby>本当<rt>ほんとう</rt></ruby>によろしいですか？
                        </p>
                        <table>
                            <tr className={styles.sendDataTable}>
                                <td><h1><ruby>作品名<rt>さくひんめい</rt></ruby></h1></td>
                                <td><p1>{projectname}</p1></td>
                            </tr>
                            <tr className={styles.sendDataTable}>
                                <td><h1>コメント</h1></td>
                                {/* <td><div className={styles.pTwoContainer}><p2>{this.state.comment}</p2></div></td>*/}
                                <td><p2>{comment}</p2></td>
                            </tr>
                        </table>

                    </>
                )) {
                    this.setState({
                        isAvailableSubmittion: true
                    });
                    return mainResolve();
                }

                const data = await new Promise((resolve, reject) => {
                    fetch(`${DB_URL}get/project/metadata/`,
                        {
                            method: 'POST',
                            headers: {'content-type': 'application/json'},
                            body: JSON.stringify({
                                userId: this.state.userId,
                                tag: this.getTag()
                            })
                        })
                        .catch(async error => {
                            await window.ownAlert(
                                <p>
                                    <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                                    インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                                </p>
                            );
                            reject(error);
                        })
                        .then(d => d.json())
                        .then(d => resolve(d));
                }).catch(error => {
                    throw new Error(error);
                });

                this.props.saveProjectSb3()
                    .catch(error => {
                        throw new Error(error);
                    })
                    .then(async content => {
                        // console.log(content, content.size);
                        if (content.size > MAX_SEND_DATA_SIZE) {
                            await window.ownAlert(
                                <>
                                    <p>
                                        <ruby>応募<rt>おうぼ</rt></ruby>できる<ruby>作品<rt>さくひん</rt></ruby>の
                                        <ruby>最大<rt>さいだい</rt></ruby>サイズを<ruby>超<rt>こ</rt></ruby>えています。
                                    </p>
                                    <p>
                                        <ruby>使用<rt>しよう</rt></ruby>する
                                        <ruby>音楽<rt>おんがく</rt></ruby>や<ruby>画像<rt>がぞう</rt></ruby>
                                        を<ruby>減<rt>へ</rt></ruby>らしてみてください。
                                    </p><br />
                                    <p><ruby>現在<rt>げんざい</rt></ruby>のサイズ：{content.size}</p>
                                    <p><ruby>最大<rt>さいだい</rt></ruby>サイズ：{MAX_SEND_DATA_SIZE}</p>
                                </>
                            );
                            this.setState({
                                isAvailableSubmittion: true
                            });
                            return mainResolve();
                        }


                        if (data.length > 0) {
                            if (!await window.ownConfirm(
                                <>
                                    <p>
                                        <ruby>既<rt>すで</rt></ruby>に<ruby>提出<rt>ていしゅつ</rt></ruby>
                                        <ruby>済<rt>ず</rt></ruby>みの
                                        <ruby>作品<rt>さくひん</rt></ruby>があります。<br />
                                        <ruby>上書<rt>うわが</rt></ruby>きして<ruby>再提出<rt>さいていしゅつ</rt></ruby>しますか？
                                    </p>
                                    <table>
                                        <tr className={styles.sendDataTable}>
                                            <td><h1><ruby>作品名<rt>さくひんめい</rt></ruby></h1></td>
                                            <td><p1>{data[0].projectName}</p1></td>
                                        </tr>
                                        <tr className={styles.sendDataTable}>
                                            <td><h1>コメント</h1></td>
                                            <td><p2>{data[0].comment}</p2></td>
                                        </tr>
                                        <tr className={styles.sendDataTable}>
                                            <td><h1><ruby>応募<rt>おうぼ</rt></ruby><ruby>日時<rt>にちじ</rt></ruby></h1></td>
                                            <td><p3>{new Date(data[0].registerDate).toLocaleString('ja')}</p3></td>
                                        </tr>
                                    </table>
                                </>
                            )) {
                                this.setState({
                                    isAvailableSubmittion: true
                                });
                                return mainResolve();
                            }
                        }
                        this.setState({isSendingData: true});

                        // console.log('check in')
                        // await new Promise(resolve => {
                        //    axios({
                        //        method: 'get',
                        //        url: `${DB_URL}check/post/project/`,
                        //        headers: {
                        //            'Content-Type': 'application/json',
                        //            'api-key': API_KEY
                        //        },
                        //        timeout: 1000 * 120
                        //    })
                        //        .then(res => {
                        //            if (res.data === 'error') {
                        //                throw new Error('timeout/error');
                        //            } else {
                        //                resolve();
                        //            }

                        //    })
                        //    .catch(error => {
                        //        console.log(error);
                        //        throw new Error('timeout/error');
                        //    });
                        // });
                        // console.log('check out')
                        // const DB_URL1 = 'http://localhost:3000/';
                        content.arrayBuffer()
                            .then(buffer => this.props.zipProjectWithPass(buffer, env.ZIP_UNLOCK_KEY,
                                (this.props.projectFilename && this.props.projectFilename !== '') ? this.props.projectFilename : 'project'))
                            .then(async ab => {
                                try {
                                    const metaData = encodeURI(`${projectname}|${comment}|${this.state.tag ? this.state.tag : 'Default'}`);
                                    await new Promise((resolve, reject) => {
                                        axios({
                                            method: 'post',
                                            url: `${DB_URL}post/project/`,
                                            headers: {
                                                'Content-Type': 'application/octet-stream',
                                                'api-key': API_KEY,
                                                'metadata': metaData,
                                                'userId': this.state.userId
                                            },
                                            data: ab,
                                            proxy: false,
                                            timeout: POST_PROJECT_TIMEOUT_MILLISECOND

                                        })
                                            .catch(error => reject(error))
                                            .then(res => resolve(res));
                                    });

                                    await window.ownAlert(
                                        <p>
                                            <ruby>応募<rt>おうぼ</rt></ruby>が<ruby>完了<rt>かんりょう</rt></ruby>しました
                                        </p>
                                    );

                                    this.setState({isSenndingData: false});
                                    this.handleClose();

                                } catch (error) {
                                    console.log(error);
                                    // if (timeoutId) clearTimeout(timeoutId);

                                    if (!this.state.isErrorSending) {
                                        await window.ownAlert(
                                            <p>
                                                <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                                                インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                                            </p>
                                        );
                                        this.setState({isErrorSending: true});
                                    }
                                    
                                    this.setState({isSenndingData: false});
                                    this.handleClose();
                                }

                            });
                });
            } catch (error){
                // eslint-disable-next-line no-console
                console.log('[ERROR] post projectdata');
                if (!this.state.isErrorSending) {
                    this.setState({isErrorSending: true});
                }
                this.setState({isSenndingData: false});
            }

        });

    }

    handleUpdateUser (callBack = null) {
        const userName = this.state.userName.length > USERNAME_LIMIT_LENGTH ?
            this.state.userName.substr(0, USERNAME_LIMIT_LENGTH) : this.state.userName;

        this.setState({
            isAvailableSubmittion: false
        });

        axios({
            method: 'post',
            url: `${DB_URL}update/user/id/`,
            data: {
                userName: userName,
                schoolName: this.state.schoolName,
                prefId: this.state.prefNumber,
                cityName: this.state.cityName,
                cityCode: this.state.cityCode,
                userId: this.state.userId ? this.state.userId : null
            },
            headers: {
                'Content-Type': 'application/json', 'api-key': API_KEY
            },
            proxy: false

        })
            .then(async res => {
                if ((res.data !== 'yet' && res.data !== 'error')) {
                    await window.ownAlert(
                        <p>
                            ユーザーデータの<ruby>更新<rt>こうしん</rt></ruby>が
                            <ruby>完了<rt>かんりょう</rt></ruby>しました
                        </p>
                    );

                    this.setState({
                        isAvailableSubmittion: true
                    });
                    if (callBack) {
                        callBack();
                    }
                }
            })
            .catch(async error => {
                // eslint-disable-next-line no-console
                console.log('post errorcode : ', error);
                if (!this.state.isErrorSending) {

                    await window.ownAlert(
                        <p>
                            <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                            インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                        </p>
                    );
                    this.setState({isErrorSending: true});
                }
            });
    }

    handleChangeToSchoolTeacher () {
        this.props.onChangeToSchoolTeacher();

        this.deleteOptions('pref');
        this.deleteOptions('city');
        this.deleteOptions('school');
    }

    handleSelectOpenPage(){
        window.open('https://startkit.pokemon-foundation.or.jp/faq/')
    }


    render () {
        return (
            this.props.isCompetitionMode ?
                <>
                    <CompetitionUIComponent
                        prefDatas={prefContents}
                        id="selectSchool"
                        tags={backdropTags}
                        title={<p><ruby>大会<rt>たいかい</rt></ruby>に<ruby>参加<rt>さんか</rt></ruby>する</p>}

                        onSelectCity={this.handleSelectCity}
                        onSelectSchool={this.handleSelectSchool}
                        onSelectPref={this.handleSelectPref}
                        onSelectPrefTextMode={this.handleSelectPrefTextMode}

                        onExport={this.handleExportUserData}
                        onExportCompetition={this.handleExportCompetition}

                        onChangeSelectMode={this.handleChangeSelectMode}
                        onSetUserName={this.handleSetUserName}
                        onSetCityName={this.handleSetCityName}
                        onSetSchoolName={this.handleSetSchoolName}
                        onSetProjectName={this.handleSetProjectName}
                        onSetComment={this.handleSetComment}

                        onRequestClose={this.handleClose}
                        isShowingBackButton

                        isTextInputMode={this.state.isTextInputMode}
                        schoolName={this.state.schoolName}
                        userName={this.state.userName}
                        cityName={this.state.cityName}
                        prefName={this.state.prefName}
                        cityCode={this.state.cityCode}
                        projectName={this.state.projectName}

                        isShowingCity={this.state.isLoadedcity}
                        isShowingSchool={this.state.isLoadedschool}

                        isLoadedUserId={this.state.isLoadedUserId}
                        userId={this.state.userId}

                        isAvailableSubmittion={this.state.isAvailableSubmittion}

                        onDownLoadProject={this.props.onDownLoadProject}

                        isErrorSending={this.state.isErrorSending}
                        onCheckError={this.handleError}

                        cityOptions={this.state.cityOptions}
                        schoolOptions={this.state.schoolOptions}

                        onSelectTag={this.handleSelectTag}
                        onUpdateUser={this.handleUpdateUser}
                        onLoadUserData={this.handleLoadUserData}

                        isSenndingData={this.state.isSendingData}
                    />
                </> :
                <SelectSchoolUIComponent
                    prefDatas={prefContents}
                    id="selectSchool"
                    tags={backdropTags}
                    title={this.props.isSchoolTeacher ?
                        <><ruby>小学校名<rt>しょうがっこうめい</rt></ruby>を<ruby>入力<rt>にゅうりょく</rt></ruby>してください</> :
                        <><ruby>施設名<rt>しせつめい</rt></ruby>を<ruby>入力<rt>にゅうりょく</rt></ruby>してください</>
                    }

                    onSelectCity={this.handleSelectCity}
                    onSelectSchool={this.handleSelectSchool}
                    onExport={this.handleExportSchoolLog}
                    onSelectPref={this.handleSelectPref}
                    onSelectPrefTextMode={this.handleSelectPrefTextMode}
                    onChangeSelectMode={this.handleChangeSelectMode}
                    onSetCityName={this.handleSetCityName}
                    onSetSchoolName={this.handleSetSchoolName}

                    onRequestClose={this.props.onRequestEscapeSelectSchool}
                    isShowingBackButton

                    isTextInputMode={this.state.isTextInputMode}
                    schoolName={this.state.schoolName}
                    cityName={this.state.cityName}
                    prefName={this.state.prefName}
                    cityCode={this.state.cityCode}

                    isAvailableSubmittion={this.state.isAvailableSubmittion}

                    isShowingCity={this.state.isLoadedcity}
                    isShowingSchool={this.state.isLoadedschool}

                    cityOptions={this.state.cityOptions}
                    schoolOptions={this.state.schoolOptions}

                    isSchoolTeacher={this.props.isSchoolTeacher}
                    onChangeToSchoolTeacher={this.handleChangeToSchoolTeacher}

                    isErrorSending={this.state.isErrorSending}

                    onSelectOpenPage={this.handleSelectOpenPage}
                />
        );
    }
}

SelectSchoolUI.propTypes = {
    onRequestClose: PropTypes.func,
    onRequestEscapeSelectSchool: PropTypes.func,
    isCompetitionMode: PropTypes.bool,
    saveProjectSb3: PropTypes.func,
    zipProjectWithPass: PropTypes.func,
    onDownLoadProject: PropTypes.func,
    projectFilename: PropTypes.string,
    // intl: intlShape,
    isSchoolTeacher: PropTypes.bool,
    onChangeToSchoolTeacher: PropTypes.func
};


const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    zipProjectWithPass: state.scratchGui.vm.zipProjectWithPass.bind(state.scratchGui.vm),
    projectId: state.scratchGui.projectId,
    projectFilename: state.scratchGui.projectTitle
});

const mapDispatchToProps = () => (
    {

    }
);

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectSchoolUI));
