import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './pokemonstartkit-button.css';
import stylesR from './pokemonstartkit-button-r.css';

const PokemonstartkitButtonComponent = ({
    enabled,
    reversalMode,
    autoWidthMode,
    iconClassName,
    iconSrc,
    onClick,
    children,
    ...props
}) => {

    if (!enabled) {
        onClick = function () { };
    }

    const style = reversalMode ? stylesR : styles;

    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    return (
        <button
            type="submit"
            className={classNames(
                style.pokemonstartkitButton,
                enabled ? style.enabled : style.disabled,
                autoWidthMode ? styles.autoWidth : null)}
            onClick={onClick}
            {...props}
        >
            <span className={classNames(style.pad, autoWidthMode ? styles.flatPadding : null)}>
                {icon}
                {children}
            </span>
        </button>
    );
};

PokemonstartkitButtonComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    reversalMode: PropTypes.bool,
    autoWidthMode: PropTypes.bool
};

export default PokemonstartkitButtonComponent;

export const PokemonstartkitSmallButtonComponent = ({
    enabled,
    help,
    reversalMode,
    iconClassName,
    iconSrc,
    onClick,
    children,
    ...props
}) => {

    if (!enabled) {
        onClick = function () { };
    }

    const style = reversalMode ? stylesR : styles;

    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    return (
        <button
            type="submit"
            className={classNames(
                style.pokemonstartkitSmallButton,
                enabled ? style.enabled : style.disabled,
                help ? style.help : null
            )}
            onClick={onClick}
            {...props}
        >
            <span className={style.smallPad}>
                {icon}
                {children}
            </span>
        </button>
    );
};

PokemonstartkitSmallButtonComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    reversalMode: PropTypes.bool,
    help: PropTypes.bool
};

export const PokemonstartkitTextCardComponent = ({
    enabled,
    reversalMode,
    iconClassName,
    iconSrc,
    onClick,
    children,
    ...props
}) => {

    if (!enabled) {
        onClick = function () { };
    }

    const style = reversalMode ? stylesR : styles;
    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    return (
        <button
            type="submit"
            className={classNames(style.pokemonstartkitTextCard, enabled ? style.enabled : style.disabled)}
            onClick={onClick}
            {...props}
        >
            <span className={style.textCardPad}>
                {icon}
                {children}
            </span>
        </button>
    );
};
PokemonstartkitTextCardComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    reversalMode: PropTypes.bool
};
