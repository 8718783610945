import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
import Modal from '../../containers/noheader-modal.jsx';

import styles from './select-school-ui.css';

import PokemonstartkitButtonComponent
from '../first-ui-library/pokemonstartkit-button/pokemonstartkit-button.jsx';


import CompetitionEntryUi from '../competition/competition-entry-ui.jsx';
import UserEntryUi from '../competition/user-entry-ui.jsx';

const submitButtonName = 'submitButton';

require('dotenv').config();
const env = process.env;

const URL = env.PC_TRAINING_URL;
const isDevelop = env.ENV_MODE === 'develop';

class CompetitionUiComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',

            'handleSelectCity',
            'handleSelectSchool',
            'handleExport',
            'handleExportProject',
            'handleSelectPrefecture',
            'handleChangeSelectMode',
            'handleDownload',
            'handleOpenOverlay',
            'handleCloseOverlay',
            'handleCheckError',
            'handleSetEditMode',
            'handleUnsetEditMode',
            'handleUpdateUser'
        ]);

        this.state = {
            isOpenedOverlay: false,
            isAvailable: true,
            isEditMode: false
        };
    }

    handleSetEditMode () {
        this.setState({
            isEditMode: true
        });
    }

    handleUnsetEditMode () {
        this.setState({
            isEditMode: false
        });
    }

    handleClose () {
        if (this.state.isEditMode) this.handleUnsetEditMode();
        else this.props.onRequestClose();
    }

    handleSelectPrefecture (e) {
        if (this.props.isTextInputMode) {
            this.props.onSelectPrefTextMode(e);
        } else {
            this.props.onSelectPref(e);
        }
    }

    handleSelectCity (e) {
        this.props.onSelectCity(e);
    }

    handleSelectSchool (e) {
        this.props.onSelectSchool(e);
    }

    handleChangeSelectMode () {
        this.props.onChangeSelectMode();
    }

    handleExport () {
        this.props.onExport();
    }

    handleExportProject (){
        this.props.onExportCompetition();
    }

    handleOpenOverlay () {
        this.setState({
            isOpenedOverlay: true
        });
    }

    handleDownload () {
        this.props.onDownLoadProject();
    }

    handleCloseOverlay () {
        this.setState({
            isOpenedOverlay: false
        });
    }

    handleCheckError () {
        this.props.onCheckError();
    }

    handleUpdateUser () {
        this.props.onUpdateUser(this.handleUnsetEditMode);
    }

    onSet

    render () {
        return (
            <>
                <Modal
                    fullScreen
                    contentLabel={this.props.title}
                    id={this.props.id}
                    onRequestClose={this.handleClose}
                    isShowingBackButton={this.props.isShowingBackButton && !this.props.isSenndingData}
                    header
                >
                    {
                        this.props.isLoadedUserId ?
                            <>
                                {
                                    this.props.userId && !this.state.isEditMode ?
                                        <CompetitionEntryUi
                                            onExportCompetition={this.props.onExportCompetition}
                                            onOpenOverlay={this.handleOpenOverlay}
                                            onCloseOverlay={this.handleCloseOverlay}
                                            onSetProjectName={this.props.onSetProjectName}
                                            onSetComment={this.props.onSetComment}
                                            isAvailableSubmittion={this.props.isAvailableSubmittion}
                                            userId={this.props.userId}
                                            projectName={this.props.projectName}
                                            isErrorSending={this.props.isErrorSending}
                                            onSelectTag={this.props.onSelectTag}
                                            onSetEditMode={this.handleSetEditMode}
                                            onLoadUserData={this.props.onLoadUserData}
                                            isSenndingData={this.props.isSenndingData}
                                        /> :
                                        <UserEntryUi
                                            prefDatas={this.props.prefDatas}
                                            onSelectCity={this.props.onSelectCity}
                                            onSelectSchool={this.props.onSelectSchool}
                                            onSelectPref={this.props.onSelectPref}
                                            onSelectPrefTextMode={this.props.onSelectPrefTextMode}
                                            onChangeSelectMode={this.props.onChangeSelectMode}
                                            onOpenOverlay={this.handleOpenOverlay}
                                            onCloseOverlay={this.handleCloseOverlay}
                                            onExport={this.props.onExport}
                                            onSetCityName={this.props.onSetCityName}
                                            onSetSchoolName={this.props.onSetSchoolName}
                                            onSetUserName={this.props.onSetUserName}
                                            isTextInputMode={this.props.isTextInputMode}
                                            schoolName={this.props.schoolName}
                                            userName={this.props.userName}
                                            cityName={this.props.cityName}
                                            prefName={this.props.prefName}
                                            isShowingCity={this.props.isShowingCity}
                                            isShowingSchool={this.props.isShowingSchool}
                                            isAvailableSubmittion={this.props.isAvailableSubmittion}
                                            isErrorSending={this.props.isErrorSending}
                                            cityOptions={this.props.cityOptions}
                                            schoolOptions={this.props.schoolOptions}
                                            isEditMode={this.state.isEditMode}
                                            onUpdateUser={this.handleUpdateUser}
                                        />
                                }
                                {
                                    !this.props.isSenndingData &&
                                    isDevelop &&
                                    <div className={styles.errorButtonWrapper}>
                                        <button
                                            onClick={this.handleCheckError}
                                        >
                                            送信エラー確認用
                                        </button>
                                    </div>
                                }
                            </> : null
                    }
                    {
                        this.state.isOpenedOverlay &&
                        <div className={styles.overlayBlock}>
                            <div className={styles.overlayBody}>
                                <p>ダウンロード<ruby>後<rt>ご</rt></ruby>、こちらのリンクから<ruby>応募<rt>おうぼ</rt></ruby>してください</p>
                                <a
                                    href={URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >リンクはこちら(現在はPCトレーニングで仮置き)</a>
                                <PokemonstartkitButtonComponent
                                    id={submitButtonName}
                                    onClick={this.handleDownload}
                                    enabled
                                > {'ダウンロード'}</PokemonstartkitButtonComponent>
                                <PokemonstartkitButtonComponent
                                    id={submitButtonName}
                                    onClick={this.handleCloseOverlay}
                                    enabled
                                >
                                    <ruby>閉<rt>と</rt></ruby>じる
                                </PokemonstartkitButtonComponent>
                            </div>
                        </div>
                    }

                </Modal>

            </>
        );
    }
}

CompetitionUiComponent.propTypes = {
    prefDatas: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            id: PropTypes.number,
            name: PropTypes.string

        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    onRequestClose: PropTypes.func,
    onDownLoadProject: PropTypes.func,
    id: PropTypes.string.isRequired,
    title: PropTypes.element.isRequired,
    isShowingBackButton: PropTypes.bool,

    onSelectCity: PropTypes.func,
    onSelectSchool: PropTypes.func,
    onSelectPref: PropTypes.func,
    onSelectPrefTextMode: PropTypes.func,
    onChangeSelectMode: PropTypes.func,

    onExport: PropTypes.func,
    onExportCompetition: PropTypes.func,

    onSetCityName: PropTypes.func,
    onSetSchoolName: PropTypes.func,
    onSetUserName: PropTypes.func,
    onSetProjectName: PropTypes.func,
    onSetComment: PropTypes.func,

    isTextInputMode: PropTypes.bool,
    schoolName: PropTypes.string,
    userName: PropTypes.string,
    cityName: PropTypes.string,
    prefName: PropTypes.string,

    isShowingCity: PropTypes.bool,
    isShowingSchool: PropTypes.bool,

    isAvailableSubmittion: PropTypes.bool,

    isLoadedUserId: PropTypes.bool,
    userId: PropTypes.string,
    projectName: PropTypes.string,

    isErrorSending: PropTypes.bool,
    isSenndingData: PropTypes.bool,
    onCheckError: PropTypes.func,
    onSelectTag: PropTypes.func,
    onUpdateUser: PropTypes.func,
    onLoadUserData: PropTypes.func
};

export default injectIntl(CompetitionUiComponent);
