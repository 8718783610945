/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import styles from './pc-training.css';
import Modal from '../../containers/noheader-modal.jsx';
import bindAll from 'lodash.bindall';

require('dotenv').config();
const env = process.env;

import {
    openFirstTimeUI
} from '../../reducers/modals';

const setHeight = () => {
    const element = document.getElementById('pctraining');
    const vh = window.innerHeight;
    element.style.setProperty('--dvh100', `${vh}px`);
};


// const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;
// const ORIGIN_URL = env.DEPLOY_MODE === 'local' ? env.LOCAL_API_SERVER : `${AWS_S3_URL}assets/`;
const PC_TRAINING_URL = env.ENV_MODE === 'develop' ? env.DEV_PC_TRAINING_URL : env.PC_TRAINING_URL;

class PcTrainingComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'postMessageToPCTraining',
            'waitPostMessage',
            'handleRequestClose',
            'handleLoadPCTraining'
        ]);
        

    }

    componentDidMount () {
        window.addEventListener('message', this.waitPostMessage);
        window.addEventListener('resize', setHeight);

        // 同時にやるとNullになるため、遅延させる
        setTimeout(() => {
            setHeight();
        });

    }


    componentWillUnmount () {
        window.removeEventListener('message', this.waitPostMessage);
        window.removeEventListener('resize', setHeight);
    }

    handleLoadPCTraining () {
        const ifrm = document.getElementById('pctraining');
        if (ifrm) {
            // ifrm.contentWindow.focus();
            ifrm.focus();
        }
    }

    handleRequestClose () {
        this.props.onOpenTopPage();
        this.props.onRequestClose();
    }


    waitPostMessage (res) {
        // if (res.origin === ORIGIN_URL) {
        //    if (res.data) {
        //        this.postMessageToPCTraining();
        //    }
        //    window.removeEventListener('message', this.waitPostMessage);
        //    console.log('get post :', env.PC_TRAINING_URL);
        // }

        if (res.data === 'close' &&
            (res.origin === env.PC_TRAINING_URL ||
                res.origin === env.DEV_PC_TRAINING_URL ||
                res.origin === 'http://localhost:8000')) {
            // const iframe = document.getElementById('pctraining');
            // iframe.parentNode.removeChild(iframe);
            this.handleRequestClose();
        }
    }

    postMessageToPCTraining () {
        const iframeElement = document.getElementsByClassName(styles.game)[0];
        /* console.log('post', iframeElement.contentWindow.origin); */
        const allow = window.sessionStorage.getItem(['Allow_Login_Pctraining']);
        iframeElement.contentWindow.postMessage(allow === 'Allow', PC_TRAINING_URL);
    }

    render () {
        return (
            <Modal
                fullScreen
                id={this.props.id}
                contentLabel={''}
                onRequestClose={this.handleRequestClose}
                // ppskBackButton
                // header
                // isShowingBackButton
            >
                <button
                    onClick={this.handleRequestClose}
                    onTouch={this.handleRequestClose}
                    className={styles.backButton}
                >
                    <span>{'トップへ'}</span>
                </button>
                <iframe
                    id="pctraining"
                    src={PC_TRAINING_URL}
                    className={styles.game}
                    loading="lazy"
                    allowFullScreen
                    onLoad={this.handleLoadPCTraining}
                />

            </Modal>
        );
    }
}

PcTrainingComponent.propTypes = {
    // intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
    onOpenTopPage: PropTypes.func,
    onRequestClose: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onOpenTopPage: () => dispatch(openFirstTimeUI())
});


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PcTrainingComponent));
