/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './terms.css';
import pokemonstartkitStyles from '../pokemonstartkit.css';
import PokemonstartkitButtonComponent from '../pokemonstartkit-button/pokemonstartkit-button.jsx';

const Terms = props => {
    const [isAgreed, setIsAgreed] = useState(false);
    const thisProps = {
        handleAgreementChange: () => setIsAgreed(!isAgreed)
    };

    const checkAgreement = () => {
        const registButton = document.getElementById('termAgreement');
        if (isAgreed) {
            registButton.disabled = false;
            registButton.classList.add('regist-on');
            registButton.classList.remove('regist-off');
        } else {
            registButton.disabled = true;
            registButton.classList.remove('regist-on');
            registButton.classList.add('regist-off');
        }
    };

    useEffect(() => {
        checkAgreement();
    }, [isAgreed]);
    return (
        <>
            <div className={styles.secInputStartkit}>
                {
                    props.isSchoolTeacher ?
                        <>
                            <p className={styles.read}>
                                本教材を使用する際には利用規約に同意いただく必要があります。
                            </p>
                        </> :
                        <>
                            <p className={styles.readMeRed}>
                                本教材は個人でのご使用や、営利目的でのご使用は行えませんのでご注意ください。
                            </p>
                            <p className={styles.readMe}>
                                本教材をご使用いただける施設は、下記の日本国内の教育施設です。
                            </p>
                            <div className={styles.schoolSetContainer}>
                                <p className={styles.readMe}>
                                    小学校（公設学童を含む）、特別支援学校、外国人学校（日本国内に限る）
                                    <br />
                                    幼稚園、保育園、保育所、認定こども園
                                    <br />
                                    中学校、義務教育学校、高等学校、中等教育学校
                                    <br />
                                    大学、高等専門学校、専修学校またはこれらに準ずる各種学校（学校教育法に規定のもの）
                                    <br />
                                    そのほか弊財団が指定した施設
                                </p>
                            </div>
                            <p className={styles.readMe}>
                                上記対象の施設の場合、本教材を使用する際には利用規約に同意いただく必要があります。
                            </p>
                        </>
                }

                <div className={styles.termsWrap}>
                    <div className={styles.terms}>
                        <div className={styles.inner}>
                            <h4>利用規約</h4>
                            <h5>第1条（目的）</h5>
                            <p>本規約は、一般財団法人ポケモン・ウィズ・ユー財団が提供するサービス「ポケモンプログラミングスタートキット」に含まれる
                                コンテンツをご利用いただく上でお守りいただきたい事項を定めたものであり、弊財団と利用者の間における一切の関係に適用されます。</p>
                            <h5>第2条（定義）</h5>
                            <p>本規約において使用する用語は、それぞれ以下の意味を有するものとします。</p>
                            <ol className={styles.withParentheses}>
                                <li>「弊財団」とは、一般財団法人ポケモン・ウィズ・ユー財団をいいます。</li>
                                <li>「利用者」とは、本利用を希望される方をいいます。</li>
                                <li>「本サービス」とは、「ポケモンプログラミングスタートキット」をいいます。</li>
                                <li>「本利用」とは、本規約の規定に基づいて本施設において本コンテンツを利用することをいいます。</li>
                                <li>「本施設」とは、日本国内の小学校（公設学童を含む）、
                                    弊財団が本サービスに関連して実施するセミナー等のイベント会場その他弊財団が指定する場所をいいます。</li>
                                <li>「本素材」とは、本サービスにおいて利用することができるポケモンに関する画像データをいいます。</li>
                                <li>「本コンテンツ」とは、本素材を含む、本サービスにおいて弊財団が提供するすべてのコンテンツをいいます。</li>
                                <li>「本サイト」とは、弊財団が運営する本サービスに関するウェブサイトをいいます。</li>
                                <li>「児童等」とは、本施設を利用する児童、生徒、又は学生をいいます。</li>
                                <li> 「反社会的勢力」とは、暴力団、暴力団構成員、準構成員、暴力団関連企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、
                                    特殊知能暴力集団、その他の反社会的勢力、もしくはそれらに該当しなくなったときから５年を経過しない者をいいます。</li>
                            </ol>
                            <h5>第3条（利用許諾）</h5>
                            <ol>
                                <li>弊財団は、利用者に対し、児童等の学習用の教材として利用する目的に限り、本施設において本コンテンツを自ら利用し、
                                    又は児童等に本コンテンツを利用させる権利（利用者の責任のもとで児童等の家庭において利用させることを含む）を非独占的に許諾します。
                                    ただし、本素材については、
                                    本サービスにおけるプログラミング教材「プログラミングでポケモンをうごかしてみよう」のためにのみ利用できるものとします。</li>
                                <li>利用者から弊財団に対する前項の許諾の対価は、無償とします。</li>
                                <li>利用者は、本利用のために必要な機器、通信手段等を自らの責任で用意し、本利用のために必要な費用を自ら負担するものとします。</li>
                                <li>本規約の他の規定にかかわらず、利用者は、著作権法に定める著作権の制限に該当する場合は、
                                    その範囲において本コンテンツを利用できるものとします。</li>
                            </ol>
                            <h5>第4条（禁止事項）</h5>
                            <ol>
                                <li>利用者は、以下の行為を行うことはできません。
                                    <ol className={styles.withParentheses}>
                                        <li>本規約に記載された条件の範囲を超えて、本コンテンツを利用すること。</li>
                                        <li>本コンテンツを、営利目的で利用すること。</li>
                                        <li>本素材を利用したコンピュータープログラム等の成果物を第三者又は公衆に対して送信し、又は送信可能な状態にすること。
                                            ただし、別途弊財団が別途許諾した場合に限り、弊財団が許諾した方法で当該成果物のみをダウンロードし弊財団に送信することができる。</li>
                                        <li>本コンテンツを加工、改変、翻案、編集等すること。
                                            （ただし、児童等が創作を行う場合及び本施設内のみで掲示、イベント利用を行う場合の制作物を除くものとします）。</li>
                                        <li>本コンテンツの第三者への販売、譲渡、利用許諾等を行うこと（ただし、本規約において別途定めがある場合を除く）</li>
                                        <li>本コンテンツを利用した物品、データ等の販売（営利目的の利用全般）、頒布、譲渡、公衆送信、貸与、リース、輸出、再許諾をすること。</li>
                                        <li>本コンテンツの著作権表示及び商標表示を除去すること。</li>
                                        <li>本コンテンツの不正な利用を目的としたプログラム等を開発すること。また、そのようなプログラム等を他者に提供し、又は利用させること。</li>
                                        <li>本サイトのサーバに設定された技術的な制限を解除及び無効化すること。</li>
                                        <li>本サイトのサーバに侵入する行為、本サイトのサーバに誤作動、バグ、その他の不具合を発生させ得る行為、本サイトのデータを改変、修正、翻案する行為。</li>
                                        <li>公序良俗に反する行為その他法令に違反する行為又はそれらのおそれのある行為。</li>
                                        <li>弊財団及び第三者の知的財産権を侵害する行為。</li>
                                        <li>弊財団及び第三者の名誉、信用及びプライバシー等の権利を侵害する行為
                                            （差別、侮辱、誹謗中傷し、名誉若しくは信用の毀損等を含みますが、これに限られないものとします。）。</li>
                                        <li>ポケモンに社会的又は教育的に悪影響を及ぼすおそれのある行為。</li>
                                        <li>特定の個人、団体、法人又は商品・サービス等を支援若しくは推薦し、又はそれらを行うおそれがあると認められる行為。</li>
                                        <li>特定の政治的、宗教的又は思想的主張のために本コンテンツを利用する行為。</li>
                                        <li>その他、弊財団が不適当と判断する行為。</li>
                                        <li>前各号に準ずる行為。</li>
                                    </ol>
                                </li>
                                <li>利用者は、本規約に違反した場合、直ちに第３条第１項により許諾された権利を失うものとします。
                                    当該失効により利用者に損害が生じても、弊財団は一切の損害賠償義務を負担しないものとします。</li>
                                <li>利用者が本規約に違反して弊財団又は第三者に損害が生じた場合、当該利用者は、その損害をすべて賠償する責任を負うものとします。</li>
                            </ol>
                            <h5>第5条（責任の制限）</h5>
                            <ol>
                                <li>弊財団は、本コンテンツ及び利用者による本利用が著作権その他の第三者の権利を侵害しないこと及び瑕疵がないことを何ら保証しないものとします。</li>
                                <li>弊財団は、本コンテンツの利用または利用不能から生じた利用者又は第三者の損害（パソコン等の損傷やデータの消失を含みます）に関して、いかなる責任も負わないものとします。</li>
                                <li>利用者は、本サービスが現状有姿で提供され、弊財団が本コンテンツの有効性、正確性、適合性、確実性について何ら保証しないことを了承します。</li>
                                <li>本サイトにおいて、第三者の運営するウェブサイト又はリソースへのリンクが提供される場合がありますが、弊財団は、当該ウェブサイト等の内容、利用及びその結果について、いかなる責任も負わないものとします。また、弊財団は、利用者が当該ウェブサイト等の利用により損害を受けた場合も、当該損害について、いかなる責任も負わないものとします。</li>
                                <li>弊財団は、弊財団の責に帰すべき事由（故意または重過失がある場合を含みます）により、利用者に損害を与えた場合には、その損害を賠償するものとします。当該損害に対して弊財団が負担する責任は、金銭賠償に限られるものとします。</li>
                                <li>前項の賠償の対象となる損害は、現実に発生した直接かつ通常の範囲の損害に限られるものとします。</li>
                            </ol>
                            <h5>第6条（権利の帰属）</h5>
                            <ol>
                                <li>本コンテンツの著作権その他一切の権利は、弊財団又は弊財団に権利を許諾した第三者に帰属しています。 </li>
                                <li>利用者は、本コンテンツその他ポケモンに関係する著作権が発生する場合、当該著作権の発生と同時にすべての著作権（著作権法第２７条及び第２８条規定の権利を含む）が、弊財団に帰属することに合意するとともに、利用者及びその発生に関係する全ての第三者が著作権及び著作者人格権に基づく主張を一切行わないことを、弊財団に対して保証するものとします。</li>
                            </ol>
                            <h5>第7条（停止、変更、終了等）</h5>
                            <ol>
                                <li>利用者は、弊財団が本コンテンツのアップデート版又は新バージョンの利用を求めた場合、これに従うものとします。</li>
                                <li>弊財団は、自己の裁量でいつでも、本サイトの運営その他本利用に関する業務を停止、変更、終了等することができるものとします。</li>
                                <li>弊財団が規定する本利用に関する利用方法、説明、ルール、細則その他は、名称の如何にかかわらず、すべて本規約の一部を構成するものとします。</li>
                                <li>弊財団は、利用者の事前の承諾を得ることなく、本規約の全部又は一部を変更することができるものとします。本規約の全部又は一部の変更は、本サイトへの掲載その他弊財団が適当と判断する方法により利用者に対し通知をした時点をもって効力を生じるものとし、利用者は、変更後の本規約にのみ従うものとします。本規約変更後に利用者が本利用を行った場合、当該利用者が変更後の本規約に同意したものとみなします。</li>
                                <li>前各項のいずれにおいても、弊財団は利用者に対して一切の責任を負わないものとします。</li>
                            </ol>
                            <h5>第8条（権利義務の譲渡等の禁止）</h5>
                            <p>利用者は、弊財団の事前の書面による承諾なしに、本規約に基づく利用者の地位及び権利を第三者に譲渡すること、再許諾すること、又は担保に供する等第三者の権利を設定することはできません。</p>
                            <h5>第9条（反社会的勢力の排除）</h5>
                            <p>利用者は、以下の各号のいずれにも該当しないことを弊財団に対して表明、保証します。</p>
                            <ol className={styles.withParentheses}>
                                <li>反社会的勢力。</li>
                                <li>東京都暴力団排除条例に規定される規制対象者。</li>
                                <li>反社会的勢力が経営を支配又は実質的に関与していると認められること。</li>
                                <li>自ら又は第三者の不正の利益を図るもしくは第三者に損害を加える等の目的で反社会的勢力を利用したと認められること。</li>
                                <li>反社会的勢力に資金等を提供し、又は便宜や利益を供するなどの関与をしていると認められること。</li>
                                <li>自ら又は第三者を利用して暴力的な要求行為、法的責任を超えた不当な要求行為、脅迫的な言動を行い、もしくは暴力を用いる等の行為を行うこと。</li>
                                <li>その他、前各号に準ずること。</li>
                            </ol>
                            <h5>第10条（準拠法、裁判管轄、その他）</h5>
                            <ol>
                                <li>本規約の準拠法は、日本法とします。</li>
                                <li>本規約に関する紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
                                <li>本規約のいずれかの規定が無効又は執行不能であるとされた場合、当該規定は、可能な最大限の範囲において執行され、本規約のその他の規定は引き続き完全な効力を有するものとします。</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <label>
                    <input
                        type="checkbox"
                        id="name"
                        checked={isAgreed}
                        className={styles.toggleInput}
                        onChange={thisProps.handleAgreementChange}
                    />
                    {'規約に同意する'}
                </label>
                <div className={pokemonstartkitStyles.pokemonstartkitContainer}>
                    <PokemonstartkitButtonComponent
                        onClick={props.onSendAgreement}
                        id="termAgreement"
                        enabled={isAgreed}
                    >
                        {'送信する'}
                    </PokemonstartkitButtonComponent>
                </div>
            </div>
        </>
    );
};

Terms.propTypes = {
    onSendAgreement: PropTypes.func,
    isSchoolTeacher: PropTypes.bool
};

export default Terms;
