import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
    activateDeck,
    closeCardHelps,
    shrinkExpandCards,
    nextStep,
    prevStep,
    dragCard,
    startDrag,
    endDrag,
    selectFeature
} from '../reducers/cards-help';

import {
    openPokemonHelpLibrary
} from '../reducers/modals';

import CardsComponent from '../components/cards/cards-help-rnd.jsx';
import {loadImageData} from '../lib/libraries/decks/translate-image.js';
import {notScratchDesktop} from '../lib/isScratchDesktop';

class CardsHelp extends React.Component {
    componentDidMount () {
        if (this.props.locale !== 'en') {
            loadImageData(this.props.locale);
        }

        this.setFeature();
    }
    componentDidUpdate (prevProps) {
        if (this.props.locale !== prevProps.locale) {
            loadImageData(this.props.locale);
        }

        if (prevProps.step !== this.props.step) {
            this.setFeature();
        }
    }

    setFeature () {
        const steps = this.props.content[this.props.activeDeckId].steps;
        const nextFeature = steps[this.props.step].feature ? steps[this.props.step].feature.sort() : [];
        if (JSON.stringify(nextFeature) !== JSON.stringify(this.props.featureId)) {
            this.props.onSelectFeature(nextFeature);
        }
    }

    render () {
        return (
            <CardsComponent {...this.props} />
        );
    }
}

CardsHelp.propTypes = {
    locale: PropTypes.string.isRequired,
    onSelectFeature: PropTypes.func
};

const mapStateToProps = state => ({
    visible: state.scratchGui.cardsHelp.visible,
    content: state.scratchGui.cardsHelp.content,
    activeDeckId: state.scratchGui.cardsHelp.activeDeckId,
    step: state.scratchGui.cardsHelp.step,
    expanded: state.scratchGui.cardsHelp.expanded,
    x: state.scratchGui.cardsHelp.x,
    y: state.scratchGui.cardsHelp.y,
    isRtl: state.locales.isRtl,
    locale: state.locales.locale,
    dragging: state.scratchGui.cardsHelp.dragging,
    showVideos: notScratchDesktop(),
    featureId: state.scratchGui.cardsHelp.feature
    
});

const mapDispatchToProps = dispatch => ({
    onActivateDeckFactory: id => () => dispatch(activateDeck(id)),
    onShowAll: () => {
        dispatch(openPokemonHelpLibrary());
        dispatch(closeCardHelps());
    },
    onCloseCards: () => {
        dispatch(selectFeature([]));
        dispatch(closeCardHelps());
    },
    onShrinkExpandCards: () => dispatch(shrinkExpandCards()),
    onNextStep: () => dispatch(nextStep()),
    onPrevStep: () => dispatch(prevStep()),
    onDrag: (e_, data) => dispatch(dragCard(data.x, data.y)),
    onStartDrag: () => dispatch(startDrag()),
    onEndDrag: () => dispatch(endDrag()),
    onSelectFeature: id => dispatch(selectFeature(id))
    
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardsHelp);
