import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
// import {connect} from 'react-redux';

import styles from '../select-school-ui/select-school-ui.css';
import PokemonstartkitLogo from '../first-ui-library/pokemonstartkit-logo.jsx';

import PokemonstartkitButtonComponent,
{PokemonstartkitSmallButtonComponent}
from '../first-ui-library/pokemonstartkit-button/pokemonstartkit-button.jsx';

import SearchSelectBox from '../select-school-ui/select-search.jsx';

const submitButtonName = 'submitButton';

const prefDropDownId = 'dropdown-pref';
const cityDropDownId = 'dropdown-city';
const schoolDropDownId = 'dropdown-school';

class UserEntryUi extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectCity',
            'handleSelectSchool',
            'handleUserDataExport',
            'handleSelectPrefecture',
            'handleChangeSelectMode',
            'handleOpenOverlay',
            'handleCloseOverlay',
            'handleUserDataUpdate'
        ]);

        this.state = {
            isOpenedOverlay: false,
            isAvailable: true
        };
    }

    handleSelectPrefecture (e) {
        if (this.props.isTextInputMode) {
            this.props.onSelectPrefTextMode(e);
        } else {
            this.props.onSelectPref(e);
        }
    }

    handleSelectCity (e) {
        this.props.onSelectCity(e);
    }

    handleSelectSchool (e) {
        this.props.onSelectSchool(e);
    }

    handleChangeSelectMode () {
        this.props.onChangeSelectMode();
    }

    handleUserDataExport () {
        this.props.onExport();
    }

    handleUserDataUpdate () {
        this.props.onUpdateUser();
    }

    handleOpenOverlay () {
        this.props.onOpenOverlay();
    }

    handleCloseOverlay () {
        this.props.onCloseOverlay();
    }

    render () {
        return (
            <>
                <div className={styles.subBodyWrapper}>
                    <PokemonstartkitLogo />
                    <div className={styles.submitTextBody}>
                        {
                            this.props.isEditMode ?
                                <>
                                    <p>
                                        ユーザーデータの<ruby>変更<rt>へんこう</rt></ruby>を<ruby>行<rt>おこな</rt></ruby>います。
                                    </p>
                                    <p>
                                        <ruby>変更後<rt>へんこうご</rt></ruby>のデータを<ruby>入力<rt>にゅうりょく</rt></ruby>して、
                                        <ruby>変更<rt>へんこう</rt></ruby>ボタンを<ruby>押<rt>お</rt></ruby>してください。
                                    </p>
                                </> :
                                <>
                                    <p>
                                        <ruby>大会<rt>たいかい</rt></ruby>に<ruby>応募<rt>おうぼ</rt></ruby>するためのユーザーデータの<ruby>登録<rt>とうろく</rt></ruby>を<ruby>行<rt>おこな</rt></ruby>います。
                                    </p>
                                    <p>
                                        <ruby>必要<rt>ひつよう</rt></ruby>なことを<ruby>入力<rt>にゅうりょく</rt></ruby>して、
                                        <ruby>登録<rt>とうろく</rt></ruby>ボタンを<ruby>押<rt>お</rt></ruby>してください。
                                    </p>
                                </>
                        }

                    </div>

                    <div className={styles.bodyWrapper}>
                        <div
                            className={styles.selectSchoolModal}
                        >
                            <p>
                                ユーザー<ruby>名<rt>めい</rt></ruby>
                            </p>
                            <input
                                id={'userName'}
                                type="text"
                                onChange={this.props.onSetUserName}
                                placeholder="ユーザー名を入力(30文字まで)"
                                maxLength={30}
                            />

                        </div>
                        <div
                            id={prefDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            <p>
                                <ruby>都道府県<rt>とどうふけん</rt></ruby>
                            </p>
                            <SearchSelectBox
                                id={'prefNameSelector'}
                                onChangeValue={this.handleSelectPrefecture}
                                options={
                                    this.props.prefDatas.map(item => ({
                                        id: item.prefName,
                                        value: item.prefCode.toString(),
                                        label: item.prefName
                                    }))
                                }
                                compareText={this.props.prefName}
                            />
                        </div>
                        <div
                            id={cityDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            <p>
                                <ruby>市区町村<rt>しくちょうそん</rt></ruby>
                            </p>
                            {
                                this.props.isTextInputMode ?
                                    <input
                                        id="cityName_Competition"
                                        type="text"
                                        onChange={this.props.onSetCityName}
                                        placeholder="市区町村名を入力(20文字まで)"
                                        maxLength="20"
                                    /> : <SearchSelectBox
                                        id={'cityNameSelector'}
                                        onChangeValue={this.handleSelectCity}
                                        options={this.props.cityOptions}
                                        disabled={!(this.props.prefName && this.props.isShowingCity)}
                                    />
                            }
                        </div>
                        <div
                            id={schoolDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            {
                                this.props.isTextInputMode ?
                                    <>
                                        <p>
                                            <ruby>小学校<rt>しょうがっこう</rt></ruby>/それ<ruby>以外<rt>いがい</rt></ruby>の
                                            <ruby>教育施設名<rt>きょういくしせつめい</rt></ruby>
                                        </p>

                                        <input
                                            id="schoolName_Competition"
                                            type="text"
                                            onChange={this.props.onSetSchoolName}
                                            placeholder="施設名を入力(64文字まで)"
                                            maxLength="64"
                                        />
                                    </> :
                                    <>
                                        <p>
                                            <ruby>小学校名<rt>しょうがっこうめい</rt></ruby>
                                        </p>
                                        <SearchSelectBox
                                            id={'schoolNameSelector'}
                                            onChangeValue={this.handleSelectSchool}
                                            options={this.props.schoolOptions}
                                            disabled={!(this.props.cityName && this.props.isShowingSchool)}
                                        />
                                    </>

                            }

                        </div>

                        <div className={styles.sendBlock}>
                            {
                                this.props.isErrorSending ?
                                    <PokemonstartkitButtonComponent
                                        id={submitButtonName}
                                        onClick={this.handleOpenOverlay}
                                        enabled
                                    >
                                        <ruby>登録<rt>とうろく</rt></ruby>できない<ruby>方<rt>かた</rt></ruby>はこちら
                                    </PokemonstartkitButtonComponent> :
                                    (this.props.isEditMode ?
                                        <>
                                            {
                                                (
                                                    this.props.isAvailableSubmittion &&
                                                    this.props.cityName !== '' &&
                                                    this.props.prefName !== '' &&
                                                    this.props.schoolName !== '' &&
                                                    this.props.userName.match(/\S/g)
                                                ) ?
                                                    <>
                                                        <PokemonstartkitButtonComponent
                                                            id={submitButtonName}
                                                            onClick={this.handleUserDataUpdate}
                                                            enabled
                                                        >
                                                            <ruby>変更<rt>へんこう</rt></ruby>
                                                        </PokemonstartkitButtonComponent>
                                                    </> :
                                                    <PokemonstartkitButtonComponent
                                                        id={submitButtonName}
                                                    >
                                                        <ruby>変更<rt>へんこう</rt></ruby>
                                                    </PokemonstartkitButtonComponent>
                                            }
                                        </> :
                                        <>
                                            {
                                                (
                                                    this.props.isAvailableSubmittion &&
                                                    this.props.cityName !== '' &&
                                                    this.props.prefName !== '' &&
                                                    this.props.schoolName !== '' &&
                                                    this.props.userName.match(/\S/g)
                                                ) ?
                                                    <>
                                                        <PokemonstartkitButtonComponent
                                                            id={submitButtonName}
                                                            onClick={this.handleUserDataExport}
                                                            enabled
                                                        >
                                                            <ruby>登録<rt>とうろく</rt></ruby>
                                                        </PokemonstartkitButtonComponent>
                                                    </> :
                                                    <PokemonstartkitButtonComponent
                                                        id={submitButtonName}
                                                    >
                                                        <ruby>登録<rt>とうろく</rt></ruby>
                                                    </PokemonstartkitButtonComponent>
                                            }
                                        </>

                                    )

                            }

                        </div>
                        {
                            this.props.isTextInputMode ?
                                <PokemonstartkitSmallButtonComponent
                                    id={'noschoolButtonName'}
                                    enabled
                                    onClick={this.handleChangeSelectMode}
                                    reversalMode
                                >
                                    <ruby>小学校選択<rt>しょうがっこうせんたく</rt></ruby>に
                                    <ruby>戻<rt>もど</rt></ruby>る
                                </PokemonstartkitSmallButtonComponent> :
                                <PokemonstartkitSmallButtonComponent
                                    id={'noschoolButtonName'}
                                    onClick={this.handleChangeSelectMode}
                                    enabled
                                    reversalMode
                                >
                                    <>
                                        <ruby>選択肢<rt>せんたくし</rt></ruby>が<ruby>見<rt>み</rt></ruby>つからない/
                                        <ruby>小学校以外<rt>しょうがっこういがい</rt></ruby>の
                                        <ruby>利用者<rt>りようしゃ</rt></ruby>
                                        はこちら
                                    </>
                                </PokemonstartkitSmallButtonComponent>
                        }
                    </div>
                </div>
                <div className={styles.refference}>
                    <ruby>市区町村<rt>しくちょうそん</rt></ruby>データの<ruby>出典<rt>しゅってん</rt></ruby>：
                    RESAS（<ruby>地域経済<rt>ちいきけいざい</rt></ruby><ruby>分析<rt>ぶんせき</rt></ruby>システム）
                </div>
            </>


        );
    }
}

UserEntryUi.propTypes = {
    prefDatas: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            id: PropTypes.number,
            name: PropTypes.string

        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),

    onSelectCity: PropTypes.func,
    onSelectSchool: PropTypes.func,
    onSelectPref: PropTypes.func,
    onSelectPrefTextMode: PropTypes.func,
    onChangeSelectMode: PropTypes.func,
    onOpenOverlay: PropTypes.func,
    onCloseOverlay: PropTypes.func,

    onExport: PropTypes.func,

    onSetCityName: PropTypes.func,
    onSetSchoolName: PropTypes.func,
    onSetUserName: PropTypes.func,

    isTextInputMode: PropTypes.bool,
    schoolName: PropTypes.string,
    userName: PropTypes.string,
    cityName: PropTypes.string,
    prefName: PropTypes.string,

    isShowingCity: PropTypes.bool,
    isShowingSchool: PropTypes.bool,

    isAvailableSubmittion: PropTypes.bool,

    isErrorSending: PropTypes.bool,
    isEditMode: PropTypes.bool,

    onUpdateUser: PropTypes.func
};

export default injectIntl(UserEntryUi);
