/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';

import Modal from '../../../containers/noheader-modal.jsx';
import SelectSchoolUI from '../../../containers/select-school-ui.jsx';
import Terms from './terms.jsx';
import PokemonstartkitLogo from '../pokemonstartkit-logo.jsx';

class TeacherUiComponent extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClick',
            'handleClose',
            'handleEscapeSelectSchool',
            'handleSwitchDiv',
            'handleChangeToSchoolTeacher'

        ]);
        this.state = {
            isTermsOpen: true,
            isSelectSchoolOpen: false
        };
    }

    handleSwitchDiv () {
        this.setState({
            isTermsOpen: false,
            isSelectSchoolOpen: true
        });
    }


    handleClick () {
        this.props.onRequestOpen();
    }
    handleClose () {
        this.props.onRequestClose();
    }

    handleEscapeSelectSchool () {
        this.setState({
            isTermsOpen: true,
            isSelectSchoolOpen: false
        });
        // this.props.onRequestEscapeSelectSchool();
    }

    enableNextButton () {
        const button = document.getElementById('nextButton');
        button.disabled = false;
    }

    handleChangeToSchoolTeacher () {
        this.props.onChangeToSchoolTeacher();
    }

    render () {
        return (
            <>
                {this.state.isTermsOpen &&
                    <Modal
                        fullScreen
                        contentLabel={this.props.title}
                        id={this.props.id}
                        onRequestOpen={this.handleClick}
                        onRequestClose={this.props.onSelectBackButton}
                        header
                        isShowingBackButton
                    >
                        <PokemonstartkitLogo />
                        <Terms
                            onSendAgreement={this.handleSwitchDiv}
                            isSchoolTeacher={this.props.isSchoolTeacher}
                        />
                    </Modal>}
                {
                    this.state.isSelectSchoolOpen &&
                    <SelectSchoolUI
                        onRequestClose={this.handleClose}
                        onRequestEscapeSelectSchool={this.handleEscapeSelectSchool}
                        isCompetitionMode={false}
                        isSchoolTeacher={this.props.isSchoolTeacher}
                        onChangeToSchoolTeacher={this.props.onChangeToSchoolTeacher}
                    />
                }
            </>
                
        );
    }
}
TeacherUiComponent.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.object,
    // modalTitleList: PropTypes.arrayOf(PropTypes.string),
    // selectedId: PropTypes.number,
    onRequestOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
    onRequestEscapeSelectSchool: PropTypes.func,
    isSchoolTeacher: PropTypes.bool,

    onSelectBackButton: PropTypes.func,
    onChangeToSchoolTeacher: PropTypes.func
};

export default injectIntl(TeacherUiComponent);
