const SET_PROJECT_SAVED = 'projectSaving/SET_PROJECT_SAVING_STATE';
const SET_META_SAVED = 'projectSaving/SET_META_SAVING_STATE';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialState = {
    project: null,
    meta: null

};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_PROJECT_SAVED:
        return Object.assign({}, state, {
            project: action.saveState
        });
    case SET_META_SAVED:
        return Object.assign({}, state, {
            meta: action.saveState
        });
    default:
        return state;
    }
};
const setProjectSavingState = state => ({
    type: SET_PROJECT_SAVED,
    saveState: state
});

const setMetaSavingState = state => ({
    type: SET_META_SAVED,
    saveState: state
});

export {
    reducer as default,
    initialState as projectSavingInitialState,
    setProjectSavingState,
    setMetaSavingState
};
