/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {FormattedMessage} from 'react-intl';

export default
[
    {
        "name": "ピカチュウ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "a7e8c3b3efb16d66d53bc86b6f1c60a3",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "a7e8c3b3efb16d66d53bc86b6f1c60a3.png"
    },
    {
        "name": "ピカチュウ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "729c2829be2f872459b1750a781d5c16",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "729c2829be2f872459b1750a781d5c16.png"
    },
    {
        "name": "ピカチュウ3",
        "tags": [
            "pokemon"
        ],
        "assetId": "cb5f29d94faf871ef8c5bc92e48a2d14",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "cb5f29d94faf871ef8c5bc92e48a2d14.png"
    },
    {
        "name": "ピカチュウ4",
        "tags": [
            "pokemon"
        ],
        "assetId": "f2a6e722bfb7117cbdbd4d8a7bef11ce",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "f2a6e722bfb7117cbdbd4d8a7bef11ce.png"
    },
    {
        "name": "ピカチュウ5",
        "tags": [
            "pokemon"
        ],
        "assetId": "d477da75b526ab4f1fcf8bb615c7e2e1",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "d477da75b526ab4f1fcf8bb615c7e2e1.png"
    },
    {
        "name": "ピカチュウ6",
        "tags": [
            "pokemon"
        ],
        "assetId": "11f2c97f833713b5e5cbadbb1f80b5dc",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "11f2c97f833713b5e5cbadbb1f80b5dc.png"
    },
    {
        "name": "ピカチュウ7",
        "tags": [
            "pokemon"
        ],
        "assetId": "478308a25af96b1c41dfa8bfa5f7ff61",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "478308a25af96b1c41dfa8bfa5f7ff61.png"
    },
    {
        "name": "ピカチュウ8",
        "tags": [
            "pokemon"
        ],
        "assetId": "bff23f4ebe184c9082631130ed6826d4",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "bff23f4ebe184c9082631130ed6826d4.png"
    },
    {
        "name": "ピカチュウ9",
        "tags": [
            "pokemon"
        ],
        "assetId": "5fed8739f9ea4d6d0a5323c80ab9d6e8",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "5fed8739f9ea4d6d0a5323c80ab9d6e8.png"
    },
    {
        "name": "ピカチュウ10",
        "tags": [
            "pokemon"
        ],
        "assetId": "63ed8a07338e60efc2e2dff34a15f480",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "63ed8a07338e60efc2e2dff34a15f480.png"
    },
    {
        "name": "ピカチュウ11",
        "tags": [
            "pokemon"
        ],
        "assetId": "9a84bf8f382dcc1aedad8b8e7b21586e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "9a84bf8f382dcc1aedad8b8e7b21586e.png"
    },
    {
        "name": "イーブイ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "65c480c97f8bcc682152b8b122d43653",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "65c480c97f8bcc682152b8b122d43653.png"
    },
    {
        "name": "イーブイ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "e36b4c6e2022c5a4355620ab2c6d3465",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "e36b4c6e2022c5a4355620ab2c6d3465.png"
    },
    {
        "name": "イーブイ3",
        "tags": [
            "pokemon"
        ],
        "assetId": "ff0570caf717eaad96d573f299dad142",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "ff0570caf717eaad96d573f299dad142.png"
    },
    {
        "name": "イーブイ4",
        "tags": [
            "pokemon"
        ],
        "assetId": "3c87c276df321e72b87f2e2f2f90ed7e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "3c87c276df321e72b87f2e2f2f90ed7e.png"
    },
    {
        "name": "ヒトカゲ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "4d9a5eeb262c4bb3a74b00822a2e4cd6",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "4d9a5eeb262c4bb3a74b00822a2e4cd6.png"
    },
    {
        "name": "ヒトカゲ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "94b168e5c99dcf765d5c0e6238844574",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "94b168e5c99dcf765d5c0e6238844574.png"
    },
    {
        "name": "ゼニガメ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "173a1065c6258d5d821754b61383c0c2",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "173a1065c6258d5d821754b61383c0c2.png"
    },
    {
        "name": "ゼニガメ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "a8f5403c41b0017422fff4c0e6a07e4e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "a8f5403c41b0017422fff4c0e6a07e4e.png"
    },
    {
        "name": "フシギダネ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "d360baf767a6cb24c2d900b47e9e3a0c",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "d360baf767a6cb24c2d900b47e9e3a0c.png"
    },
    {
        "name": "フシギダネ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "4ef8ce2a289f84e59a5fb5685cfa7398",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "4ef8ce2a289f84e59a5fb5685cfa7398.png"
    },
    {
        "name": "モンスターボール",
        "tags": [
            "tools"
        ],
        "assetId": "87a04a54893e97c0058dd0a8e6b19fb4",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "87a04a54893e97c0058dd0a8e6b19fb4.png"
    },
    {
        "name": "スーパーボール",
        "tags": [
            "tools"
        ],
        "assetId": "4c3be265f7467f6d9f2d4b29d430a7f7",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "4c3be265f7467f6d9f2d4b29d430a7f7.png"
    },
    {
        "name": "ハイパーボール",
        "tags": [
            "tools"
        ],
        "assetId": "b7e69dc536a1f803a4ff1e6af19efbd1",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "b7e69dc536a1f803a4ff1e6af19efbd1.png"
    },
    {
        "name": "マスターボール",
        "tags": [
            "tools"
        ],
        "assetId": "2738329e7a1e7482320826d0c7e6ff7d",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "2738329e7a1e7482320826d0c7e6ff7d.png"
    },
    {
        "name": "タマゴ",
        "tags": [
            "tools"
        ],
        "assetId": "a1d2708c4941f7b8fc96da1284f526b4",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "a1d2708c4941f7b8fc96da1284f526b4.png"
    },
    {
        "name": "みがわり",
        "tags": [
            "tools"
        ],
        "assetId": "e9f37570ea72b04d6bdfdc48bacf14cf",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "e9f37570ea72b04d6bdfdc48bacf14cf.png"
    },
    {
        "name": "オボンのみ",
        "tags": [
            "tools"
        ],
        "assetId": "6a4e319c0d5e6884c4b72c2488d5c3a2",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "6a4e319c0d5e6884c4b72c2488d5c3a2.png"
    },
    {
        "name": "カゴのみ",
        "tags": [
            "tools"
        ],
        "assetId": "f561cf3cc5a7c47e132690843503bddf",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "f561cf3cc5a7c47e132690843503bddf.png"
    },
    {
        "name": "モモンのみ",
        "tags": [
            "tools"
        ],
        "assetId": "9218c6d1b9a0e35b61bbfe1c26b3f3fe",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "9218c6d1b9a0e35b61bbfe1c26b3f3fe.png"
    },
    {
        "name": "リザードン1",
        "tags": [
            "pokemon"
        ],
        "assetId": "c8e7fa4d8291ed610a396159d0e5b073",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "c8e7fa4d8291ed610a396159d0e5b073.png",
        "rotationCenterX": 440,
        "rotationCenterY": 320
    },
    {
        "name": "リザードン2",
        "tags": [
            "pokemon"
        ],
        "assetId": "5ed373d91d9c6700d46b03fe0c4560d9",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "5ed373d91d9c6700d46b03fe0c4560d9.png",
        "rotationCenterX": 450,
        "rotationCenterY": 310
    },
    {
        "name": "ディグダ1",
        "tags": [
            "pokemon"
        ],
        "assetId": "a6cb6525da60f5e9736477ef7df68897",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "a6cb6525da60f5e9736477ef7df68897.png"
    },
    {
        "name": "ディグダ2",
        "tags": [
            "pokemon"
        ],
        "assetId": "0e95e097e4646e4f213cef7d6e6ce377",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "0e95e097e4646e4f213cef7d6e6ce377.png"
    },
    {
        "name": "コイキング1",
        "tags": [
            "pokemon"
        ],
        "assetId": "fd08d6a3f53c8c8f320772af3db1da4e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "fd08d6a3f53c8c8f320772af3db1da4e.png"
    },
    {
        "name": "コイキング2",
        "tags": [
            "pokemon"
        ],
        "assetId": "2c9d87c46066fba07bf130826fa44c53",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "2c9d87c46066fba07bf130826fa44c53.png"
    },
    {
        "name": "シャワーズ",
        "tags": [
            "pokemon"
        ],
        "assetId": "48c1addd654e7c20e86c008b887e229c",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "48c1addd654e7c20e86c008b887e229c.png"
    },
    {
        "name": "サンダース",
        "tags": [
            "pokemon"
        ],
        "assetId": "6cd8c7a044c848da9a515d119618b6b1",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "6cd8c7a044c848da9a515d119618b6b1.png"
    },
    {
        "name": "ブースター",
        "tags": [
            "pokemon"
        ],
        "assetId": "3d5db6de182b8ce79a8dcde14fd9b05e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "3d5db6de182b8ce79a8dcde14fd9b05e.png"
    },
    {
        "name": "ミュウツー",
        "tags": [
            "pokemon"
        ],
        "assetId": "de8e511b8ee8b1a3df69aa9c6caae917",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "de8e511b8ee8b1a3df69aa9c6caae917.png"
    },
    {
        "name": "ヒバニー",
        "tags": [
            "pokemon"
        ],
        "assetId": "4a107cdd0e9f3103d6fca65de995bb15",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "4a107cdd0e9f3103d6fca65de995bb15.png"
    },
    {
        "name": "ケロマツ",
        "tags": [
            "pokemon"
        ],
        "assetId": "8922db76a0e4f6858f7b6f33e1635be1",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "8922db76a0e4f6858f7b6f33e1635be1.png"
    },
    {
        "name": "ロトム",
        "tags": [
            "pokemon"
        ],
        "assetId": "ef8267b73caf5d0f138c16b28be3696f",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "ef8267b73caf5d0f138c16b28be3696f.png"
    },
    {
        "name": "ニャオハ",
        "tags": [
            "pokemon"
        ],
        "assetId": "6f867109cc785d70020f41d6c1171b3b",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "6f867109cc785d70020f41d6c1171b3b.png"
    },
    {
        "name": "でんき",
        "tags": [
            "effects"
        ],
        "assetId": "6ed418f053d2975aa1b31950d91972fd",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "6ed418f053d2975aa1b31950d91972fd.png"
    },
    {
        "name": "ほのお",
        "tags": [
            "effects"
        ],
        "assetId": "f85a6e36f78e4fc0955a7b1906ef1102",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "f85a6e36f78e4fc0955a7b1906ef1102.png"
    },
    {
        "name": "みず",
        "tags": [
            "effects"
        ],
        "assetId": "c4468bf2d3472a9364b0a4e27e6f3bcc",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "c4468bf2d3472a9364b0a4e27e6f3bcc.png"
    },
    {
        "name": "くさ",
        "tags": [
            "effects"
        ],
        "assetId": "79d50b3e43645d700b9655a040e9262f",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "79d50b3e43645d700b9655a040e9262f.png"
    },
    {
        "name": "まる",
        "tags": [
            "others"
        ],
        "assetId": "b964c1df5ab493f8643e26e3b951fb0d",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "b964c1df5ab493f8643e26e3b951fb0d.png"
    },
    {
        "name": "ばつ",
        "tags": [
            "others"
        ],
        "assetId": "3e098c4de6c6dd00074db7340f5c85f9",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "3e098c4de6c6dd00074db7340f5c85f9.png"
    },
    {
        "name": "やじるし",
        "tags": [
            "others"
        ],
        "assetId": "1d3a9b5d9de41dd9965729ed6dc9e9a2",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "1d3a9b5d9de41dd9965729ed6dc9e9a2.png"
    },
    {
        "name": "チェック",
        "tags": [
            "others"
        ],
        "assetId": "8e5a26f3c3ac91ff92334686f5deaadf",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "8e5a26f3c3ac91ff92334686f5deaadf.png"
    },
    {
        "name": "はてな",
        "tags": [
            "others"
        ],
        "assetId": "e7cffeb19689e75740e6fc2b99acea1a",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "e7cffeb19689e75740e6fc2b99acea1a.png"
    },
    {
        "name": "びっくり",
        "tags": [
            "others"
        ],
        "assetId": "ac980fe7ce60963a693d14fd2080ce4c",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "ac980fe7ce60963a693d14fd2080ce4c.png"
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="くさむら"
                description="草むらのスプライト名"
                id="gui.sprite.grass"
            />
        ),
        "tags": [
            "others"
        ],
        "assetId": "2fcd5bce04eea52e0b663ee9e74d9732",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "2fcd5bce04eea52e0b663ee9e74d9732.png"
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="いわ"
                description="岩のスプライト名"
                id="gui.sprite.rock"
            />
        ),
        "tags": [
            "others"
        ],
        "assetId": "d4b06fae0f34bdae327313b2009efd4e",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "d4b06fae0f34bdae327313b2009efd4e.png"
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="かさ"
                description="傘のスプライト名"
                id="gui.sprite.umbrella"
            />
        ),
        "tags": [
            "others"
        ],
        "assetId": "61e81352d8eb396e8e6c1819598ef1b4",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "61e81352d8eb396e8e6c1819598ef1b4.png"
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="くるま"
                description="車のスプライト名"
                id="gui.sprite.car"
            />
        ),
        "tags": [
            "others"
        ],
        "assetId": "b7fbe4fc673b2a9a53a608779a2d9d9c",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "b7fbe4fc673b2a9a53a608779a2d9d9c.png"
    },
    {
        "name": "パソコン",
        "tags": [
            "others"
        ],
        "assetId": "801c18040e4142d18e6bc0cd9e74c3ed",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "801c18040e4142d18e6bc0cd9e74c3ed.png"
    },
    {
        "name": "リボン",
        "tags": [
            "others"
        ],
        "assetId": "f970ce63a5eace209cc8ade6150718c7",
        "bitmapResolution": 2,
        "dataFormat": "png",
        "md5ext": "f970ce63a5eace209cc8ade6150718c7.png"
    }
]
