const SET_USER_TEACHER = 'scratch-gui/user-authentication/IS_TEACHER';

const initialState = null;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_USER_TEACHER:
        return action.changed;
    default:
        return state;
    }
};
const setUserTeacher = () => ({
    type: SET_USER_TEACHER,
    changed: true
});
const setUserStudent = () => ({
    type: SET_USER_TEACHER,
    changed: false
});

const setUserType = userType => {
    if (userType == 'Teacher') {
        return ({
            type: SET_USER_TEACHER,
            changed: true
        });
    }
    if (userType == 'Student') {
        return ({
            type: SET_USER_TEACHER,
            changed: false
        });
    }

};

export {
    reducer as default,
    initialState as userAuthenticationInitialState,
    setUserTeacher,
    setUserStudent
};
