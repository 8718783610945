/* eslint-disable max-len */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './select-user.css';
import PokemonstartkitLogo from '../pokemonstartkit-logo.jsx';
import {PokemonstartkitTextCardComponent} from '../pokemonstartkit-button/pokemonstartkit-button.jsx';
import Modal from '../../../containers/noheader-modal.jsx';

// 画像が要らなくなったため もし使用するならActiveに
// import teacherThumbnail from '*';
// import studentThumbnail from '*';


class SelectUser extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectedTeacher',
            'handleSelectedStudent',
            'handleSelectedUser'
        ]);
    }

    handleSelectedTeacher () {
        this.props.onSelectedTeacher();
    }

    handleSelectedStudent () {
        this.props.onSelectedStudent();
    }

    handleSelectedUser () {
        this.props.onSelectedUser();
    }

    render () {
        return (
            <Modal
                fullScreen
                contentLabel={<p><ruby>先生<rt>せんせい</rt></ruby>、もしくは<ruby>児童<rt>じどう</rt></ruby>として<ruby>始<rt>はじ</rt></ruby>めるかを
                    <ruby>選<rt>えら</rt></ruby>んでください（<ruby>後<rt>あと</rt></ruby>から<ruby>変更<rt>へんこう</rt></ruby>もできます）</p>}
                id={this.props.id}
                onRequestClose={this.props.onRequestClose}
                header
            >
                <PokemonstartkitLogo />
                <div className={styles.selectUserContainer}>
                    <div className={styles.selectUserCards}>
                        <PokemonstartkitTextCardComponent
                            onClick={this.handleSelectedTeacher}
                            reversalMode
                            enabled
                        >
                            <h2 className={styles.cardTitle}><ruby>小学校<rt>しょうがっこう</rt></ruby>の<ruby>先生<rt>せんせい</rt></ruby>はこちら</h2>
                        </PokemonstartkitTextCardComponent>
                        <PokemonstartkitTextCardComponent
                            onClick={this.handleSelectedStudent}
                            reversalMode
                            enabled
                        >
                            <h2 className={styles.cardTitle}><ruby>児童<rt>じどう</rt></ruby>はこちら</h2>
                        </PokemonstartkitTextCardComponent>
                    </div>
                    <div className={styles.selectUserCards}>
                        <PokemonstartkitTextCardComponent
                            onClick={this.handleSelectedUser}
                            reversalMode
                            enabled
                        >
                            <h2 className={styles.cardTitle}><ruby>小学校以外<rt>しょうがっこういがい</rt></ruby>の<ruby>利用希望者<rt>りようきぼうしゃ</rt></ruby>（<ruby>先生<rt>せんせい</rt></ruby>）はこちら</h2>
                        </PokemonstartkitTextCardComponent>
                    </div>
                </div>
            </Modal>
        );
    }
}

SelectUser.propTypes = {
    id: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func,
    onSelectedTeacher: PropTypes.func,
    onSelectedUser: PropTypes.func,
    onSelectedStudent: PropTypes.func
};

export default injectIntl(SelectUser);
