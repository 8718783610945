/* eslint-disable prefer-template */
import {onAlert, onConfirm} from '../reducers/overlay-state';

let isAlready = false;

const alert = async (dispatch, state) => {
    if (isAlready) return;
    isAlready = true;
    document.dispatchEvent(new Event('popupVisible'));
    return new Promise(async mainResolve => {
        const isAccepted = await new Promise(async resolve => {
            dispatch(onAlert(state, resolve));
        });
        isAlready = false;
        return mainResolve(isAccepted);
    });
};

const confirm = async (dispatch, state) => {
    if (isAlready) return;
    isAlready = true;
    document.dispatchEvent(new Event('popupVisible'));
    return new Promise(async mainResolve => {
        const isAccepted = await new Promise(async resolve => {
            dispatch(onConfirm(state, resolve));
        });
        isAlready = false;
        return mainResolve(isAccepted);
    });
};

export {
    alert,
    confirm
};
