const ACTIVATE_AUTOSAVE = 'autosaveState/SET_AUTOSAVE_STATE';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialState = true;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case ACTIVATE_AUTOSAVE:
        return action.title;
    default:
        return state;
    }
};
const setAutosaveState = title => ({
    type: ACTIVATE_AUTOSAVE,
    title: title
});

export {
    reducer as default,
    initialState as autosaveInitialState,
    setAutosaveState
};
