import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import firstUiLibraryContent from '../lib/libraries/first-ui-alternatives.json';
import backdropTags from '../lib/libraries/backdrop-tags';
import FirstUiLibraryComponent from '../components/first-ui-library/first-ui-library.jsx';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Mode',
        description: 'Heading for the backdrop library',
        id: 'gui.firsttimeUi.chooseMode'
    }
});


class FirstTimeUI extends React.Component {

    render () {
        return (
            <FirstUiLibraryComponent
                data={firstUiLibraryContent}
                id="firstUiLibrary"
                tags={backdropTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
                isShowingBackButton={false}
                onStartLoadingProject={this.props.onStartLoadingProject}
                onSaveProject={this.props.onSaveProject}
                onSetAutosave={this.props.onSetAutosave}
                isSelectedLoadUI={this.props.isSelectedLoadUI}
            />
        );
    }
}

FirstTimeUI.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    onStartLoadingProject: PropTypes.func.isRequired,
    onSaveProject: PropTypes.func.isRequired,
    onSetAutosave: PropTypes.func.isRequired,
    isSelectedLoadUI: PropTypes.bool
};

export default injectIntl(FirstTimeUI);
