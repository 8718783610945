import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import stylesLarge from './large-text-card.css';
import styles from './text-card.css';
import stylesR from './text-card-r.css';
import stylesE from './text-card-extra.css';

const PokemonstartkitTextCard = ({
    disabled,
    reversalMode,
    iconClassName,
    iconSrc,
    onClick,
    children,
    ...props
}) => {

    if (disabled) {
        onClick = function () { };
    }

    const style = reversalMode ? stylesR : styles;

    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    return (
        <button
            type="submit"
            className={classNames(style.textCard, disabled ? style.disabled : style.enabled)}
            onClick={onClick}
            {...props}
        >
            <span className={style.pad}>
                {icon}
                {children}
            </span>
        </button>
    );
};

const LargeTextCard = ({
    iconClassName,
    iconSrc,
    onClick,
    children,
    ...props
}) => {

    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    return (
        <button
            type="submit"
            className={classNames(stylesLarge.textCard, stylesLarge.button)}
            onClick={onClick}
            {...props}
        >
            <span className={stylesLarge.pad}>
                {icon}
                {children}
            </span>
        </button>
    );
};

const ExtraTextCard = ({
    iconClassName,
    iconSrc,
    onClick,
    className,
    disabled,
    children,
    ...props
}) => {

    const icon = iconSrc && (
        <img
            className={classNames(styles.icon, iconClassName)}
            draggable={false}
            src={iconSrc}
        />
    );

    const buttonStyle = disabled ? stylesE.buttonDisabled : stylesE.button;
    const textBodyStyle = disabled ? stylesE.textBodyDisabled : stylesE.textBody;

    return (
        <button
            type="submit"
            className={classNames(className,
                buttonStyle)}
            onClick={onClick}
            {...props}
        >
            <div className={textBodyStyle}>
                {icon}
                {children}
            </div>
        </button>
    );
};
ExtraTextCard.propTypes = {
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node
};


PokemonstartkitTextCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    reversalMode: PropTypes.bool
};

LargeTextCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
    reversalMode: PropTypes.bool
};

export {
    PokemonstartkitTextCard as default,
    LargeTextCard,
    ExtraTextCard
};
