/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable valid-jsdoc */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {setProjectID} from '../../reducers/project-id';
import {setAutosaveState} from '../../reducers/autosave-state';
import {setProjectTitle} from '../../reducers/project-title';
import Modal from '../../containers/noheader-modal.jsx';
// import Spinner from '../spinner/spinner.jsx';
import LoadingAnimation from '../loading-animation/loading-animation.jsx';
/* import TextCard, {LargeTextCard} from '../text-card/text-card.jsx';*/
import styles from './freemode-save-load-ui.css';

const getTimeData = () => new Date().toLocaleString();

class SaveLoadUiComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleSelect',

            'handleChangeProjectName',
            'onSelectItem',
            'handleSelectBackButton',
            'onCheckDataname',
            'handleSelectSavebutton',
            'handleSelectLoadbutton',
            'onSetData',
            'onSetAutosave',
            'handleToggleChange',
            'changeProjectName',
            'handleSelectNewgame',
            'handleSelectContinue'
        ]);
        this.changeData = null;
        this.state = {
            loaded: false,
            selectID: this.props.selectedProjectId !== null && this.props.isSaveMode ? this.props.selectedProjectId : null,
            isInvalidProjectName: false,
            isValidAutoSave: this.props.autoSaveState,
            isReadyData: false,
            isExistSaveData: null
        };
        this.selectedId = this.props.selectedProjectId === null ? null : this.props.selectedProjectId;
    }
    componentDidMount () {
        // Allow the spinner to display before loading the content
        if (this.props.isLoaded){
            this.checkReady();
        }
    }

    componentDidUpdate (prevProp, prevState){
        if (!prevProp.isLoaded && this.props.isLoaded) {
            this.checkReady();
        }

        if (!prevState.isReadyData && this.state.isReadyData){
            if (this.props.selectedProjectId !== null) {
                if (this.props.isSaveMode) {
                    this.handleSelect(null, this.props.selectedProjectId);
                    const element = document.getElementById(`saveslot_${this.props.selectedProjectId}`);
                    if (element) {
                        element.className = styles.saveSlotItemActive;
                    }
                }
            }
        }
    }

    checkReady () {
        this.setState({loaded: true});
        this.setState({isReadyData: true});
    }

    handleChangeProjectName (event) {
        this.changeProjectName(event.target.value);
    }

    changeProjectName (name){
        const data = {...this.props.data[this.state.selectID]};
        data.name = name;
        data.time = getTimeData();
        data.wasSavedName = true;
        this.changeData = data;
        this.setState({isInvalidProjectName: data.name === '' || !data.name.match(/\S/g)});
    }

    handleSelect (event, id) {
        if (event) {
            this.onSelectItem(event);
            this.setState({selectID: id});
        }
        const element = document.getElementById('projectNameInput');
        if (element) {
            element.value = this.props.data[id].name;
        }

        this.setState({isExistSaveData: this.props.data[id].wasSavedName});
        const data = {...this.props.data[id]};
        data.time = getTimeData();
        data.wasSavedName = true;
        this.changeData = data;
        this.setState({isInvalidProjectName: data.name === '' || !data.name.match(/\S/g)});
    }

    handleClose () {
        this.props.onRequestClose();
    }

    onSelectItem (event) {
        if (this.state.selectID !== null) {
            const element = document.getElementById(`saveslot_${this.state.selectID}`);
            if (element) {
                element.className = styles.saveSlotItem;
            }
        }
        event.target.className = styles.saveSlotItemActive;
    }

    handleSelectBackButton () {
        if (this.state.loaded) {
            this.props.onSelectBackButton();
        }
    }

    onCheckDataname () {
        if (this.state.selectID === null || this.changeData === null) return false;
        return (this.changeData.name === '');
    }

    handleSelectSavebutton () {
        if (this.state.selectID === null) return;
        if (this.state.isInvalidProjectName) return;
        return new Promise(async (resolve, reject) => {
            if (this.onCheckDataname()) {
                await window.ownAlert(<p>セーブデータに<ruby>名前<rt>なまえ</rt></ruby>をつけてね</p>);
            } else {
                let accept = true;
                if (this.props.data[this.state.selectID].wasSavedName) {
                    if (this.selectedId !== this.state.selectID || (!this.props.autoSaveState && this.props.projectChanged)) {
                        accept = await window.ownConfirm(
                            <p>
                                <ruby>上書<rt>うわが</rt></ruby>き<ruby>保存<rt>ほぞん</rt></ruby>しますか？すでに
                                <ruby>保存<rt>ほぞん</rt></ruby>されているデータは<ruby>消<rt>き</rt></ruby>えてしまいます
                            </p>
                        );
                    }
                }

                if (!accept) return;

                this.onSetAutosave();

                try {
                    const title = this.props.projectTitle;
                    const id = this.props.projectId;

                    await this.props.onSaveProject(this.changeData.id, this.changeData.name);

                    this.onSetData();

                    if (this.changeData.id !== id) {
                        this.props.onSetProjectID(id);
                        if (this.changeData.name !== title) this.props.onSetProjectTitle(title);
                    }

                    this.changeData = null;
                    await window.ownAlert(
                        <p>
                            <ruby>保存<rt>ほぞん</rt></ruby>が<ruby>完了<rt>かんりょう</rt></ruby>しました
                        </p>
                    );
                    
                    this.handleClose();
                    return resolve();
                } catch (error) {
                    return reject(error);
                }
            }
        });

    }

    handleSelectLoadbutton (isNewgame = false) {
        if (!this.state.loaded) return;
        if (this.state.selectID === null) return;
        return new Promise(async (resolve, reject) => {
            if (this.onCheckDataname()) {
                
                window.ownAlert(<p>セーブデータに<ruby>名前<rt>なまえ</rt></ruby>をつけてね</p>);

            } else {

                let accept = true;
                if (isNewgame) {
                    if (this.props.data[this.state.selectID].wasSavedName) {
                        
                        accept = await window.ownConfirm(
                            <p>
                                <ruby>上書<rt>うわが</rt></ruby>きして<ruby>新<rt>あたら</rt></ruby>しくはじめますか？すでに
                                <ruby>保存<rt>ほぞん</rt></ruby>されているデータは<ruby>消<rt>き</rt></ruby>えてしまいます
                            </p>
                        );
                    }

                } else {
                    const element = document.getElementById('toggleInput');
                    if (element.checked) {
                        
                        accept = await window.ownConfirm(
                            <p>
                                オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にすると、
                                <ruby>自動<rt>じどう</rt></ruby>で<ruby>変更<rt>へんこう</rt></ruby>が
                                <ruby>保存<rt>ほぞん</rt></ruby>されます。これまでのデータは
                                <ruby>上書<rt>うわが</rt></ruby>きされますが、この
                                <ruby>設定<rt>せってい</rt></ruby>ではじめますか？
                            </p>
                        );
                    }
                }
                if (!accept) return;

                this.onSetAutosave();
                this.props.onSetSelectedClassMode();

                this.setState({loaded: false});

                try {
                    this.onSetData();

                    const projectId = isNewgame ? 'default' : this.props.data[this.state.selectID].id;

                    await this.props.onLoadProject(projectId,
                        this.props.data[this.state.selectID].name,
                        isNewgame
                    );

                    this.changeData = null;
                    this.handleClose();
                    return resolve();

                } catch (err) {
                    this.setState({loaded: true});
                    await window.ownConfirm(
                        <p>
                            データのロードに<ruby>失敗<rt>しっぱい</rt></ruby>しました。
                            インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご
                            <ruby>確認<rt>かくにん</rt></ruby>ください。
                        </p>
                    );
                    return reject(err);
                }
            }
        });

    }

    handleSelectNewgame () {
        this.handleSelectLoadbutton(true);
    }

    handleSelectContinue () {
        this.handleSelectLoadbutton(false);
    }

    onSetData () {
        if (this.changeData === null) return;
        this.props.onSetProjectID(this.changeData.id);
        this.props.onSetProjectTitle(this.changeData.name);

        // this.changeData = null;
        
        return new Promise(async (resolve, reject) => {
            try {
                await this.props.onSaveMetaData(this.state.selectID, this.changeData.name);
                return resolve();
            } catch (error) {
                return reject(error);
            }
        });
    }

    onSetAutosave () {
        const element = document.getElementById('toggleInput');
        if (element) {
            this.props.onSetAutosave();
            this.props.onSetAutosaveState(element.checked);
        }
    }

    handleToggleChange (e) {
        this.setState({isValidAutoSave: e.target.checked});
    }

    render () {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.isLoaded && this.state.loaded ?
                    this.props.title : <>ロード<ruby>中<rt>ちゅう</rt></ruby></>}
                id={this.props.id}
                onRequestClose={this.handleSelectBackButton}
                header
                isShowingBackButton={this.props.isShowingBackButton && !this.state.isloaded}
                scratchPage
            >
                {
                    this.props.isLoaded && this.state.loaded ?
                        <div className={styles.container}>
                            <div className={styles.libraryScrollGridWrapper}>
                                <div className={styles.libraryScrollGrid}>
                                    {
                                        this.props.data.map((dataItem, index) => (
                                            this.props.isShowNewgame || this.props.isSaveMode || dataItem.wasSavedName ?
                                                <div
                                                    id={`saveslot_${index}`}
                                                    className={this.state.selectID === index ? styles.saveSlotItemActive : styles.saveSlotItem}
                                                    // eslint-disable-next-line react/jsx-no-bind
                                                    onClick={e => this.handleSelect(e, index)}
                                                    key={`saveslot_${index}`}
                                                >
                                                    {
                                                        (this.props.isLoaded && this.state.loaded && dataItem.image) ?
                                                            <img
                                                                src={dataItem.image}
                                                            /> : null
                                                    }
                                                    <h3 className={styles.dataItemName}>{dataItem.name}</h3>
                                                </div> :
                                                <div
                                                    id={`saveslot_${index}`}
                                                    className={styles.saveSlotItemDisabled}
                                                    key={`saveslot_${index}`}
                                                >
                                                    <h3 className={styles.dataItemName}>{dataItem.name}</h3>
                                                </div>
                                        ))
                                    }

                                </div>
                                {
                                    this.state.loaded &&
                                    <div className={styles.loadWrapperAll}>
                                        <div className={styles.loadWrapper}>
                                            <div className={styles.nameTimeWrapper}>
                                                <h4 className={styles.timeText}><ruby>保存<rt>ほぞん</rt></ruby>データ<ruby>名<rt>めい</rt></ruby></h4>
                                                <div className={styles.nameInputWrapper}>
                                                    {
                                                        this.state.selectID === null ?
                                                            <input
                                                                className={styles.projectNameInput}
                                                                id="projectNameInput"
                                                                type="text"
                                                                maxLength={24}
                                                                disabled
                                                                placeholder="セーブデータのなまえ"
                                                            /> :
                                                            <input
                                                                className={styles.projectNameInput}
                                                                id="projectNameInput"
                                                                type="text"
                                                                onChange={this.handleChangeProjectName}
                                                                maxLength={24}
                                                                placeholder="セーブデータのなまえ"
                                                            />
                                                    }


                                                </div>

                                                {
                                                    this.props.data[this.state.selectID] && this.props.data[this.state.selectID].time &&
                                                (this.props.data[this.state.selectID].time === '' ?
                                                    <p className={styles.timeText}><ruby>新<rt>あたら</rt></ruby>しいデータ</p> :
                                                    <p className={styles.timeText}><ruby>最後<rt>さいご</rt></ruby>に<ruby>保存<rt>ほぞん</rt></ruby>した<ruby>日時<rt>にちじ</rt></ruby>：{this.props.data[this.state.selectID].time}</p>

                                                )
                                                }
                                            </div>
                                            <div className={styles.loadButtonBox}>
                                                {
                                                    this.props.isSaveMode ?
                                                        <div
                                                            className={styles.loadButton}
                                                    
                                                        >
                                                            {
                                                                this.state.selectID === null ?
                                                                    <div className={styles.loadButtonDeactive}><h4>データをえらぼう</h4></div> :
                                                                    this.state.isInvalidProjectName ?
                                                                        <div
                                                                            className={styles.loadButtonDeactive}
                                                                        ><h4>データの<ruby>名前<rt>なまえ</rt></ruby>をつけよう</h4></div> :
                                                                        <div
                                                                            className={styles.loadButtonActive}
                                                                            onClick={this.handleSelectSavebutton}
                                                                        ><h4>ほぞんする</h4></div>
                                                            }</div> :
                                                        <>
                                                            <div
                                                                className={styles.loadButton}
                                                            >
                                                                {
                                                                    this.state.selectID === null ?
                                                                        <div className={styles.loadButtonDeactive}><h4>データをえらぼう</h4></div> :
                                                                        this.state.isInvalidProjectName ?
                                                                            <div className={styles.loadButtonDeactive}><h4>データの<ruby>名前<rt>なまえ</rt></ruby>をつけよう</h4></div> :
                                                                            <>
                                                                                {
                                                                                    this.state.isExistSaveData ?
                                                                                        <div
                                                                                            className={styles.loadButtonActive}
                                                                                            onClick={this.handleSelectContinue}
                                                                                        >
                                                                                            <h4>つづきからはじめる</h4>
                                                                                        </div> : <div
                                                                                            className={classNames(styles.loadButtonActive, styles.newgameButton)}
                                                                                            onClick={this.handleSelectNewgame}
                                                                                        >
                                                                                            <h4>あたらしくはじめる</h4>
                                                                                        </div>
                                                                                }

                                                                            </>
                                                                }
                                                            </div>
                                                            {
                                                                !this.state.isInvalidProjectName && this.state.isExistSaveData && this.props.isShowNewgame && this.state.selectID !== null &&
                                                                <div
                                                                    className={classNames(styles.loadButton, styles.newgameButtonActive)}
                                                                    onClick={this.handleSelectNewgame}
                                                                >
                                                                    <h4>あたらしくはじめる</h4>
                                                                </div>
                                                            }
                                                        </>
                                                }
                                                {
                                                    this.props.data[this.state.selectID] && !this.props.isSaveMode &&
                                                    <div className={styles.toggleBox}>
                                                        <input
                                                            className={styles.toggleInput}
                                                            id="toggleInput"
                                                            type="checkbox"
                                                            defaultChecked={this.props.autoSaveState}
                                                            onChange={this.handleToggleChange}
                                                        />
                                                        <label htmlFor="toggleInput">
                                                            {this.state.isValidAutoSave ?
                                                                <h4 className={styles.validAutoSave}>オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にする<br />
                                                                    （<ruby>有効<rt>ゆうこう</rt></ruby>にすると、20<ruby>秒<rt>びょう</rt></ruby>に1<ruby>回<rt>かい</rt></ruby><ruby>自動<rt>じどう</rt></ruby>で<ruby>保存<rt>ほぞん</rt></ruby>されます）</h4> :
                                                                <h4 className={styles.invalidAutoSave}>オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にする<br />
                                                                    （<ruby>有効<rt>ゆうこう</rt></ruby>にすると、20<ruby>秒<rt>びょう</rt></ruby>に1<ruby>回<rt>かい</rt></ruby><ruby>自動<rt>じどう</rt></ruby>で<ruby>保存<rt>ほぞん</rt></ruby>されます）</h4>
                                                            }
                                                        </label>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        
                                    </div>
                                }
                                {
                                    this.state.selectID !== null && (this.state.isValidAutoSave || this.props.isSaveMode) &&
                                    <div className={styles.captionWrapper}>
                                        <p><ruby>複数<rt>ふくすう</rt></ruby>のタブで<ruby>操作<rt>そうさ</rt></ruby>するとデータが<ruby>上書<rt>うわが</rt></ruby>きされる<ruby>可能性<rt>かのうせい</rt></ruby>があります。ご<ruby>注意<rt>ちゅうい</rt></ruby>ください。</p>
                                    </div>
                                }
                            </div>
                            
                
                        </div> :
                        (
                            <div className={styles.animWrapper}>
                                <LoadingAnimation />
                            </div>

                        )
                }
            </Modal>
        );
    }
}

SaveLoadUiComponent.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            time: PropTypes.string,
            wasSavedName: PropTypes.bool
        })
    ),
    id: PropTypes.string.isRequired,
    onSaveMetaData: PropTypes.func,
    onRequestClose: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    title: PropTypes.object.isRequired,
    isShowingBackButton: PropTypes.bool,

    onLoadProject: PropTypes.func.isRequired,
    onSaveProject: PropTypes.func.isRequired,
    onSelectBackButton: PropTypes.func,
    isLoaded: PropTypes.bool,

    onSetAutosave: PropTypes.func.isRequired,
    onSetAutosaveState: PropTypes.func,

    isSaveMode: PropTypes.bool,

    onSetProjectTitle: PropTypes.func,
    onSetProjectID: PropTypes.func,
    onSetSelectedClassMode: PropTypes.func,
    selectedProjectId: PropTypes.number,

    autoSaveState: PropTypes.bool,
    projectTitle: PropTypes.string,
    projectId: PropTypes.string,
    projectChanged: PropTypes.bool,

    isShowNewgame: PropTypes.bool
};

SaveLoadUiComponent.defaultProps = {
    onSetSelectedClassMode: () => { },
    selectedProjectId: null
};

const mapStateToProps = state => ({
    projectChanged: state.scratchGui.projectChanged,
    autoSaveState: state.scratchGui.autosaveState,
    projectTitle: state.scratchGui.projectTitle,
    projectId: state.scratchGui.projectId

});

const mapDispatchToProps = dispatch => (
    {
        onSetProjectID: name => dispatch(setProjectID(name)),
        onSetProjectTitle: name => dispatch(setProjectTitle(name)),
        onSetAutosaveState: state => dispatch(setAutosaveState(state))
    }
);


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaveLoadUiComponent));
