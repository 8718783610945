import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import decksLibraryContent from '../lib/libraries/decks/index-pokemon.jsx';
import {freeModeTipsTag, scratchPokemonGameTipsTag, scratchPokemonCharizardTag, scratchPokemonBookTag} from '../lib/libraries/pokemon-tutorial-tags';

import analytics from '../lib/analytics';
import {notScratchDesktop} from '../lib/isScratchDesktop';

import LibraryComponent from '../components/library/library.jsx';

import {connect} from 'react-redux';

import {
    closePokemonTipsLibrary
} from '../reducers/modals';

import {
    activateDeck
} from '../reducers/cards';

import {
    ClassModeActionTypes
} from '../reducers/selected-class-mode';

const pokemonTutorialTags = [
    {
        id: ClassModeActionTypes.FREE,
        tipsTag: freeModeTipsTag
    },
    {
        id: ClassModeActionTypes.CLASS_MAIN,
        tipsTag: scratchPokemonGameTipsTag
    },
    {
        id: ClassModeActionTypes.CHARIZARD,
        tipsTag: scratchPokemonCharizardTag
    },
    {
        id: ClassModeActionTypes.BOOK,
        tipsTag: scratchPokemonBookTag
    }
];
const messages = defineMessages({
    pokemonTipsLibraryTitle: {
        defaultMessage: 'Choose a Tutorial',
        description: 'Heading for the help/tutorials library',
        id: 'gui.pokemonTipsLibrary.tutorials'
    }
});

class PokemonTipsLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect (item) {
        analytics.event({
            category: 'library',
            action: 'Select How-to',
            label: item.id
        });

        if (item.requiredProjectId && (item.requiredProjectId !== this.props.projectId)) {
            const urlParams = `/projects/${item.requiredProjectId}/editor?tutorial=${item.urlId}`;
            return window.open(window.location.origin + urlParams, '_blank');
        }

        this.props.onActivateDeck(item.id);
    }
    render () {
        const decksLibraryThumbnailData = Object.keys(decksLibraryContent)
            .filter(id => {
                if (notScratchDesktop()) return true; // Do not filter anything in online editor
                const deck = decksLibraryContent[id];
                // Scratch Desktop doesn't want tutorials with `requiredProjectId`
                if (deck.hasOwnProperty('requiredProjectId')) return false;
                // Scratch Desktop should not load tutorials that are _only_ videos
                if (deck.steps.filter(s => s.title).length === 0) return false;
                // Allow any other tutorials
                return true;
            })
            .filter(id => decksLibraryContent[id].modes.some(mode => mode === this.props.selectedClassMode))
            .map(id => ({
                rawURL: decksLibraryContent[id].img,
                id: id,
                name: decksLibraryContent[id].name,
                featured: true,
                tags: decksLibraryContent[id].tags,
                urlId: decksLibraryContent[id].urlId,
                requiredProjectId: decksLibraryContent[id].requiredProjectId,
                hidden: decksLibraryContent[id].hidden || false
            }));

        if (!this.props.visible) return null;
        
        const PokemonTutorialTags = pokemonTutorialTags.find(tag => tag.id === this.props.selectedClassMode).tipsTag;

        return (
            <LibraryComponent
                filterable
                data={decksLibraryThumbnailData}
                id="tipsLibrary"
                tags={PokemonTutorialTags}
                title={this.props.intl.formatMessage(messages.pokemonTipsLibraryTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
                isShowingBackButton
                initSelectedTag={PokemonTutorialTags[this.props.initSelectedTagId]}
            />
        );
    }
}

PokemonTipsLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateDeck: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    initSelectedTagId: PropTypes.number,
    selectedClassMode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.pokemonTipsLibrary,
    projectId: state.scratchGui.projectState.projectId,
    selectedClassMode: state.scratchGui.selectedClassMode
});

const mapDispatchToProps = dispatch => ({
    onActivateDeck: id => dispatch(activateDeck(id)),
    onRequestClose: () => dispatch(closePokemonTipsLibrary())
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PokemonTipsLibrary));
