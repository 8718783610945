import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import log from '../lib/log';
import sharedMessages from './shared-messages';
import {projectTitleInitialState, setProjectTitle} from '../reducers/project-title';
// import {setProjectID} from '../reducers/project-id';
import Minizip from 'minizip-asm.js';
import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';
import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';

import {projectHost} from '../lib/project-fetcher-hoc.jsx';
const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});
import {connectDB} from './indexedDB.jsx';

require('dotenv').config();

const staticPath = '../../static';

/**
 * Higher Order Component to provide behavior for loading local project files into editor.
 * @param {React.Component} WrappedComponent the component to add project file loading functionality to
 * @returns {React.Component} WrappedComponent with project file loading functionality added
 *
 * <IndexedDBLoaderHOC>
 *     <WrappedComponent />
 * </IndexedDBLoaderHOC>
 */
const IndexedDBLoaderHOC = function (WrappedComponent) {
    const env = process.env;
    class IndexedDBloaderComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'onload',

                'getZipData',
                'handleLoadProjectFromFilename',
                'onUnzip',

                'onUnzipProjectData',
                'createFileObjects',
                'handleFinishedLoadingUpload',
                'handleStartSelectingFileUpload',
                'handleChange',
                'handleLoadCompe'
            ]);

            //this.state = {
            //    isLoadIndexedDB: false
            //};
            
            this.projectName = 'defaultProject';
            this.isLoadIndexedDB = false;
            this.closeFunc = null;
        }

        componentDidUpdate(prevProps) {
            // console.log(this.props.isLoadingUpload, prevProps.isLoadingUpload, this.state.isLoadIndexedDB, this.props.isLoadingUpload && !prevProps.isLoadingUpload && !this.state.isLoadIndexedDB,this.props.loadingState);
            if (this.props.isLoadingUpload && !prevProps.isLoadingUpload && !this.isLoadIndexedDB) {
                this.handleFinishedLoadingUpload(); // cue step 5 below
            }
        }


        handleStartSelectingFileUpload (closeFunc = null) {
            this.createFileObjects(); // go to step 2
            this.closeFunc = closeFunc;
        }

        createFileObjects () {
            // redo step 7, in case it got skipped last time and its objects are
            // still in memory
            this.removeFileObjects();
            // create fileReader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onUnzip;
            // create <input> element and add it to DOM
            this.inputElement = document.createElement('input');
            this.inputElement.accept = '.ppsk';
            this.inputElement.style = 'display: none;';
            this.inputElement.type = 'file';
            this.inputElement.onchange = this.handleChange; // connects to step 3
            document.body.appendChild(this.inputElement);
            // simulate a click to open file chooser dialog
            this.inputElement.click();
        }
        // step 3: user has picked a file using the file chooser dialog.
        // We don't actually load the file here, we only decide whether to do so.
        handleChange = async e => {
            const {
                intl,
                isShowingWithoutId,
                loadingState,
                projectChanged
            } = this.props;
            const thisFileInput = e.target;
            if (thisFileInput.files) { // Don't attempt to load if no file was selected
                this.fileToUpload = thisFileInput.files[0];

                // If user owns the project, or user has changed the project,
                // we must confirm with the user that they really intend to
                // replace it. (If they don't own the project and haven't
                // changed it, no need to confirm.)
                let uploadAllowed = true;
                if ((projectChanged && isShowingWithoutId)) {
                    uploadAllowed = await window.ownConfirm( // eslint-disable-line no-alert
                        intl.formatMessage(sharedMessages.replaceProjectWarning)
                    );
                    
                }
                // console.log(this.fileToUpload);
                if (uploadAllowed) {
                    // cues step 4
                    this.props.requestProjectUpload(loadingState);

                } else {
                    // skips ahead to step 7
                    this.removeFileObjects();
                }
            }
        }
        // step 4 is below, in mapDispatchToProps

        // step 5: called from componentDidUpdate when project state shows
        // that project data has finished "uploading" into the browser
        handleFinishedLoadingUpload () {
            if (this.fileToUpload && this.fileReader) {
                // begin to read data from the file. When finished,
                // cues step 6 using the reader's onload callback
                this.fileReader.readAsArrayBuffer(this.fileToUpload);
            } else {
                this.props.cancelFileUpload(this.props.loadingState);
                // skip ahead to step 7
                this.removeFileObjects();
            }
        }

        removeFileObjects () {
            if (this.inputElement) {
                this.inputElement.value = null;
                document.body.removeChild(this.inputElement);
            }
            this.inputElement = null;
            this.fileReader = null;
            this.fileToUpload = null;
        }


        handleLoadProjectFromFilename (loadID = null, projectName = 'defaultProject', isNewgame = false) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.closeFunc = null;
                    this.isLoadIndexedDB = true;
                    // this.setState({ isLoadIndexedDB: true });
                    this.props.onLoadingStarted(); // loadUI開く
                    this.props.requestProjectUpload(this.props.loadingState);

                    const projectfileID = loadID ? loadID : this.props.projectFilename;

                    // this.projectName = projectName;

                    const db = await connectDB().catch(error => {
                        throw new Error(error);
                    });

                    const objstore = db.transaction(['projectData'], 'readonly').objectStore('projectData');

                    const request = objstore.get(projectfileID);
                    request.onerror = error => {
                        throw new Error(error);
                    };

                    const data = await new Promise(resolve => {
                        request.onsuccess = event => {
                            resolve(event.target.result);
                        };
                    });

                    const zipData = await this.getZipData(data, projectfileID, isNewgame === true);
                    const loadData = await this.onUnzipProjectData(zipData);
                    await this.onload(loadData);
                    return resolve();
                } catch (error) {
                    this.props.cancelFileUpload(this.props.loadingState);
                    this.props.onCloseLoading();
                    console.log(error);
                    reject(error);
                }

            });
        }

        // {projectData[id:string, data:blob]} is projectData from indexedDB [id:string, data:blob].
        // {projectfileID} is projectId to load.
        // {isNewgame} is a flag of newgame.
        getZipData (projectData, projectfileID, isNewgame){
            return new Promise(async (resolve, reject) => {
                try {
                    const fileReader = new FileReader();
                    let blob;
                    if (typeof projectData === 'undefined' || isNewgame) {

                        const id = typeof projectData === 'undefined' ? (projectfileID.includes('freemode') ? 'default' : projectfileID) : projectData.id;

                        console.log('Get SaveData : ', `${projectHost}/${id}`);
                        const response = await fetch(`${projectHost}/${id}`);
                        blob = await response.blob();
                    } else {
                        blob = projectData.Data;
                        console.log('Get SaveData : ', projectData);
                    }
                    fileReader.readAsArrayBuffer(blob);
                    await new Promise(resolve => fileReader.onload = () => resolve());
                    return resolve(fileReader.result);

                } catch (error) {
                    //await window.ownAlert(
                    //    <p>
                    //        <ruby>通信<rt>つうしん</rt></ruby>エラーが<ruby>発生<rt>はっせい</rt></ruby>しました。
                    //        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    //    </p>
                    //);
                    reject('There is no data to load');
                }

            });
        }

        handleLoadCompe = blob => new Promise(async (resolve, reject) => {
            try {
                // this.setState({isLoadIndexedDB: true});
                this.isLoadIndexedDB = true;
                this.props.onLoadingStarted();
                this.props.requestProjectUpload(this.props.loadingState);

                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(blob);
                await new Promise(resolve => fileReader.onload = () => resolve());

                const loadData = await this.onUnzipProjectData(fileReader.result);
                await this.onload(loadData);
                return resolve();
            } catch (error) {
                this.props.cancelFileUpload(this.props.loadingState);
                this.props.onCloseLoading();
                return reject(error);
            }
        });

        onUnzipProjectData = buffer => new Promise((resolve, reject) => {
            try {
                const mzip = new Minizip(Buffer.from(buffer));
                if (mzip.list().length > 0) {
                    const projectPath = mzip.list()[0].filepath;
                    const projectData = mzip.extract(projectPath, {password: env.ZIP_UNLOCK_KEY});
                    return resolve(projectData.buffer);
                }
                throw new Error('empty folder');
            } catch (error) {
                reject('empty folder');
            }

        });


        onUnzip (event) {
            const data = event.target.result;
            const mzip = new Minizip(Buffer.from(data));
            if (mzip.list().length > 0) {
                const projectPath = mzip.list()[0].filepath;
                const projectData = mzip.extract(projectPath, {password: env.ZIP_UNLOCK_KEY});
                this.onload(projectData.buffer);

            } else {
                throw new Error('invalid project data');
            }
        }

        // step 6: attached as a handler on our FileReader object; called when
        // file upload raw data is available in the reader
        onload = data => new Promise(async (resolve, reject) => {
            let loadingSuccess = false;
            try {
                if (data) {
                    await this.props.vm.loadProject(data)
                        .catch(async error => {
                            log.warn(error);
                            // await window.ownAlert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                            document.dispatchEvent(new Event('errorLoadProject'));
                            // console.log('end-load/reject');
                            throw new Error(error);
                        });

                    // if (this.projectName) this.props.onSetProjectTitle(this.projectName);
                    loadingSuccess = true;
                    document.dispatchEvent(new Event('loadProject'));

                }
                // console.log('end-load');
                if (this.closeFunc !== null) {
                    this.closeFunc();
                }
                return resolve();
            } catch (error) {
                reject(error);
            } finally {
                this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                this.isLoadIndexedDB = false;
            }

        });


        render () {
            const {
                /* eslint-disable no-unused-vars */
                cancelFileUpload,
                // closeFileMenu: closeFileMenuProp,
                isLoadingUpload,
                isShowingWithoutId,
                loadingState,
                onLoadingFinished,
                onLoadingStarted,
                onSetProjectTitle,
                projectChanged,
                requestProjectUpload,
                onCloseLoading,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        onStartLoadingProject={this.handleLoadProjectFromFilename}
                        onUpLoadProject={this.handleStartSelectingFileUpload}
                        onLoadCompe={this.handleLoadCompe}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    const getProjectFilename = (curTitle, defaultTitle) => {
        let filenameTitle = curTitle;
        if (!filenameTitle || filenameTitle.length === 0) {
            filenameTitle = defaultTitle;
        }
        return `${filenameTitle.substring(0, 100)}`;
    };


    IndexedDBloaderComponent.propTypes = {
        canSave: PropTypes.bool,
        cancelFileUpload: PropTypes.func,
        // closeFileMenu: PropTypes.func,
        intl: intlShape.isRequired,
        isLoadingUpload: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onLoadingFinished: PropTypes.func,
        onLoadingStarted: PropTypes.func,
        onSetProjectTitle: PropTypes.func,
        projectChanged: PropTypes.bool,
        requestProjectUpload: PropTypes.func,
        projectFilename: PropTypes.string,
        vm: PropTypes.shape({
            loadProject: PropTypes.func
        }),
        onCloseLoading: PropTypes.func
    };
    const mapStateToProps = state => {
        const loadingState = state.scratchGui.projectState.loadingState;
        return {
            isLoadingUpload: getIsLoadingUpload(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            loadingState: loadingState,
            projectChanged: state.scratchGui.projectChanged,
            vm: state.scratchGui.vm,
            projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
            projectId: state.scratchGui.projectId
        };
    };
    const mapDispatchToProps = dispatch => ({
        cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
        onCloseLoading: () => dispatch(closeLoadingProject()),
        // transition project state from loading to regular, and close
        // loading screen and file menu
        onLoadingFinished: loadingState => { //
            dispatch(onLoadedProject(loadingState, false, true));
            dispatch(closeLoadingProject());
            // dispatch(closeFileMenu());
        },
        // show project loading screen
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onSetProjectTitle: title => dispatch(setProjectTitle(title)),
        requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))

        // onSetProjectSaveName: name => dispatch(setProjectID(name)),
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(IndexedDBloaderComponent));
};

export {
    IndexedDBLoaderHOC as default
};
