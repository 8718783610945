/* eslint-disable no-alert */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {setProjectID} from '../reducers/project-id';
import {setMetaSavingState} from '../reducers/project-saving';
import {projectTitleInitialState} from '../reducers/project-title';
import {connectDB} from '../lib/indexedDB.jsx';
import libraryContents from '../lib/libraries/freemode-save-load-ui.json';
import SaveLoadUIComponent from '../components/freemode-ui/saveload-ui-component.jsx';

// import styles from '../components/first-ui-library/freemode-ui/freemode-ui.css';
// import Modal from './noheader-modal.jsx';
// import TextCard from '../components/text-card/text-card.jsx';

// import downloadBlob from '../lib/download-blob';
// import Minizip from 'minizip-asm.js';

const messages = {
    // eslint-disable-next-line max-len
    saveLibraryTitle: <p>データを<ruby>保存<rt>ほぞん</rt></ruby>する<ruby>場所<rt>ばしょ</rt></ruby>を<ruby>選<rt>えら</rt></ruby>んでください</p>,
    loadLibraryTitle: <p>プログラミングをはじめるデータを<ruby>選<rt>えら</rt></ruby>んでください</p>
};

const getMessage = isSavemode => {
    if (isSavemode) return messages.saveLibraryTitle;
    return messages.loadLibraryTitle;

};

const getTimeData = () => {
    const now = new Date();
    return `${now.getFullYear()}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getDate().toString()
        .padStart(2, '0')} ${now.getHours().toString()
        .padStart(2, '0')}:${now.getMinutes().toString()
        .padStart(2, '0')}:${now.getSeconds().toString()
        .padStart(2, '0')}`;
};

const setmetaTimeoutMSecs = 3000;


class SaveLoadUI extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onSuccessedRead',
            'handleSaveMetaData',

            'handleSelectBackButton',
            'handleClose',
            'onStartLoadingProject',
            'onSaveProject',
            'onSetMetaState'
        ]);

        this.state = {
            isLoaded: false
        };

    }

    componentDidMount () {
        this.checkData();
    }

    componentWillUnmount () {
    }

    handleSaveMetaData (id, name) {
        return new Promise(async (resolve, reject) => {
            let db;
            try {
                if (id === null) {
                    reject(new Error('id is null'));
                }

                if (libraryContents[id].name === name) {
                    return resolve();
                }

                db = await connectDB();
                const objStore = db.transaction(['playerData'], 'readwrite').objectStore('playerData');

                const request = objStore.get(`freemode_${id + 1}`);

                request.onsuccess = () => {
                    console.log(`freemode_${id + 1}`, name, getTimeData());

                    let data = request.result;

                    if (!data) {
                        data = {
                            id: `freemode_${id + 1}`
                        };

                    }
                    data.name = name;
                    data.time = getTimeData();

                    this.props.onSetMetaSavingState(true);
                    const putRequest = objStore.put(data);

                    putRequest.onsuccess = () => {
                        this.onSetMetaState();
                        db.close();
                        return resolve();
                    };

                    putRequest.onerror = error => {
                        console.log('error/saveloadui/put-request : ', error);
                        db.close();
                        return reject(error);
                    };

                };
                request.onerror = err => {
                    alert('読み込みエラー');
                    this.props.onSetMetaSavingState(null);
                    if (db) db.close();
                    return reject(err);
                };
            } catch (err) {
                if (db) db.close();
                return reject(err);
            }

        });
    }

    checkData () {
        return new Promise(async (resolve, reject) => {
            try {
                const db = await connectDB();
                const objstore = db.transaction(['playerData'], 'readonly').objectStore('playerData');
                const timeoutId = setTimeout(() => {
                    db.close();
                    throw new Error('timeout error');
                }, 10000);

                const keyRequest = objstore.getAllKeys();

                const keys = await new Promise(resol => keyRequest.onsuccess = event => resol(event.target.result));

                const validKeys = keys.filter(key => key.includes('freemode')).map(k => Number(k.split('_')[1]) - 1);
                // console.log(keys,validKeys)
                const metaDataReadPromises = [];
                validKeys.forEach(i => {
                    const keyPromise = new Promise(res => {
                        const request = objstore.get(libraryContents[i].id);
                        request.onsuccess = e => {
                            this.onSuccessedRead(e, i);
                            return res();
                        };
                        request.onerror = err => {
                            console.log('error : indexedDB');
                            clearTimeout(timeoutId);
                            db.close();
                            return reject(err);
                        };
                    });
                    metaDataReadPromises.push(keyPromise);
                });
                await Promise.all(metaDataReadPromises);

                const objstoreProj = db.transaction(['projectData'], 'readonly').objectStore('projectData');
                const projKeyRequest = objstoreProj.getAllKeys();
                const projKeys = await new Promise(resol => projKeyRequest.onsuccess = event => resol(event.target.result));
                const validProjKeys = projKeys.filter(key => key.includes('freemode')).map(k => Number(k.split('_')[1]) - 1);


                validProjKeys.forEach(i => {
                    libraryContents[i].wasSavedName = true;
                });

                this.setState({
                    isLoaded: true
                });
                clearTimeout(timeoutId);
                db.close();
                return resolve();

            } catch (err){
                db.close();
                return reject(err);
            }

        });

    }


    onSuccessedRead (event, index) {
        const result = event.target.result;
        if (result) {
            libraryContents[index].name = result.name;
            libraryContents[index].time = result.time;
            libraryContents[index].image = result.image;
            // libraryContents[index].wasSavedName = true;
        }
    }

    onSetMetaState () {
        return new Promise(async (resolve, reject) => {
            let timeoutId;
            try {
                this.props.onSetMetaSavingState(false);
                timeoutId = setTimeout(() => {
                    this.props.onSetMetaSavingState(null);
                    resolve();
                }, setmetaTimeoutMSecs);
            } catch (err) {
                this.props.onSetMetaSavingState(null);
                clearTimeout(timeoutId);
                reject(err);
            }
        });
    }

    handleSelectBackButton () {
        this.props.onSelectBackButton();
    }

    handleClose () {
        this.props.onRequestClose();
    }

    onStartLoadingProject (...args) {
        this.props.onStartLoadingProject(...args);
    }

    onSaveProject (...args) {
        this.props.onSaveProject(...args);
    }

    render () {
        return (
            <SaveLoadUIComponent
                data={libraryContents}
                id="backdropSaveLibrary"
                title={getMessage(this.props.isSaveMode)}
                onSaveMetaData={this.handleSaveMetaData}
                onRequestClose={this.handleClose}
                isShowingBackButton
                onLoadProject={this.props.onStartLoadingProject}
                onSaveProject={this.props.onSaveProject}
                onSelectBackButton={this.handleSelectBackButton}
                isLoaded={this.state.isLoaded}
                isSaveMode={this.props.isSaveMode}
                onSetAutosave={this.props.onSetAutosave}
                onSetSelectedClassMode={this.props.onSetSelectedClassMode}
                selectedProjectId={this.props.projectId ? Number(this.props.projectId.split('_')[1]) - 1 : null}
                isShowNewgame={this.props.fromFirstUI}
            />
        );
    }
}
const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}`;
};

SaveLoadUI.propTypes = {
    onRequestClose: PropTypes.func,
    onStartLoadingProject: PropTypes.func.isRequired,
    onSaveProject: PropTypes.func,
    onSelectBackButton: PropTypes.func,
    isSaveMode: PropTypes.bool,
    fromFirstUI: PropTypes.bool,
    onSetAutosave: PropTypes.func.isRequired,
    onSetSelectedClassMode: PropTypes.func,
    projectId: PropTypes.string,

    onSetMetaSavingState: PropTypes.func
};

SaveLoadUI.defaultProps = {
    fromFirstUI: false
};

const mapStateToProps = state => ({
    projectFileName: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
    projectId: state.scratchGui.projectId
});
const mapDispatchToProps = dispatch => ({
    onSetProjectSaveName: name => dispatch(setProjectID(name)),
    onSetMetaSavingState: state => dispatch(setMetaSavingState(state))

});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaveLoadUI));
