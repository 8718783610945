/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {FormattedMessage} from 'react-intl';

export default
[
    {
        "name": "いどう１",
        "tags": [
            "se"
        ],
        "assetId": "6f2ad14b34805e79bc019d8e37ca6f82",
        "dataFormat": "",
        "md5ext": "6f2ad14b34805e79bc019d8e37ca6f82.mp3",
        "sampleCount": 117601,
        "rate": 44100
    },
    {
        "name": "いどう２",
        "tags": [
            "se"
        ],
        "assetId": "d8450c91b36f279ace6f03842da9b7c5",
        "dataFormat": "",
        "md5ext": "d8450c91b36f279ace6f03842da9b7c5.mp3",
        "sampleCount": 104448,
        "rate": 44100
    },
    {
        "name": "うごく",
        "tags": [
            "se"
        ],
        "assetId": "2b0adf6e1c495837fe57d1c8b4a69350",
        "dataFormat": "",
        "md5ext": "2b0adf6e1c495837fe57d1c8b4a69350.mp3",
        "sampleCount": 81416,
        "rate": 44100
    },
    {
        "name": "おちる",
        "tags": [
            "se"
        ],
        "assetId": "789e4d1fcb30a6572f3e86b9d025ca0c",
        "dataFormat": "",
        "md5ext": "789e4d1fcb30a6572f3e86b9d025ca0c.mp3",
        "sampleCount": 61056,
        "rate": 44100
    },
    {
        "name": "クリア",
        "tags": [
            "se"
        ],
        "assetId": "f6708b49312e6acd94e2d7f5a0c318be",
        "dataFormat": "",
        "md5ext": "f6708b49312e6acd94e2d7f5a0c318be.mp3",
        "sampleCount": 117601,
        "rate": 44100
    },
    {
        "name": "クリック（ながい）",
        "tags": [
            "se"
        ],
        "assetId": "f8b4e2631dca57a0c96f1d8b2705e3fa",
        "dataFormat": "",
        "md5ext": "f8b4e2631dca57a0c96f1d8b2705e3fa.mp3",
        "sampleCount": 88200,
        "rate": 44100
    },
    {
        "name": "クリック（みじかい）",
        "tags": [
            "se"
        ],
        "assetId": "b025f319764c8da7ed6c013fb4a592c8",
        "dataFormat": "",
        "md5ext": "b025f319764c8da7ed6c013fb4a592c8.mp3",
        "sampleCount": 44100,
        "rate": 44100
    },
    {
        "name": "しっぱい（ながい）",
        "tags": [
            "se"
        ],
        "assetId": "8c01e4b7f5a3d962309f6c7ba2d18453",
        "dataFormat": "",
        "md5ext": "8c01e4b7f5a3d962309f6c7ba2d18453.mp3",
        "sampleCount": 264600,
        "rate": 44100
    },
    {
        "name": "しっぱい（みじかい）",
        "tags": [
            "se"
        ],
        "assetId": "93b562adf0e41786ce249f51d78c2a30",
        "dataFormat": "",
        "md5ext": "93b562adf0e41786ce249f51d78c2a30.mp3",
        "sampleCount": 69376,
        "rate": 44100
    },
    {
        "name": "しゅつげん（ながい）",
        "tags": [
            "se"
        ],
        "assetId": "3207b1e5d8cf924fa6d70c9b485e1f83",
        "dataFormat": "",
        "md5ext": "3207b1e5d8cf924fa6d70c9b485e1f83.mp3",
        "sampleCount": 158761,
        "rate": 44100
    },
    {
        "name": "しゅつげん（みじかい）",
        "tags": [
            "se"
        ],
        "assetId": "b641e572f93d78ac205a6cd9b0f8e4d3",
        "dataFormat": "",
        "md5ext": "b641e572f93d78ac205a6cd9b0f8e4d3.mp3",
        "sampleCount": 44130,
        "rate": 44100
    },
    {
        "name": "すいとる",
        "tags": [
            "se"
        ],
        "assetId": "57b6318a4fe92d0c163d9a84c87b05f2",
        "dataFormat": "",
        "md5ext": "57b6318a4fe92d0c163d9a84c87b05f2.mp3",
        "sampleCount": 155136,
        "rate": 44100
    },
    {
        "name": "スタート",
        "tags": [
            "se"
        ],
        "assetId": "9db047a81f5263c0e7a6f58d2419c3e5",
        "dataFormat": "",
        "md5ext": "9db047a81f5263c0e7a6f58d2419c3e5.mp3",
        "sampleCount": 132300,
        "rate": 44100
    },
    {
        "name": "すばやいうごき",
        "tags": [
            "se"
        ],
        "assetId": "a5c70edf2b4931686e74df01a58230cb",
        "dataFormat": "",
        "md5ext": "a5c70edf2b4931686e74df01a58230cb.mp3",
        "sampleCount": 120064,
        "rate": 44100
    },
    {
        "name": "せいこう",
        "tags": [
            "se"
        ],
        "assetId": "e26071a3c9fb5d84d53e1a0c86ab9f47",
        "dataFormat": "",
        "md5ext": "e26071a3c9fb5d84d53e1a0c86ab9f47.mp3",
        "sampleCount": 151201,
        "rate": 44100
    },
    {
        "name": "たたく１",
        "tags": [
            "se"
        ],
        "assetId": "1b3e5c8da7f69240cda83529b0fa7e51",
        "dataFormat": "",
        "md5ext": "1b3e5c8da7f69240cda83529b0fa7e51.mp3",
        "sampleCount": 50816,
        "rate": 44100
    },
    {
        "name": "たたく２",
        "tags": [
            "se"
        ],
        "assetId": "c56aebf3d9087b12d4f156e9073bc829",
        "dataFormat": "",
        "md5ext": "c56aebf3d9087b12d4f156e9073bc829.mp3",
        "sampleCount": 68155,
        "rate": 44100
    },
    {
        "name": "ダメージ",
        "tags": [
            "se"
        ],
        "assetId": "9c4e7d351a80b6f2d0e3a68cbfad25c9",
        "dataFormat": "",
        "md5ext": "9c4e7d351a80b6f2d0e3a68cbfad25c9.mp3",
        "sampleCount": 57728,
        "rate": 44100
    },
    {
        "name": "でんわ",
        "tags": [
            "se"
        ],
        "assetId": "f749bcd03e5a61c289ef8b5d2a10647c",
        "dataFormat": "",
        "md5ext": "f749bcd03e5a61c289ef8b5d2a10647c.mp3",
        "sampleCount": 38656,
        "rate": 44100
    },
    {
        "name": "とじる",
        "tags": [
            "se"
        ],
        "assetId": "e4b316df9720c8578fa526bc0d7394a1",
        "dataFormat": "",
        "md5ext": "e4b316df9720c8578fa526bc0d7394a1.mp3",
        "sampleCount": 52921,
        "rate": 44100
    },
    {
        "name": "にぶい",
        "tags": [
            "se"
        ],
        "assetId": "852ed3c9b7a0f4ed1f6c058a932d7b4e",
        "dataFormat": "",
        "md5ext": "852ed3c9b7a0f4ed1f6c058a932d7b4e.mp3",
        "sampleCount": 151201,
        "rate": 44100
    },
    {
        "name": "はかい",
        "tags": [
            "se"
        ],
        "assetId": "70f4e5b8c136d97291bf4136e82d7a45",
        "dataFormat": "",
        "md5ext": "70f4e5b8c136d97291bf4136e82d7a45.mp3",
        "sampleCount": 124518,
        "rate": 44100
    },
    {
        "name": "ひらく",
        "tags": [
            "se"
        ],
        "assetId": "c90edb3a281f647a5e682dabf50634d7",
        "dataFormat": "",
        "md5ext": "c90edb3a281f647a5e682dabf50634d7.mp3",
        "sampleCount": 58801,
        "rate": 44100
    },
    {
        "name": "ロボット",
        "tags": [
            "se"
        ],
        "assetId": "15e2d8c736fb9a540e1a7fc4b6d80b29",
        "dataFormat": "",
        "md5ext": "15e2d8c736fb9a540e1a7fc4b6d80b29.mp3",
        "sampleCount": 126464,
        "rate": 44100
    },
    {
        "name": "ワープ",
        "tags": [
            "se"
        ],
        "assetId": "f3d70e1c8a5b9426bc7d9374a21e5469",
        "dataFormat": "",
        "md5ext": "f3d70e1c8a5b9426bc7d9374a21e5469.mp3",
        "sampleCount": 399397,
        "rate": 44100
    },
    {
        "name": "シオンタウンのテーマ",
        "tags": [
            "BGM"
        ],
        "assetId": "90d1b6a57c4e8f230b9f6e8253a17dc4",
        "dataFormat": "",
        "md5ext": "90d1b6a57c4e8f230b9f6e8253a17dc4.mp3",
        "sampleCount": 8476020,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="シオンへのみち―クチバより"
                description="シオンへの道―クチバよりのアセット名"
                id="gui.music.loadToSion"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "1e69f28c03dab4579c6d8f25a470eb60",
        "dataFormat": "",
        "md5ext": "1e69f28c03dab4579c6d8f25a470eb60.mp3",
        "sampleCount": 5971140,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="トキワへのみちーマサラより"
                description="トキワへの道ーマサラよりのアセット名"
                id="gui.music.loadToTokiwa"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "ed6caf83524b791c86d3e9a5f07b52d4",
        "dataFormat": "",
        "md5ext": "ed6caf83524b791c86d3e9a5f07b52d4.mp3",
        "sampleCount": 3607380,
        "rate": 44100
    },
    {
        "name": "ニビシティのテーマ",
        "tags": [
            "BGM"
        ],
        "assetId": "4d7be9a326af1c580a23b59c89e0d7fe",
        "dataFormat": "",
        "md5ext": "4d7be9a326af1c580a23b59c89e0d7fe.mp3",
        "sampleCount": 5683020,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="ハナダへのみちーおつきみやまより"
                description="ハナダへの道ーおつきみ山よりのアセット名"
                id="gui.music.loadToHanada"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "8fc3a1b27e45d9600b98e73c49a6f218",
        "dataFormat": "",
        "md5ext": "8fc3a1b27e45d9600b98e73c49a6f218.mp3",
        "sampleCount": 4789260,
        "rate": 44100
    },
    {
        "name": "マサラタウンのテーマ",
        "tags": [
            "BGM"
        ],
        "assetId": "ceb80d3a1f6be27d5c49a2f78d64015f",
        "dataFormat": "",
        "md5ext": "ceb80d3a1f6be27d5c49a2f78d64015f.mp3",
        "sampleCount": 4036655,
        "rate": 44100
    },
    {
        "name": "メインテーマ",
        "tags": [
            "BGM"
        ],
        "assetId": "1b59fc8a236ad79ec0e45d760f82c9ba",
        "dataFormat": "",
        "md5ext": "1b59fc8a236ad79ec0e45d760f82c9ba.mp3",
        "sampleCount": 4604040,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="うみ"
                description="海のアセット名"
                id="gui.music.sea"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "b5cf8a042d6e9f3171e703d85b420cef",
        "dataFormat": "",
        "md5ext": "b5cf8a042d6e9f3171e703d85b420cef.mp3",
        "sampleCount": 5359620,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="たたかい(VSマスタートレーナー)"
                description="戦い(VSマスタートレーナー)のアセット名"
                id="gui.music.vsMasterTrainer"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "73e5dcf1908ba24c67f813495b620ced",
        "dataFormat": "",
        "md5ext": "73e5dcf1908ba24c67f813495b620ced.mp3",
        "sampleCount": 5968200,
        "rate": 44100
    },
    {
        "name": (
            <FormattedMessage
                defaultMessage="たたかい(VSやせいのポケモン)"
                description="戦い(VS野生のポケモン)のアセット名"
                id="gui.music.vsWildPokemon"
            />
        ),
        "tags": [
            "BGM"
        ],
        "assetId": "a2bf304e5cd1f68937c7b1e0d4a56f82",
        "dataFormat": "",
        "md5ext": "a2bf304e5cd1f68937c7b1e0d4a56f82.mp3",
        "sampleCount": 4066020,
        "rate": 44100
    }
]
