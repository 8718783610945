import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';

import Modal from '../../../containers/modal.jsx';
import TeacherTool from './teacher-tool.jsx';

class TeacherToolUiComponent extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClick',
            'handleClose'
        ]);
    }
    handleClick () {
        this.props.onRequestOpen();
    }
    handleClose () {
        this.props.onRequestClose();
    }


    render () {
        return (
            <>

                <Modal
                    fullScreen
                    contentLabel={'先生用指導用資料'}
                    id={this.props.id}
                    onRequestOpen={this.handleClick}
                    onRequestClose={this.props.onSelectBackButton}
                    isShowingBackButton
                >
                    <TeacherTool onSelectBackButton={this.props.onSelectBackButton} />
                </Modal>

            </>
        );
    }
}
TeacherToolUiComponent.propTypes = {
    id: PropTypes.string.isRequired,
    onRequestOpen: PropTypes.func,
    onRequestClose: PropTypes.func,

    onSelectBackButton: PropTypes.func
};

export default injectIntl((TeacherToolUiComponent));
