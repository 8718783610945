import React from 'react';
import styles from './pokemonstartkit.css';
import logo from '../menu-bar/logo.png';

const PokemonstartkitLogo = () => (
    <div className={styles.pokemonstartkitContainer}>
        <img
            src={logo}
            className={styles.logoImage}
            onContextMenu={e => {
                e.preventDefault();
            }}
            draggable={false}
        />
    </div>
);

export default PokemonstartkitLogo;
