import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Button from '../button/button.jsx';
import PpskButton from '../first-ui-library/pokemonstartkit-button/pokemonstartkit-button.jsx';
import CloseButton from '../close-button/close-button.jsx';
import Box from '../box/box.jsx';
import bdGray from './bg-gray-fixed.jpg';
import bgGreen from './bg-green-fixed.jpg';
import styles from './noheader-modal.css';

import backIcon from '../../lib/assets/icon--back.svg';


const ppskStyle = {
    header: {
        backgroundColor: 'rgb(100,201,245)',
        background: 'linear-gradient(50deg, rgba(100,201,245,1) 0%, rgba(244,144,185,1) 100%)'
    },
    bg: {
        backgroundImage: `url(${bdGray})`,
        backgroundRepeat: 'repeat',
        backgroundSize: '960px',
        backgroundColor: 'white',
        overflow: 'auto'
    }
};

const scratchStyle = {
    header: {
        backgroundColor: 'rgb(230,221,0)',
        background: 'linear-gradient(353deg, rgba(230,221,0,1) 0%, rgba(28,189,164,1) 100%)'
    },
    bg: {
        backgroundImage: `url(${bgGreen})`,
        backgroundRepeat: 'repeat',
        backgroundSize: '960px',
        backgroundColor: 'white',
        overflow: 'auto'
    }
};

const ModalComponent = props => {
    const modalStyle = props.scratchPage ? scratchStyle : ppskStyle;
    const thisProps = {
        handleCloseFunction: () => props.isShowingBackButton && props.onRequestClose,
        handleOnContextMenu: e => e.preventDefault()
    };

    return (
        <ReactModal
            isOpen
            className={classNames(styles.modalContent, props.className, modalStyle, {
                [styles.fullScreen]: props.fullScreen
            })}
            overlayClassName={styles.modalOverlay}
            onRequestClose={thisProps.handleCloseFunction}
        >
            <Box
                dir={props.isRtl ? 'rtl' : 'ltr'}
                direction="column"
                grow={1}
                style={modalStyle.bg}
                onContextMenu={thisProps.handleOnContextMenu}
            >
                {props.header && <div className={styles.header}>
                    <div
                        className={classNames(
                            styles.headerItem,
                            styles.headerItemTitle,

                        )}
                        style={modalStyle.header}
                    >
                        <h3>{props.contentLabel}</h3>
                    </div>
                    <div
                        className={classNames(
                            styles.headerItem,
                            styles.headerItemClose
                        )}
                    >
                        {props.isShowingBackButton && (props.fullScreen ? (
                            <Button
                                className={styles.backButton}
                                iconSrc={backIcon}
                                onClick={props.onRequestClose}
                            >
                                <h4>{'もどる'}</h4>
                            </Button>
                        ) : (
                            <CloseButton
                                size={CloseButton.SIZE_LARGE}
                                onClick={props.onRequestClose}
                            />
                        ))}

                    </div>
                </div>}
                {props.ppskBackButton && (props.fullScreen ? (
                    <PpskButton
                        iconSrc={backIcon}
                        onClick={props.onRequestClose}
                        enabled
                        reversalMode
                    >
                        {'もどる'}
                    </PpskButton>
                ) : (
                    <CloseButton
                        size={CloseButton.SIZE_LARGE}
                        onClick={props.onRequestClose}
                    />
                ))}
                {props.children}
            </Box>
        </ReactModal>
    );
};

ModalComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    fullScreen: PropTypes.bool,
    isRtl: PropTypes.bool,
    onRequestClose: PropTypes.func,
    isShowingBackButton: PropTypes.bool,
    ppskBackButton: PropTypes.bool,
    header: PropTypes.bool,
    contentLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    scratchPage: PropTypes.bool
};

export default ModalComponent;
