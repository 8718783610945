export const PullDownTags = [
    {key: 'All', name: '全て'},
    {key: 'Default', name: 'リリース前用'},
    {key: 'LoadTest', name: '負荷検証用'},
    {key: 'Competition_1', name: '第一回(想定)'},
    {key: 'Competition_2', name: '第二回(想定)'}
];

export const AvailableCompetitionTag = [
    {key: 'Default', name: 'リリース前用'},
    {key: 'Competition_1', name: '第一回(想定)'},
    {key: 'Competition_2', name: '第二回(想定)'}
];
