import React from 'react';
import {injectIntl} from 'react-intl';

import Modal from '../../../containers/noheader-modal.jsx';

import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import styles from '../pokemonstartkit.css';
import PokemonstartkitLogo from '../pokemonstartkit-logo.jsx';
import PokemonstartkitButtonComponent from '../pokemonstartkit-button/pokemonstartkit-button.jsx';

class StudentUiComponent extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClick',
            'handleClose'
        ]);
    }
    handleClick () {
        this.props.onRequestOpen();
    }
    handleClose () {
        this.props.onRequestClose();
    }

    render () {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestOpen={this.handleClick}
                onRequestClose={this.props.onSelectBackButton}
                isShowingBackButton
                header
            >
                <PokemonstartkitLogo />
                <div className={styles.pokemonstartkitContainer}>
                    <h1><ruby>注意<rt>ちゅうい</rt></ruby></h1>
                </div>
                
                <div className={styles.pokemonstartkitContainer}>
                    <h2><ruby>先生<rt>せんせい</rt></ruby>の<ruby>指示<rt>しじ</rt></ruby>に<ruby>従<rt>したが</rt></ruby>って、このサイトは<ruby>利用<rt>りよう</rt></ruby>してください。</h2>
                </div>
                <div className={styles.pokemonstartkitContainer}>
                    
                    <PokemonstartkitButtonComponent
                        onClick={this.handleClose}
                        enabled
                    >
                        <ruby>確認<rt>かくにん</rt></ruby>しました
                    </PokemonstartkitButtonComponent>
                </div>
            </Modal>
        );
    }
}
StudentUiComponent.propTypes = {
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    title: PropTypes.object,
    // modalTitleList: PropTypes.arrayOf(PropTypes.string),
    // selectedId: PropTypes.number,
    onRequestOpen: PropTypes.func,
    onRequestClose: PropTypes.func,

    onSelectBackButton: PropTypes.func
};

export default injectIntl(StudentUiComponent);
