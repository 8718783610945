/* eslint-disable react/jsx-no-bind */
// import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import Modal from '../../containers/modal.jsx';
import Spinner from '../spinner/spinner.jsx';
import * as XLSX from 'xlsx';
import dotenv from 'dotenv';
import axios from 'axios';
import downloadBlob from '../../lib/download-blob.js';

import styles from './competition-dev.css';

import {PullDownTags} from '../../lib/libraries/competition.js';

const headerLabel = {
    tag: '大会',
    projectName: '作品名',
    comment: 'コメント',
    userName: 'ユーザー名',
    schoolName: '学校名',
    prefName: '都道府県名',
    cityName: '市区町村名',
    registerDate: '応募日時',

    checked: '確認済'
    
};
// const exportheaderLabel = {
//    userName: 'ユーザー名',
//    schoolName: '学校名',
//    projectName: '作品名',
//    comment: 'コメント',
//    checked: '確認済'
// };
const header = Object.keys(headerLabel);
const headerCell = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1'];

const radioName = 'compe-dev-radio';
dotenv.config();
const env = process.env;

// const DB_URL = env.ENV_MODE === 'develop' ? env.DEV_DB_URL : env.PRD_DB_URL;
// const DB_URL = env.PRD_DB_URL; //リリース後はこちらのURLを有効にする


// const CHECK_PROJECT_METADATA_URL = `${DB_URL}check/project/`;
// const DELETE_PROJECT_BY_ID_URL = `${DB_URL}delete/project/id/`;
// const DELETE_PROJECT_BASE_URL = `${DB_URL}delete/project/`;
// const GET_PROJECT_METADATA_URL = `${DB_URL}get/project/all/`;
// const LOAD_PROJECT_URL = `${DB_URL}get/project/data/`;

const databaseURL = isDevelop => (isDevelop ? env.DEV_DB_URL : env.PRD_DB_URL);
// const databaseURL = isDevelop => 'http://localhost:3000/';

const CHECK_PROJECT_METADATA_PATH = isDevelop => `${databaseURL(isDevelop)}check/project/`;
const DELETE_PROJECT_BY_ID_PATH = isDevelop => `${databaseURL(isDevelop)}delete/project/id/`;
const DELETE_PROJECT_BASE_PATH = isDevelop => `${databaseURL(isDevelop)}delete/project/`;
const GET_PROJECT_METADATA_PATH = isDevelop => `${databaseURL(isDevelop)}get/project/all/`;
const GET_PROJECT_PAGE_PATH = isDevelop => `${databaseURL(isDevelop)}get/project/page/`;
const LOAD_PROJECT_PATH = isDevelop => `${databaseURL(isDevelop)}get/project/data/`;

const ALLOW_PAGE_DIFF = 5;

const API_KEY = env.API_KEY;

const CHECKED_MESSAGE = '〇';
const NOT_CHECKED_MESSAGE = '×';

class CompetitionComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'getData',
            'handleSelectRadio',
            'handleSelectLoadButton',
            'handleSelectCheckButton',
            'handleUpload',
            'handleExportXLSX',
            'onLoadData',
            'handleDownloadData',
            'adjustDataSets',
            'adjustXLSXDataSets',
            'handleSelectTag',
            'filterData',
            'handleDeletedata',
            'handleDeleteAllData',
            'handleRemoveSelected',
            'onUnchecked',
            'initializeSelected',
            'handleChangeUseDevelop',
            'handleClickPageNumber',
            'handleChangePageNumber',
            'getDataPerPage'
        ]);

        this.state = {
            isLoaded: false,
            dataSets: [],
            loadDataState: {
                userName: '',
                schoolName: '',
                projectName: '',
                projectId: null
            },
            isSelected: false,
            isAvailableLoading: true,
            tagFilter: 'All',
            isDevelop: true,
            pageNumber: 1
        };

    }

    componentDidMount () {
        new Promise(async () => {
            try {
                await this.getData();
            } catch (_) {
                let intervalId = setInterval(async () => {
                    try {
                        await this.getData();
                        clearInterval(intervalId);
                        intervalId = null;
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    }
                }, 3000);

                setTimeout(() => {
                    if (intervalId) {
                        clearInterval(intervalId);
                        // eslint-disable-next-line no-alert
                        window.alert('データの取得に失敗しました。通信環境を確認してください。');
                        this.handleClose();
                    }
                }, 10000);
            }
        })
            .catch(error => {
                throw new Error(error);
            });

    }

    initializeSelected () {
        this.setState({
            loadDataState: {
                userName: '',
                schoolName: '',
                projectName: '',
                projectId: null
            },
            isSelected: false
        });
    }

    getData (isDevelop = null) {
        return new Promise((resolve, reject) => {
            if (isDevelop === null) isDevelop = this.state.isDevelop;
            axios({
                method: 'get',
                url: GET_PROJECT_METADATA_PATH(isDevelop),
                proxy: false,
                timeout: 1000 * 10
            })
                .then(d => d.data)
                .then(data => {
                    this.setState({
                        dataSets: data,
                        isLoaded: true,
                        checked: false
                    });
                    resolve(true);
                })
                .catch(error => {
                    reject(error);
                });

        })
            .catch(error => {
                throw new Error(error);
            });
    }

    getDataPerPage (isDevelop = null, pageNumber = 1) {
        return new Promise((resolve, reject) => {
            if (isDevelop === null) isDevelop = this.state.isDevelop;
            axios({
                method: 'get',
                url: `${GET_PROJECT_PAGE_PATH(isDevelop)}?page=${pageNumber - 1}`,
                proxy: false,
                timeout: 1000 * 10
            })
                .then(d => d.data)
                .then(data => {
                    this.setState({
                        dataSets: data,
                        isLoaded: true,
                        checked: false
                    });
                    resolve(true);
                })
                .catch(error => {
                    reject(error);
                });

        })
            .catch(error => {
                throw new Error(error);
            });
    }

    handleChangeUseDevelop () {
        this.setState(state => ({
            isDevelop: !state.isDevelop,
            pageNumber: 1
        }));
        this.handleRemoveSelected();
        this.getData(!this.state.isDevelop);
    }

    handleClose () {
        this.props.onRequestClose();
    }

    handleSelectRadio () {
        const element = document.getElementById('accept-tbody');
        if (element) {
            const rows = Array.from(element.rows);
            rows.forEach((row, i) => {
                if (row.cells[0].firstChild.checked) {
                    this.setState({
                        isSelected: true,
                        loadDataState: this.filterData()[i]
                    });
                }
            });
        }
    }

    handleSelectLoadButton (){
        // console.log('load');
        this.setState({isAvailableLoading: false});
        this.onLoadData({projectId: this.state.loadDataState.projectId});
    }

    onUnchecked () {
        const checkBoxElements = document.getElementsByClassName(styles.checkbox);
        if (checkBoxElements) {
            const checkBoxelementArray = Array.from(checkBoxElements);
            checkBoxelementArray.forEach(element => {
                if (element.checked) {
                    element.checked = false;
                }
            });
        }
    }

    handleSelectCheckButton () {
        new Promise((resolve, reject) => {
            const data = this.state.loadDataState;
            data.checked = !data.checked;
            axios({
                method: 'post',
                url: CHECK_PROJECT_METADATA_PATH(this.state.isDevelop),
                data: JSON.stringify({
                    checked: data.checked,
                    projectId: data.projectId

                }),
                headers: {
                    'content-type': 'application/json'
                },
                proxy: false,
                timeout: 1000 * 10

            })
                .catch(error => {
                    reject(error);
                })
                .then(() => {
                    this.getData();
                    resolve();
                });
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('データの送信に失敗しました。通信環境を確認してください。');
            });

    }

    handleUpload () {
        this.props.onUpLoadProject(this.handleClose);
    }

    handleExportXLSX () {
        new Promise(resolve => {
            if (this.filterData().length === 0) {
                // eslint-disable-next-line no-alert
                window.alert('データが空です');
                // await window.ownAlert('データが空です');
                // return;
                return resolve();
            }
            const now = new Date();
            const exportBook = XLSX.utils.book_new();

            const exportSheet = XLSX.utils.json_to_sheet(this.adjustDataSets());

            exportSheet['!ref'] = `A1:I${this.state.dataSets.length + 1}`;
            Object.values(headerLabel).forEach((data, i) => {
                exportSheet[headerCell[i]].v = data;
            });
            XLSX.utils.book_append_sheet(exportBook, exportSheet, 'user_data');
            XLSX.writeFile(exportBook,
                `competitionData-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}.xlsx`);
            resolve();
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('予期せぬエラーが発生しました。(xlsx出力)');
            });
    }

    onLoadData (data) {
        new Promise((mainResolve, mainReject) => {
            this.setState({isLoaded: false});
            axios({
                method: 'post',
                url: LOAD_PROJECT_PATH(this.state.isDevelop),
                data: JSON.stringify(data),
                headers: {
                    'content-type': 'application/json'
                },
                responseType: 'blob',
                proxy: false,
                timeout: 1000 * 60

            })
                .then(d => d.data)
                .then(blob => {
                    new Promise(async (resolve, reject) => {
                        try {
                            await this.props.onLoadProject(blob);
                            this.handleClose();
                            return resolve();
                        } catch (err) {
                            return reject(err);
                        }
                    })
                        .catch(err => {
                            mainReject(err);
                        })
                        .then(() => {
                            mainResolve();
                        });
                })
                .catch(error => {
                    mainReject(error);
                });
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('データの取得に失敗しました。通信環境を確認してください。');
                this.setState({isAvailableLoading: true});
                this.getData();
            });

    }

    handleDownloadData () {
        new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: LOAD_PROJECT_PATH(this.state.isDevelop),
                data: JSON.stringify({projectId: this.state.loadDataState.projectId}),
                headers: {
                    'content-type': 'application/json'
                },
                responseType: 'blob',
                proxy: false,
                timeout: 1000 * 60

            })
                .catch(error => {
                    reject(error);
                })
                .then(d => d.data)
                .then(blob => {
                    this.setState({isAvailableLoading: false});
                    
                    downloadBlob(
                        `${this.state.loadDataState.userName}_${this.state.loadDataState.projectname}.ppsk`,
                        blob
                    );
                    this.setState({isAvailableLoading: true});
                    this.setState({isLoaded: true});
                    resolve();
                });
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('データの取得に失敗しました。通信環境を確認してください。');
            });

    }

    filterData () {
        return this.state.dataSets.filter(data =>
            (this.state.tagFilter === 'All' ? true : data.tag && data.tag.includes(this.state.tagFilter))
        );
    }

    adjustDataSets () {
        return this.filterData().map(data => {
            const tag = PullDownTags.find(tagData => tagData.key === data.tag);

            return {
                tag: tag ? tag.name : '一致するタグがありません',
                projectName: data.projectname,
                comment: data.comment ? data.comment : 'コメントがありません',
                userName: data.userName ? data.userName : 'なし',
                schoolName: data.schoolName ? data.schoolName : 'なし',
                prefName: data.prefName ? data.prefName : 'なし',
                cityName: data.cityName ? data.cityName : 'なし',
                registerDate: data.registerDate ? new Date(data.registerDate).toLocaleString('ja') : 'なし',

                checked: data.checked ? CHECKED_MESSAGE : NOT_CHECKED_MESSAGE
            };
        });
    }

    adjustXLSXDataSets () {
        return this.filterData().map(data => ({
            userName: data.userName,
            schoolName: data.schoolName,
            projectName: data.projectname,
            comment: data.comment ? data.comment : 'コメントがありません',
            checked: data.checked ? CHECKED_MESSAGE : NOT_CHECKED_MESSAGE
        }));

    }

    handleSelectTag (event){
        const tag = event.currentTarget.value;
        if (this.state.tagFilter !== tag) {
            this.setState({tagFilter: tag});
            this.handleRemoveSelected();
        }
    }

    handleDeletedata () {
        // eslint-disable-next-line no-alert
        if (!window.confirm('本当に削除しますか？')) return;

        new Promise((resolve, reject) => {
            const data = this.state.loadDataState;
            axios({
                method: 'post',
                url: DELETE_PROJECT_BY_ID_PATH(this.state.isDevelop),
                data: JSON.stringify({
                    projectId: data.projectId

                }),
                headers: {
                    'content-type': 'application/json',
                    'api-key': API_KEY
                },
                proxy: false,
                timeout: 1000 * 10

            })
                .then(() => {
                    this.onUnchecked();
                    this.initializeSelected();
                    // eslint-disable-next-line no-alert
                    // window.alert('正常に削除されました');
                    setTimeout(() => {
                        this.getData();
                        resolve();
                    }, 100);
                })
                .catch(error => {
                    reject(error);
                });
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('データの送信に失敗しました。通信環境を確認してください。');
            });
    }

    handleDeleteAllData (event, isDevelop = null) {
        // eslint-disable-next-line no-alert
        if (!window.confirm('現在表示されている全てのレコードが削除されます。一度削除すると復元はできませんが、本当に削除しますか？')) return;
        if (isDevelop === null) isDevelop = this.state.isDevelop;

        const DELETE_PATH = DELETE_PROJECT_BASE_PATH(isDevelop);

        const URL = this.state.tagFilter === 'All' ?
            `${DELETE_PATH}all/` : `${DELETE_PATH}tag/`;

        new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify({
                    tag: this.state.tagFilter
                }),
                headers: {
                    'content-type': 'application/json',
                    'api-key': API_KEY
                },
                proxy: false,
                timeout: 1000 * 60

            })
                .then(() => {
                    this.onUnchecked();
                    this.initializeSelected();
                    // eslint-disable-next-line no-alert
                    window.alert('正常に削除されました');
                    setTimeout(() => {
                        this.getData();
                        resolve();
                    }, 100);
                })
                .catch(error => {
                    reject(error);
                });
        })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert('データの送信に失敗しました。通信環境を確認してください。');
            });
    }

    handleRemoveSelected () {
        this.setState({
            isSelected: false,
            loadDataState: {
                userName: '',
                schoolName: '',
                projectName: '',
                projectId: null
            }
        });
        this.onUnchecked();
        this.initializeSelected();
    }

    handleClickPageNumber (number) {
        // console.log(number);
        this.setState({pageNumber: number});
        this.getDataPerPage(this.state.isDevelop, number);
    }

    handleChangePageNumber (number) {
        const newNumber = this.state.pageNumber + number;
        this.setState({pageNumber: newNumber});
        this.getDataPerPage(this.state.isDevelop, newNumber);
    }

    render () {
        // const pageNumber = [...Array(Math.ceil(this.filterData().length / 20))].map((_, i) => i + 1);
        return (
            <Modal
                fullScreen
                contentLabel={(this.state.isLoaded || this.state.isAvailableLoading) ? this.props.title : 'ロード中です・・・'}
                id={this.props.id}
                onRequestClose={this.handleClose}
                isShowingBackButton={(this.state.isLoaded || this.state.isAvailableLoading)}
            >
           
                {
                    this.state.isLoaded ?

                        <>
                            <div className={this.state.isSelected ? styles.selectedBody : styles.nonSelectedBody}>
                                <div
                                    className={styles.uploadButtonBlock}
                                >
                                    <button
                                        className={styles.uploadButton}
                                        onClick={this.handleUpload}
                                    >
                                        ローカルからアップロードする
                                    </button>
                                    <button
                                        className={styles.uploadButton}
                                        onClick={this.handleExportXLSX}
                                    >
                                        提出者の情報を書き出す
                                    </button>
                                    <button
                                        className={styles.uploadButton}
                                        onClick={this.handleDeleteAllData}
                                    >
                                        リスト内の全データを削除
                                    </button>
                                    <div className={styles.uploadButtonBox}>
                                        <select
                                            className={styles.competitionModeSelector}
                                            onChange={this.handleSelectTag}
                                        >
                                            {
                                                PullDownTags.map(tag => (
                                                    <option
                                                        className={styles.competitionModeOption}
                                                        // eslint-disable-next-line no-shadow, react/jsx-no-bind
                                                        key={tag.key}
                                                        value={tag.key}
                                                    >
                                                        {tag.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <div className={styles.useDevelopWrapper}>

                                            <label
                                                className={styles.useDevelopLabel}
                                                onClick={this.handleChangeUseDevelop}
                                            >
                                                開発者用DBを参照
                                            </label>
                                            <input
                                                type="checkbox"
                                                onChange={this.handleChangeUseDevelop}
                                                checked={this.state.isDevelop}
                                            />
                                        </div>
                                    </div>

                                </div>

                                {
                                    this.state.dataSets && this.filterData().length > 0 ?
                                    
                                        <div
                                            className={
                                                this.state.isSelected ?
                                                    styles.compeDevTableWrapperSelected : styles.compeDevTableWrapper
                                            }
                                        >
                                            <table className={styles.toppageTable}>
                                                <thead key="header">
                                                    <tr>
                                                        <th
                                                            className={styles.competitionDevHeader}
                                                            id={header}
                                                            key={header}
                                                        >
                                                            <input
                                                                className={styles.checkbox}
                                                                type="radio"
                                                                name={radioName}
                                                                disabled
                                                            />
                                                        </th>
                                                        {
                                                            Object.values(headerLabel).map(headerName =>
                                                                (<th
                                                                    className={styles.competitionDevHeader}
                                                                    key={headerName}
                                                                ><p>{headerName}</p></th>)
                                                            )
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    key="accept-tbody"
                                                    id="accept-tbody"
                                                >
                                                    {
                                                        this.state.dataSets &&
                                                        this.adjustDataSets()
                                                            .map((data, i) =>
                                                                (<tr
                                                                    key={`table-row-${i}`}
                                                                    className={
                                                                        this.filterData()[i].checked ?
                                                                            styles.checkedRow : styles.nonCheckedRow
                                                                    }
                                                                >
                                                                    <td className={styles.competitionDevBody}>
                                                                        <input
                                                                            className={styles.checkbox}
                                                                            onClick={this.handleSelectRadio}
                                                                            type="radio"
                                                                            name={radioName}
                                                                        />
                                                                    </td>
                                                                    {
                                                                        Object.values(data).map((cell, j) => {
                                                                            if (header.length <= j) return null;
                                                                            return (<td
                                                                                className={styles.competitionDevBody}
                                                                                key={`${header[j]}_${i} `}
                                                                            ><p>{cell}</p></td>);
                                                                        })
                                                                    }
                                                                </tr>)

                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                            {/*<div className={styles.pageWrapper}>*/}
                                            {/*    {*/}
                                            {/*        this.state.pageNumber !== 1 &&*/}
                                            {/*        <div*/}
                                            {/*            className={styles.numberDivision}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                this.handleChangePageNumber(-1);*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            {'BACK'}*/}
                                            {/*        </div>*/}
                                            {/*    }*/}

                                            {/*    {*/}
                                            {/*        pageNumber*/}
                                            {/*            .filter(number => Math.abs(this.state.pageNumber - number) <= ALLOW_PAGE_DIFF)*/}
                                            {/*            .map(number => (<>*/}
                                            {/*                <div*/}
                                            {/*                    className={(!this.state.isAllMode && this.state.pageNumber === number) ?*/}
                                            {/*                        styles.selectNumberDivision : styles.numberDivision}*/}
                                            {/*                    onClick={() => {*/}
                                            {/*                        this.handleClickPageNumber(number);*/}
                                            {/*                    }}*/}
                                            {/*                >*/}
                                            {/*                    {number}*/}
                                            {/*                </div>*/}
                                            {/*                {number !== pageNumber.length && ' , '}*/}
                                            {/*            </>))*/}
                                            {/*    }*/}

                                            {/*    {*/}
                                            {/*        this.state.pageNumber !== pageNumber.length &&*/}
                                            {/*        <div*/}
                                            {/*            className={styles.numberDivision}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                this.handleChangePageNumber(1);*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            {'NEXT'}*/}
                                            {/*        </div>*/}
                                            {/*    }*/}


                                            {/*</div>*/}
                                        </div> : <div className={styles.noDataTextWrapper}>
                                            <p>提出されたデータがありません</p>
                                        </div>
                                }
                            </div>
                            {
                                this.state.isSelected &&
                                    <div className={styles.loadDataStateBox}>
                                        <table className={styles.loadDataTable}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.loadDataTd}><p>ユーザー名</p></td>
                                                    <td className={styles.loadDataUserName}><span>{this.state.loadDataState.userName}</span></td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.loadDataTd}><p>作品名</p></td>
                                                    <td className={styles.loadDataProjectName}><span>{this.state.loadDataState.projectname}</span></td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.loadDataTd}><p>コメント</p></td>
                                                    <td className={styles.loadDataComment}><span>{this.state.loadDataState.comment ? this.state.loadDataState.comment : 'コメントがありません'}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <p>{`ユーザー名：${this.state.loadDataState.userName}`}</p>*/}
                                        {/* <p>{`作品名：${this.state.loadDataState.projectname}`}</p>*/}

                                        {/* <p>{`学校名：${this.state.loadDataState.schoolName}`}</p>*/}
                                        <div className={styles.loadDataStateButtonBox}>
                                            {/* <button*/}
                                            {/*    onClick={this.handleRemoveSelected}*/}
                                            {/*    className={styles.loadDataStateButton}*/}
                                            {/* >選択解除*/}
                                            {/* </button>*/}
                                            <button
                                                onClick={this.handleRemoveSelected}
                                                className={styles.loadDataStateButton}
                                            >選択解除
                                            </button>
                                            {
                                                this.state.isAvailableLoading ?
                                                    <button
                                                        onClick={this.handleSelectLoadButton}
                                                        className={styles.loadDataStateButton}
                                                    >読み込む
                                                    </button> : <button
                                                        onClick={this.handleSelectLoadButton}
                                                        className={styles.loadDataStateButton}
                                                        disabled
                                                    >読み込む
                                                    </button>
                                            }
                                            <button
                                                onClick={this.handleSelectCheckButton}
                                                className={styles.loadDataStateButton}
                                            >チェック
                                            </button>
                                            <button
                                                onClick={this.handleDownloadData}
                                                className={styles.loadDataStateButton}
                                            >DL
                                            </button>
                                            <button
                                                onClick={this.handleDeletedata}
                                                className={styles.loadDataStateButton}
                                            >削除
                                            </button>
                                        </div>
                                    </div>
                            }
                        </> :
                        <div className={styles.spinnerWrapper}>
                            <Spinner
                                large
                                level="primary"
                            />
                        </div>

                }

            </Modal>
        );
    }
}

CompetitionComponent.propTypes = {
    onRequestClose: PropTypes.func,
    onUpLoadProject: PropTypes.func,
    onLoadProject: PropTypes.func,

    title: PropTypes.string,
    id: PropTypes.string
};


export default injectIntl(CompetitionComponent);
