import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape} from 'react-intl';

import CompetitionUI from '../components/competition/competition.jsx';

class Competition extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);
    }

    handleClose () {
        this.props.onRequestClose();
    }

    render () {
        return (
            <CompetitionUI
                intl={this.props.intl}
                id={'competiion-modal'}
                onRequestClose={this.handleClose}
                onDownLoadProject={this.props.onDownLoadProject}
            />
        );
    }
}

Competition.propTypes = {
    intl: intlShape,
    onRequestClose: PropTypes.func,
    onDownLoadProject: PropTypes.func
};

export default injectIntl(Competition);
