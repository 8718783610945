import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';


export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'mixedPokemonPen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    //
    // {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Pokemon Pen"
    //            description="Name for the 'Pokemon Pen' extension"
    //            id="gui.extension.pokemonpen.name"
    //        />
    //    ),
    //    extensionId: 'pokemonPen',
    //    iconURL: penIconURL,
    //    insetIconURL: penInsetIconURL,
    //    description: (
    //        <FormattedMessage
    //            defaultMessage="Draw with your sprites."
    //            description="Description for the 'Pokemon Pen' extension"
    //            id="gui.extension.pokemonpen.description"
    //        />
    //    ),
    //    featured: true
    // },
    // {
    //    name: (
    //        <FormattedMessage
    //            defaultMessage="Mix New Pokemon Pen"
    //            description="Name for the 'Mixed New Pokemon Pen' extension"
    //            id="gui.extension.mixedPokemonpen.name"
    //        />
    //    ),
    //    extensionId: 'mixedPokemonPen',
    //    iconURL: penIconURL,
    //    insetIconURL: penInsetIconURL,
    //    description: (
    //        <FormattedMessage
    //            defaultMessage="Draw with your sprites."
    //            description="Description for the 'Mixed New Pokemon Pen' extension"
    //            id="gui.extension.mixedPokemonpen.description"
    //        />
    //    ),
    //    featured: true
    // },
    
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    }
];
