/* eslint-disable prefer-template */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import {setProjectSavingState} from '../reducers/project-saving';
import {setProjectUnchanged} from '../reducers/project-changed';
import {intlShape} from 'react-intl';
import downloadBlob from './download-blob';
import dotenv from 'dotenv';
import {connectDB} from './indexedDB.jsx';
import VM from 'scratch-vm';

dotenv.config();
const IndexedDBSaverHOC = function (WrappedComponent) {

    const getTimeData = () => new Date().toLocaleString('ja');
    const env = process.env;

    class IndexedDBsaverComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'saveProject',
                'onSave',
                'tryAutoSave',

                'handleSetAutosave',
                'handleUnsetAutosave',
                'handleSave',
                'handleDownload',
                'onFinishSaving',
                'onSaveSnapshot'
            ]);

            this.intervalID = null;
            this.saveStateTimeoutID = null;
        }

        // componentDidUpdate () {
        //   if (this.props.projectChanged && this.props.isActiveAutosave && this.props.projectSavingState !== true) {
        //       console.log('auto save :', this.props.projectId);
        //       if (this.props.projectId) {
        //           this.saveProject(false, this.props.projectId);
        //       }
        //   }
        // }

        componentWillUnmount () {
            this.handleUnsetAutosave();
        }

        handleUnsetAutosave () {
            // console.log('unset');
            if (this.intervalID) {
                console.log('unset autosave');
                clearInterval(this.intervalID);
                this.intervalID = null;
            }
        }

        handleSetAutosave () {
            this.handleUnsetAutosave();
            // console.log('set');
            if (this.intervalID === null) {
                console.log('set autosave');
                this.intervalID = setInterval(this.tryAutoSave, this.props.autoSaveIntervalSecs * 1000);
            }
        }

        tryAutoSave () {
            if (this.props.projectChanged && this.props.isActiveAutosave && this.props.projectSavingState !== true) {
                console.log('auto save :', this.props.projectId);
                if (this.props.projectId) this.saveProject(false, this.props.projectId);
            }
        }

        saveProject (isDownLoaded = false, id = null, name = null, isValidateMessage = true) {
            return new Promise(async (resolve, reject) => {
                let db;
                try {
                    if (isValidateMessage) this.props.onSetProjectSavingState(true);
                    db = await connectDB();

                    const projectId = id === null ? this.props.projectId : id;
                    await this.onSave(db, projectId, isDownLoaded, name).catch(() => {
                        throw new Error();
                    });
                    if (isValidateMessage) this.onFinishSaving();
                    return resolve();
                } catch (error) {
                    this.props.onSetProjectSavingState(null);
                    return reject(error);
                } finally {
                    db.close();
                }
            });
        }

        onSave (db, id, isDownLoaded, name) {
            return new Promise(async (resolve, reject) => {
                try {
                    const content = await this.props.saveProjectSb3();
                    const buffer = await content.arrayBuffer();

                    const arrayBuffer = this.props.zipProjectWithPass(buffer, env.ZIP_UNLOCK_KEY, id ? id : 'default');
                    const blob = new Blob([arrayBuffer], {type: 'application/x.scratch.ppsk'});

                    if (isDownLoaded) {
                        // const filename = id === null ? 'projectData' : id;
                        const filename = 'projectData';
                        downloadBlob(filename + '.ppsk', blob);
                        db.close();
                        return resolve();
                    }
                    const transaction = db.transaction(['projectData'], 'readwrite');
                    const objItem = transaction.objectStore('projectData');

                    const request = objItem.put(
                        {
                            id: id,
                            Data: blob
                        }
                    );

                    request.onerror = error => {
                        db.close();
                        return reject(error);
                    };
                    request.onsuccess = () => {
                        console.log('Save : ', id);
                        this.props.setProjectUnchanged();
                        // const playerTransaction = db.transaction(['playerData'], 'readwrite');
                        // const playerObjItem = playerTransaction.objectStore('playerData');

                        const data = {
                            id: id,
                            name: name === null ? this.props.projectFilename : name
                        };

                        console.log(data);

                        const waitSnap = new Promise(res => {
                            this.props.vm.renderer.requestSnapshot(snap => this.onSaveSnapshot(snap, res));
                        });

                        waitSnap.then(snap => {

                            data.time = getTimeData();
                            data.image = snap;

                            const trans = db.transaction(['playerData'], 'readwrite');
                            trans.objectStore('playerData').put(data);

                            db.close();
                            return resolve();
                        });

                    };
                    
                } catch (error) {
                    db.close();
                    return reject(error);
                }

            });
        }


        onSaveSnapshot (snapshot, resolve) {
            resolve(snapshot);
        }

        onFinishSaving () {
            // console.log("false");
            this.props.onSetProjectSavingState(false);
            if (this.saveStateTimeoutID) {
                clearTimeout(this.saveStateTimeoutID);
            }
            this.saveStateTimeoutID = setTimeout(() => {
                this.props.onSetProjectSavingState(null);
                this.saveStateTimeoutID = null;
                // console.log("null");
            }, this.props.savelogMillisecs);
        }

        handleDownload (id, name = null, isValidateMessage = true) {
            this.saveProject(true, id, name, isValidateMessage);
        }

        handleSave (id, name = null, isValidateMessage = true){
            this.saveProject(false, id, name, isValidateMessage);
        }

        render () {
            const {
                autoSaveIntervalSecs,
                projectFilename,
                saveProjectSb3,
                zipProjectWithPass,
                projectChanged,
                setProjectUnchanged,
                projectId,
                resetProjectID,
                onSetProjectSavingState,
                isActiveAutosave,
                savelogMillisecs,
                projectSavingState,
                vm,
                ...componentProps
            } = this.props;

            return (
                <React.Fragment>
                    <WrappedComponent
                        onSaveProject={this.handleSave}
                        onDownLoadProject={this.handleDownload}
                        onSetAutosave={this.handleSetAutosave}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    const getProjectFilename = (curTitle, defaultTitle) => {
        let filenameTitle = curTitle;
        if (!filenameTitle || filenameTitle.length === 0) {
            filenameTitle = defaultTitle;
        }
        return `${filenameTitle.substring(0, 100)}`;
    };

    IndexedDBsaverComponent.propTypes = {
        intl: intlShape.isRequired,
        projectFilename: PropTypes.string,
        saveProjectSb3: PropTypes.func,
        zipProjectWithPass: PropTypes.func,
        projectChanged: PropTypes.bool,
        setProjectUnchanged: PropTypes.func,
        autoSaveIntervalSecs: PropTypes.number,
        savelogMillisecs: PropTypes.number,
        projectId: PropTypes.string,
        isActiveAutosave: PropTypes.bool,
        onSetProjectSavingState: PropTypes.func,
        projectSavingState: PropTypes.bool,
        vm: PropTypes.instanceOf(VM).isRequired


    };
    IndexedDBsaverComponent.defaultProps = {
        autoSaveIntervalSecs: 20, // 60
        savelogMillisecs: 3000// 60
    };

    const mapStateToProps = state => ({
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        zipProjectWithPass: state.scratchGui.vm.zipProjectWithPass.bind(state.scratchGui.vm),
        projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
        projectChanged: state.scratchGui.projectChanged,
        projectId: state.scratchGui.projectId,
        isActiveAutosave: state.scratchGui.autosaveState,
        projectSavingState: state.scratchGui.savingState.project,
        vm: state.scratchGui.vm
    });

    const mapDispatchToProps = dispatch => (
        {
            setProjectUnchanged: () => dispatch(setProjectUnchanged()),
            onSetProjectSavingState: state => dispatch(setProjectSavingState(state))
        }
    );

    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );

    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(IndexedDBsaverComponent);
};

export {
    IndexedDBSaverHOC as default
};
