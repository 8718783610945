/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './curricurum-tools.css';
import downloadBlob from '../../../../lib/download-blob';

class CurricurumTools extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelect',
            'handleSelectDownloadAll'
        ]);
    }

    handleSelect (url) {
        window.open(url, '_blank');
    }

    handleSelectDownloadAll (path, name) {
        if (!path) return;
        const fileName = name || 'download';

        fetch(path)
            .then(data => data.blob())
            .then(blob => {
                downloadBlob(`${fileName}.zip`, blob);

            });
    }

    render () {
        const dir = this.props.toolDatas.dir;
        return (
            <>
                <div className={styles.curricurumContainer}>
                    {
                        this.props.toolDatas.allDatas &&
                        <div className={styles.downloadAllContainer}>
                            <button
                                className={styles.downloadAllButton}
                                onClick={() => {
                                    this.handleSelectDownloadAll(this.props.toolDatas.dir + this.props.toolDatas.allDatas.src, this.props.toolDatas.allDatas.name);
                                }}
                            >
                                {'一括ダウンロード'}
                            </button>
                        </div>
                    }

                    <div className={styles.toolsContainer}>
                        {
                            this.props.toolDatas.slides &&
                            <>
                                <div className={styles.slidesContainer}>
                                    <h2>{'指導用スライド'}</h2>
                                    <div className={styles.slides}>
                                        {this.props.toolDatas.slides.map(slide => (
                                            <div
                                                className={styles.slide}
                                                onClick={() => {
                                                    this.handleSelect(`${dir}${slide.src}`);
                                                }}
                                            >
                                                {
                                                    slide.name &&
                                                    <h3>{slide.name}</h3>
                                                }
                                                {
                                                    slide.thumbnail &&
                                                    <img
                                                        src={`${dir}${slide.thumbnail}`}
                                                    />
                                                }
                                                <p>{'クリックすると別タブで開きます'}<br /> {'ダウンロード可能です'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            this.props.toolDatas.teachingPlans &&
                            <>
                                <div className={styles.teachingPlanContainer}>
                                    <h2>{'指導案'}</h2>
                                    <div className={styles.teachingPlans}>
                                        {this.props.toolDatas.teachingPlans.map(plan => (
                                            <div
                                                className={styles.teachingPlan}
                                                onClick={() => {
                                                    this.handleSelect(`${dir}${plan.src}`);
                                                }}
                                            >
                                                {
                                                    plan.name &&
                                                    <h3>{plan.name}</h3>
                                                }
                                                {
                                                    plan.thumbnail &&
                                                    <img
                                                        src={`${dir}${plan.thumbnail}`}
                                                    />
                                                }
                                                <p>{'クリックすると別タブで開きます'}<br /> {'ダウンロード可能です'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            this.props.toolDatas.hintCards &&
                            <>
                                <div className={styles.startingTextContainer}>
                                    <h2>{'ヒントカード'}</h2>
                                    <p>{'応用編に取り組むときのヒントカードです。'}<br /> {'AからHまで、全16ページ。'}</p>
                                    <div className={styles.startingTextWrapper}>
                                        {this.props.toolDatas.hintCards.map(hintCard => (
                                            <div
                                                className={styles.startingText}
                                                onClick={() => {
                                                    this.handleSelect(`${dir}${hintCard.src}`);
                                                }}
                                            >
                                                {
                                                    hintCard.name &&
                                                    <h3>{hintCard.name}</h3>
                                                }
                                                {
                                                    hintCard.thumbnail &&
                                                    <img
                                                        src={`${dir}${hintCard.thumbnail}`}
                                                    />
                                                }
                                                <p>{'クリックすると別タブで開きます'}<br /> {'ダウンロード可能です'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            this.props.toolDatas.workSheet &&
                            <>
                                <div className={styles.startingTextContainer}>
                                    <h2>{'ワークシート'}</h2>
                                    <div className={styles.startingTextWrapper}>
                                        {this.props.toolDatas.workSheet.map(workSheet => (
                                            <div
                                                className={styles.startingText}
                                                onClick={() => {
                                                    this.handleSelect(`${dir}${workSheet.src}`);
                                                }}
                                            >
                                                {
                                                    workSheet.name &&
                                                    <h3>{workSheet.name}</h3>
                                                }
                                                {
                                                    workSheet.thumbnail &&
                                                    <img
                                                        src={`${dir}${workSheet.thumbnail}`}
                                                    />
                                                }
                                                <p>{'クリックすると別タブで開きます'}<br /> {'ダウンロード可能です'}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
  
}

export default CurricurumTools;
