import bindAll from 'lodash.bindall';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
// import allSpriteLibraryContent from '../lib/libraries/sprites/all-sprites.json';
// import allSpriteLibraryContent from '../lib/libraries/sprites/all-sprites.json';
// import classMainSpriteLibraryContent from '../lib/libraries/sprites/classmode_1.json';
// import class2SpriteLibraryContent from '../lib/libraries/sprites/classmode_2.json';
// import class3SpriteLibraryContent from '../lib/libraries/sprites/classmode_3.json';
import allSpriteLibraryContent from '../lib/libraries/sprites/all-sprites.js';
import classMainSpriteLibraryContent from '../lib/libraries/sprites/classmode_1.js';
import class2SpriteLibraryContent from '../lib/libraries/sprites/classmode_2.js';
import class3SpriteLibraryContent from '../lib/libraries/sprites/classmode_3.js';
import randomizeSpritePosition from '../lib/randomize-sprite-position';

// default tags
// import spriteTags from '../lib/libraries/sprite-tags';

// pokemon tags
import spriteTags from '../lib/libraries/pokemon-sprite-tags';
import LibraryComponent from '../components/library/library.jsx';

const spriteContents = {
    CLASS_MAIN: classMainSpriteLibraryContent,
    CHARIZARD: class2SpriteLibraryContent,
    BOOK: class3SpriteLibraryContent,
    FREE: allSpriteLibraryContent
};

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Sprite',
        description: 'Heading for the sprite library',
        id: 'gui.spriteLibrary.chooseASprite'
    }
});

class SpriteLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
            'renameCostume'
        ]);
    }
    handleItemSelect (item) {
        // Randomize position of library sprite
        const renameItem = this.renameCostume(item);
        randomizeSpritePosition(renameItem);
        this.props.vm.addSprite(JSON.stringify(renameItem)).then(() => {
            this.props.onActivateBlocksTab();
        });
    }
    renameCostume (item) {
        const renameItem = _.cloneDeep(item);
        renameItem.costumes.map(costume => {
            costume.name = costume.name ?
                (typeof costume.name === 'string' ?
                    // Use the name if it is a string, else use formatMessage to get the translated name
                    costume.name : this.props.intl.formatMessage(costume.name.props)
                ).toLowerCase() :
                null;
            return costume;
        });
        return renameItem;
    }
    render () {
        return (
            <LibraryComponent
                data={spriteContents[this.props.selectedClassMode]}
                id="spriteLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
                isShowingBackButton
            />
        );
    }
}

SpriteLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    selectedClassMode: PropTypes.string
};


const mapStateToProps = state => ({
    selectedClassMode: state.scratchGui.selectedClassMode
});
const mapDispatchToProps = dispatch => (
    {}
);

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SpriteLibrary));
