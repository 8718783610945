import {defineMessages} from 'react-intl';

export default defineMessages({
    all: {
        defaultMessage: 'All',
        description: 'Tag for filtering a library for everything',
        id: 'gui.libraryTags.all'
    },
    tipsMovie: {
        defaultMessage: 'TipsMovie',
        description: 'Tag for filtering a library for TipsMovie',
        id: 'gui.libraryTags.tipsMovie'
    },
    basic: {
        defaultMessage: 'Basic',
        description: 'Tag for filtering a library for basic',
        id: 'gui.libraryTags.basic'
    },
    applied: {
        defaultMessage: 'Applied',
        description: 'Tag for filtering a library for applied',
        id: 'gui.libraryTags.applied'
    },
    scratchPokemonGame: {
        defaultMessage: 'ScratchPokemonGame',
        description: 'Tag for filtering a curricurum for scratch pokemon games',
        id: 'gui.libraryTags.scratchPokemonGame'
    },
    scratchPokemonGameMovie: {
        defaultMessage: 'ScratchPokemonGameMovie',
        description: 'Tag for filtering a curricurum for scratch pokemon games',
        id: 'gui.libraryTags.scratchPokemonGameMovie'
    },
    scratchPokemonGameCard: {
        defaultMessage: 'ScratchPokemonGameCard',
        description: 'Tag for filtering a curricurum for scratch pokemon games',
        id: 'gui.libraryTags.scratchPokemonGameCard'
    },
    flyCharizard: {
        defaultMessage: 'FlyCharizard',
        description: 'Tag for filtering a curricurum for fly charizard',
        id: 'gui.libraryTags.flyCharizard'
    },
    pokemonStoryMaking: {
        defaultMessage: 'PokemonStoryMaking',
        description: 'Tag for filtering a curricurum for pokemon story making',
        id: 'gui.libraryTags.pokemonStoryMaking'
    },
    help: {
        defaultMessage: 'Help',
        description: 'Tag for filtering a library for Help',
        id: 'gui.libraryTags.help'
    },
    pokemon: {
        defaultMessage: 'Pokemon',
        description: 'Tag for filtering a library for pokemon',
        id: 'gui.libraryTags.pokemon'
    },
    tools: {
        defaultMessage: 'Tools',
        description: 'Tag for filtering a library for pokemon tools',
        id: 'gui.libraryTags.tools'
    },
    effects: {
        defaultMessage: 'Effects',
        description: 'Tag for filtering a library for effects',
        id: 'gui.libraryTags.effects'
    },
    se: {
        defaultMessage: 'se',
        description: 'Tag for filtering a library for sound effects',
        id: 'gui.libraryTags.se'
    },
    bgms: {
        defaultMessage: 'BGM',
        description: 'Tag for filtering a library for bgms',
        id: 'gui.libraryTags.bgms'
    },
    others: {
        defaultMessage: 'Others',
        description: 'Tag for filtering a library for others',
        id: 'gui.libraryTags.others'
    }
});
