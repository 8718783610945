import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Rnd} from 'react-rnd';
import styles from './card-rnd.css';

import shrinkIcon from './icon--shrink.svg';
import expandIcon from './icon--expand.svg';

import rightArrow from './icon--next.svg';
import leftArrow from './icon--prev.svg';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import closeIcon from './icon--close.svg';

import fullViewIcon from './icon--fullview.svg';

// import ReactPlayer from 'react-player';


const CardHeader = (
    {
        onCloseCards,
        onShrinkExpandCards,
        onShowAll,
        onStartFullView,
        onEndFullView,
        totalSteps,
        step,
        expanded,
        isDeck
    }) => {
    const thisProps = {
        handleAllButtonClick: event => {
            onShowAll();
            event.stopPropagation();
        },
        handleShrinkExpandButtonClick: event => {
            onShrinkExpandCards();
            event.stopPropagation();
        },
        handleRemoveButtonClick: event => {
            onCloseCards();
            onEndFullView();
            event.stopPropagation();
        },
        handleFullViewButtonClick: event => {
            onStartFullView();
            event.stopPropagation();
        }
    };
    return (
        <div className={expanded ? styles.headerButtons : classNames(styles.headerButtons, styles.headerButtonsHidden)}>
            <div>
                <div
                    className={styles.allButton}
                    onClick={thisProps.handleAllButtonClick}
                    onTouchEnd={thisProps.handleAllButtonClick}
                >
                    <img
                        className={styles.helpIcon}
                        src={helpIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Hints"
                        description="Title for button to return to pokemon tutorials library"
                        id="gui.cards.all-pokemon-tutorials"
                    />
                </div>
            </div>
            {totalSteps > 1 ? (
                <div className={styles.stepsList}>
                    {Array(totalSteps).fill(0)
                        .map((_, i) => (
                            <div
                                className={i === step ? styles.activeStepPip : styles.inactiveStepPip}
                                key={`pip-step-${i}`}
                            />
                        ))}
                </div>
            ) : null}

            <div className={styles.headerButtonsRight}>
                {
                    isDeck && <div
                        className={styles.fullViewButton}
                        onClick={thisProps.handleFullViewButtonClick}
                        onTouchEnd={thisProps.handleFullViewButtonClick}
                    >
                        <img
                            className={styles.fullViewIcon}
                            draggable={false}
                            src={fullViewIcon}
                        />
                        <FormattedMessage
                            defaultMessage="Full View"
                            description="Title for button to full view how-to card"
                            id="gui.cards.fullView"
                        />
                    </div>
                }
                
                <div
                    className={styles.shrinkExpandButton}
                    onClick={thisProps.handleShrinkExpandButtonClick}
                    onTouchEnd={thisProps.handleShrinkExpandButtonClick}
                >
                    <img
                        draggable={false}
                        src={expanded ? shrinkIcon : expandIcon}
                    />
                    {expanded ?
                        <FormattedMessage
                            defaultMessage="Shrink"
                            description="Title for button to shrink how-to card"
                            id="gui.cards.shrink"
                        /> :
                        <FormattedMessage
                            defaultMessage="Expand"
                            description="Title for button to expand how-to card"
                            id="gui.cards.expand"
                        />
                    }
                </div>
                <div
                    className={styles.removeButton}
                    onClick={thisProps.handleRemoveButtonClick}
                    onTouchEnd={thisProps.handleRemoveButtonClick}
                >
                    <img
                        className={styles.closeIcon}
                        src={closeIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Close"
                        description="Title for button to close how-to card"
                        id="gui.cards.close"
                    />
                </div>
            </div>
        </div>
    );
};

CardHeader.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired,
    onShrinkExpandCards: PropTypes.func.isRequired,
    onStartFullView: PropTypes.func.isRequired,
    onEndFullView: PropTypes.func.isRequired,
    step: PropTypes.number,
    totalSteps: PropTypes.number,
    isDeck: PropTypes.bool
};

const CardFullView = ({image, onEndFullView}) => {
    const thisProps = {
        handleEndFullViewButtonClick: event => {
            onEndFullView();
            event.stopPropagation();
        }
    };
    let count = 0;

    useEffect(() => {
        const fullViewImage = document.getElementById('fullViewImage');
        fullViewImage.addEventListener('touchstart', onDoubleTappedFullView);
        return () => {
            fullViewImage.removeEventListener('touchstart', onDoubleTappedFullView);
        }
    }, []);

    const onDoubleTappedFullView = event => {
        if (!count) {
            ++count;
            setTimeout(() => {
                count = 0;
            }, 200);
        } else {
            event.preventDefault();
            count = 0;
            console.log('double tapped');
            thisProps.handleEndFullViewButtonClick(event);
        }
    };

    return (<Fragment>
        <div className={styles.fullViewContainer}>
            
            <div className={styles.fullViewImageContainer}>
                <img
                    className={styles.fullViewImage}
                    id = "fullViewImage"
                    draggable={false}
                    src={image}
                />
                <div
                    className={styles.fullViewCloseButton}
                    onClick={thisProps.handleEndFullViewButtonClick}
                >
                    <img
                        draggable={false}
                        src={closeIcon}
                    />
                </div>
            </div>
           
        </div>
    </Fragment>
    );
};

CardFullView.propTypes = {
    image: PropTypes.string.isRequired,
    onEndFullView: PropTypes.func.isRequired
};

class VideoStep extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            playing: null
        };
        this.handlePreventDefault = this.handlePreventDefault.bind(this);
    }
    handlePreventDefault (event) {
        event.preventDefault();
    }

    render () {
        return (
            <div className={styles.videoPlayerContainer}>
                {/* <ReactPlayer
                    url={this.props.video}
                    id="tipsPlay"
                    playing={this.props.expanded}
                    loop
                    controls
                    autoPlay
                    width="95%"
                    height="95%"
                    config={{file: {attributes: {controlsList: 'nodownload'}}}}
                    onContextMenu={this.handlePreventDefault}
                /> */}
            </div>
        );
    }
}

VideoStep.propTypes = {
    expanded: PropTypes.bool.isRequired,
    video: PropTypes.string.isRequired
};

const updateZoomInfo = event => (
    new Promise(resolve => {
        let clickX;
        let clickY;
        if (event.type === 'touchend') {
            
            // タッチイベントから座標を取得する
            clickX = event.changedTouches[0].pageX;
            clickY = event.changedTouches[0].pageY;
        } else {
            // マウスイベントから座標を取得する
            clickX = event.pageX;
            clickY = event.pageY;
        }

        // 要素の位置を取得
        const clientRect = event.currentTarget.getBoundingClientRect();
        const positionX = clientRect.left + window.pageXOffset;
        const positionY = clientRect.top + window.pageYOffset;

        // 要素内におけるクリック位置を計算
        const x = clickX - positionX;
        const y = clickY - positionY;
        return resolve({
            x: x,
            y: y,
            oldWidth: event.currentTarget.width,
            oldHeight: event.currentTarget.height
        });
    })
);

// 試験用
const offsetLinearZoomStyles = (x, y, oldWidth, oldHeight, zoomScale) => (
    new Promise(resolve => {
        const OFFSET_X = oldWidth * 0.3;
        const OFFSET_Y = oldHeight * 0.2;
        const fx = (((1 - zoomScale) * oldWidth) / (oldWidth - (2 * OFFSET_X))) * (x - OFFSET_X);
        const fy = (((1 - zoomScale) * oldHeight) / (oldHeight - (2 * OFFSET_Y))) * (y - OFFSET_Y);
        const newTop = Math.max(Math.min(0, fy), (1 - zoomScale) * oldHeight);
        const newLeft = Math.max(Math.min(0, fx), (1 - zoomScale) * oldWidth);
        // console.log(newTop, newLeft);
        return resolve({newTop: newTop, newLeft: newLeft});
    })
);
// 試験用
// const linearZoomStyles = (x, y, ZOOM_SCALE) => {
//    const newTop = ((1 - ZOOM_SCALE) * y);
//    const newLeft = ((1 - ZOOM_SCALE) * x);
//    return {newTop: newTop, newLeft: newLeft};
// };

const ImageStepZoomTest = ({title, image}) => {
    const ZOOM_SCALE = 2.5;
    
    const [zoomIn, setZoomIn] = useState({
        isZoomIn: false,
        x: 0,
        y: 0,
        oldWidth: null
    });
    const zoomStyles = (x, y, oldWidth, oldHeight) => (
        new Promise(async resolve => {
            const newHeight = oldHeight * ZOOM_SCALE;
            const newWidth = oldWidth * ZOOM_SCALE;
            // オフセットを考慮したズーム
            const {newTop, newLeft} = await offsetLinearZoomStyles(x, y, oldWidth, oldHeight, ZOOM_SCALE);

            // 線形ズーム
            // const {newTop, newLeft} = linearZoomStyles(x, y, ZOOM_SCALE);

            // 確認用
            // console.log(x, y, oldWidth, oldHeight, newWidth, newHeight, newTop, newLeft);
            return resolve({
                position: 'absolute',
                width: `${newWidth}px`,
                height: `${newHeight}px`,
                top: `${newTop}px`,
                left: `${newLeft}px`,
                border: '1px solid #ddd',
                borderRadius: '0.5rem',
                cursor: 'zoom-out'
            });
        })
        
    );

    // const normalStyles = {
    //    position: 'relative',
    //    maxWidth: '100%',
    //    maxHeight: '95%',
    //    border: '1px solid #ddd',
    //    borderRadius: '0.5rem',
    //    overflow: 'hidden',
    //    margin: '0'
    // };

    const absoluteStyles = {
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ddd',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        margin: '0',
        cursor: 'zoom-in'
    };

    const [zoomStyle, setZoomStyle] = useState(zoomStyles(0, 0));
    useEffect(() => {
        (async () => {
            if (zoomIn.isZoomIn) {
                const style = await zoomStyles(zoomIn.x, zoomIn.y, zoomIn.oldWidth, zoomIn.oldHeight);
                setZoomStyle(style);
            }
        })();
    }, [zoomIn.isZoomIn]
    );
    const thisProps = {
        handleContextMenu: event => event.preventDefault(),
        handleZoomIn: async event => {
            const {x, y, oldWidth, oldHeight} = await updateZoomInfo(event);
            setZoomIn({
                isZoomIn: !zoomIn.isZoomIn,
                x: x,
                y: y,
                oldWidth: oldWidth,
                oldHeight: oldHeight
            });
        }
    };

    return (
        <Fragment>
            <div className={styles.stepTitle}>
                {title}
            </div>
            <div className={styles.stepImageContainer}>
                <div className={styles.stepImageWrapper}>
                    
                    <img
                        /* className={zoomIn.isZoomIn ? styles.zoomStepImage : styles.stepImage}*/
                        style={zoomIn.isZoomIn ? zoomStyle : absoluteStyles}
                        draggable={false}
                        key={image} /* Use src as key to prevent hanging around on slow connections */
                        src={image}
                        onContextMenu={thisProps.handleContextMenu}
                        onClick={thisProps.handleZoomIn}
                        onTouchEnd={thisProps.handleZoomIn}
                    />
                    
                </div>
            </div>
        </Fragment>
    );
};

ImageStepZoomTest.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired
};

const ImageStep = ({title, image}) => {
    
    const thisProps = {
        handleContextMenu: event => event.preventDefault()
    };

    return (
        <Fragment>
            <div className={styles.stepTitle}>
                {title}
            </div>
            <div className={styles.stepImageContainer}>
                <img
                    className={styles.stepImage}
                    draggable={false}
                    key={image} /* Use src as key to prevent hanging around on slow connections */
                    src={image}
                    onContextMenu={thisProps.handleContextMenu}
                    onClick={thisProps.handleZoomIn}
                    onTouchEnd={thisProps.handleZoomIn}
                />
            </div>
        </Fragment>
    );
};

ImageStep.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired
};


const NextPrevButtons = ({isRtl, onNextStep, onPrevStep, expanded}) => {
    const thisProps = {
        handleNextButtonClick: event => {
            onNextStep();
            event.stopPropagation();
        },
        handlePrevButtonClick: event => {
            onPrevStep();
            event.stopPropagation();
        }
    };
    return (
        <Fragment>
            {onNextStep ? (
                <div>
                    <div className={expanded ? (isRtl ? styles.leftCard : styles.rightCard) : styles.hidden} />
                    <div
                        className={expanded ? (isRtl ? styles.leftButton : styles.rightButton) : styles.hidden}
                        onClick={thisProps.handleNextButtonClick}
                        onTouchEnd={thisProps.handleNextButtonClick}
                    >
                        <img
                            draggable={false}
                            src={isRtl ? leftArrow : rightArrow}
                        />
                    </div>
                </div>
            ) : null}
            {onPrevStep ? (
                <div>
                    <div className={expanded ? (isRtl ? styles.rightCard : styles.leftCard) : styles.hidden} />
                    <div
                        className={expanded ? (isRtl ? styles.rightButton : styles.leftButton) : styles.hidden}
                        onClick={thisProps.handlePrevButtonClick}
                        onTouchEnd={thisProps.handlePrevButtonClick}
                    >
                        <img
                            draggable={false}
                            src={isRtl ? rightArrow : leftArrow}
                        />
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

NextPrevButtons.propTypes = {
    expanded: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    onNextStep: PropTypes.func,
    onPrevStep: PropTypes.func
};

const PreviewsStep = ({deckIds, content, onActivateDeckFactory, onShowAll}) => {
    const thisProps = {
        handleSeeAllButtonClick: event => {
            onShowAll();
            event.stopPropagation();
        }
    };
    return (
        <Fragment>
            <div className={styles.stepTitle}>
                <FormattedMessage
                    defaultMessage="More things to try!"
                    description="Title card with more things to try"
                    id="gui.cards.more-things-to-try"
                />
            </div>
            <div className={styles.decks}>
                {deckIds.slice(0, 2).map(id => (
                    <div
                        className={styles.deck}
                        key={`deck-preview-${id}`}
                        onClick={onActivateDeckFactory(id)}
                        onTouchEnd={onActivateDeckFactory(id)}
                    >
                        <img
                            className={styles.deckImage}
                            draggable={false}
                            src={content[id].img}
                        />
                        <div className={styles.deckName}>{content[id].name}</div>
                    </div>
                ))}
            </div>
            <div className={styles.seeAll}>
                <div
                    className={styles.seeAllButton}
                    onClick={thisProps.handleSeeAllButtonClick}
                    onTouchEnd={thisProps.handleSeeAllButtonClick}
                >
                    <FormattedMessage
                        defaultMessage="See more"
                        description="Title for button to see more in how-to library"
                        id="gui.cards.see-more"
                    />
                </div>
            </div>
        </Fragment>
    );
};

PreviewsStep.propTypes = {
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }).isRequired,
    deckIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired
};

const Cards = props => {
    const {
        activeDeckId,
        content,
        // dragging,
        isRtl,
        // locale,
        onActivateDeckFactory,
        onCloseCards,
        onShrinkExpandCards,
        onDrag,
        onStartDrag,
        onEndDrag,
        onShowAll,
        onNextStep,
        onPrevStep,
        showVideos,
        step,
        expanded,
        isFullView,
        onStartFullView,
        onEndFullView,
        ...posProps
    } = props;
    let {x, y} = posProps;

    if (activeDeckId === null) return;

    const cardHorizontalDragOffset = 400; // ~80% of card width
    const cardVerticalDragOffset = expanded ? 257 : 0; // ~80% of card height, if expanded
    const menuBarHeight = 48;

    if (x === 0 && y === 0) {
        x = 0;
        y = window.innerHeight - 400 - menuBarHeight;
    }

    const steps = content[activeDeckId].steps;
    const [imageLoaded, setImageLoaded] = useState(null);

    
    const loadingTranslateImage = async () => {
        const WAIT_MSECS = [1];
        for (let i = 0; i < WAIT_MSECS.length; i++) {
            await new Promise(resolve => setTimeout(resolve, WAIT_MSECS[i]));

            const loadingImage = steps[step].image; // translateImage(steps[step].image, locale);
            if (typeof loadingImage !== 'undefined') {
                setImageLoaded(loadingImage);
                return loadingImage;
            }
        }
        setImageLoaded(false);
        throw new Error('Image translation failed');
    };

    useEffect(() => {
        const loadImageAsync = async () => {
            try {
                if (typeof steps[step].deckIds === 'undefined') {
                    await loadingTranslateImage();
                }
                
            } catch (error) {
                throw new Error('Image translation failed');
            }
        };
        loadImageAsync();
    }, [imageLoaded, step, steps]);


    const [rndSize, setRndSize] = useState({width: 540, height: 400});
    
    const onResizeStop = (e, direction, ref) => {
        setRndSize({
            width: ref.style.width,
            height: ref.style.height
        });
    };

    return (
        <Fragment>
            {/* Custom overlay to act as the bounding parent for the draggable, using values from above */}
            <div
                className={styles.cardContainerOverlay}
                style={{
                    width: `${window.innerWidth + (2 * cardHorizontalDragOffset)}px`,
                    height: `${window.innerHeight - menuBarHeight + cardVerticalDragOffset}px`,
                    top: `${menuBarHeight}px`,
                    left: `${-cardHorizontalDragOffset}px`
                }}
            >
                <div
                    className={styles.draggableLimit}
                >
                
                    <Rnd
                        bounds="parent"
                        default={{
                            x: x,
                            y: y,
                            width: rndSize.width,
                            height: rndSize.height
                        }}
                        enableResizing={expanded ? {
                            top: true,
                            right: true,
                            bottom: true,
                            left: true,
                            topRight: true,
                            bottomRight: true,
                            bottomLeft: true,
                            topLeft: true
                        } : {
                            top: false,
                            right: true,
                            bottom: false,
                            left: true,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false
                        }}
                        onDrag={onDrag}
                        onDragStart={onStartDrag}
                        onDragStop={onEndDrag}
                        onResizeStop={onResizeStop}
                        size={{width: rndSize.width, height: expanded ? rndSize.height : '63px'}}
                        className={expanded ? styles.cardContainer : styles.cardContainerShrinked}
                    >
                        <div className={expanded ? styles.card : styles.cardHidden}>
                            <CardHeader
                                expanded={expanded}
                                step={step}
                                totalSteps={steps.length}
                                onCloseCards={onCloseCards}
                                onShowAll={onShowAll}
                                onShrinkExpandCards={onShrinkExpandCards}
                                onStartFullView={onStartFullView}
                                onEndFullView={onEndFullView}
                                isDeck={typeof steps[step].deckIds === 'undefined'}
                            />
                            <div className={expanded ? styles.stepBody : styles.hidden}>
                                {steps[step].deckIds ? (
                                    <PreviewsStep
                                        content={content}
                                        deckIds={steps[step].deckIds}
                                        onActivateDeckFactory={onActivateDeckFactory}
                                        onShowAll={onShowAll}
                                    />
                                ) : (
                                    steps[step].video ? (
                                        showVideos && (
                                            <VideoStep
                                                video={steps[step].video}
                                                // expanded={expanded} // when using react player
                                                onShrinkExpandCards={onShrinkExpandCards}
                                            />
                                        )
                                    ) : (
                                        <>
                                            {imageLoaded && (
                                                <ImageStepZoomTest
                                                    image={imageLoaded}
                                                    title={steps[step].title}
                                                />
                                            )}
                                        </>

                                    )
                                )}
                            </div>
                            <NextPrevButtons
                                expanded={expanded}
                                isRtl={isRtl}
                                onNextStep={step < steps.length - 1 ? onNextStep : null}
                                onPrevStep={step > 0 ? onPrevStep : null}
                            />
                        </div>
                    </Rnd>
                </div>
            
            </div>
            {
                isFullView && (
                    <CardFullView
                        image={imageLoaded}
                        onEndFullView={onEndFullView}
                    />
                )
            }
        </Fragment>
    );
};

Cards.propTypes = {
    activeDeckId: PropTypes.string.isRequired,
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }),
    dragging: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onCloseCards: PropTypes.func.isRequired,

    onDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onShowAll: PropTypes.func,
    onShrinkExpandCards: PropTypes.func.isRequired,
    onStartDrag: PropTypes.func,
    showVideos: PropTypes.bool,
    step: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,

    isFullView: PropTypes.bool,
    onStartFullView: PropTypes.func,
    onEndFullView: PropTypes.func
};

Cards.defaultProps = {
    showVideos: true
};

export {
    Cards as default,
    // Others exported for testability
    ImageStepZoomTest,
    VideoStep
};
