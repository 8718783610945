/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import Modal from '../../../containers/noheader-modal.jsx';
import classNames from 'classnames';

import PokemonstartkitLogo from '../pokemonstartkit-logo.jsx';
import pokemonstartkitStyles from '../pokemonstartkit.css';
import styles from './select-game.css';
import bindAll from 'lodash.bindall';

import {config} from 'dotenv';
config();
const env = process.env;
const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;

const scratchThumbnail = env.DEPLOY_MODE === 'local' ?
    `${env.LOCAL_API_SERVER}ui/pikachu-programming-logo.png` :
    `${AWS_S3_URL}assets/ui/pikachu-programming-logo.png`;

const pcTrainingThumbnail = env.DEPLOY_MODE === 'local' ?
    `${env.LOCAL_API_SERVER}ui/pc-training-logo.png` :
    `${AWS_S3_URL}assets/ui/pc-training-logo.png`;

const zaidanLogo = env.DEPLOY_MODE === 'local' ?
    `${env.LOCAL_API_SERVER}ui/logo-zaidan.png` :
    `${AWS_S3_URL}assets/ui/logo-zaidan.png`;


import PokemonstartkitButtonComponent from '../pokemonstartkit-button/pokemonstartkit-button.jsx';
import {connectDB} from '../../../lib/indexedDB.jsx';

const VERSION_NUMBER = env.VERSION;

const DB_URL = env.ENV_MODE === 'develop' ? env.DEV_DB_URL : env.PRD_DB_URL;
const deleteURL = `${DB_URL}delete/user/`;

class SelectGame extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectPCTraining',
            'handleDeleteUserData',
            'handleRequestDeleteUserData',
            'onDeleteIndexedDB',
            'handlePreventEvent'
        ]);

        this.state = {
            loaded: false
        };
    }

    componentDidMount () {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.onSetImageToCanvas('scratchThumbnail', scratchThumbnail);
        this.onSetImageToCanvas('pcTrainingThumbnail', pcTrainingThumbnail);

    }

    onSetImageToCanvas (id, src) {
        const image = new Image();
        image.onload = () => {
            const canvas = document.getElementById(id);
            if (!canvas) return;
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            const aspectRate = image.height / image.width;
            ctx.drawImage(image, 0, (1 - aspectRate) * image.width / 2);
        };
        image.src = src;
    }

    handleRequestDeleteUserData (dataId) {
        fetch(deleteURL,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    userId: dataId
                })
            }).catch(() => null)
            .then(() => {
                this.onDeleteIndexedDB();
            });
    }

    onDeleteIndexedDB () {
        /*
        const delRequest = indexedDB.deleteDatabase('scratchGui');
        delRequest.onsuccess = () => {
            alert('正常に削除されました');
            window.location.reload();
        };
        delRequest.onerror = () => {
            alert('エラーが発生しました');
            window.location.reload();
        };
        delRequest.onblocked = () => {
            // alert('接続がブロックされました');
            alert('正常に削除されました');
            window.location.reload();
        };
        */
        return new Promise(async (resolve, reject) => {
            const db = await connectDB();

            const clearPlayerDataRequest = db.transaction('playerData', 'readwrite').objectStore('playerData')
                .clear();
            const clearPlayerPromise = new Promise(resolve => clearPlayerDataRequest.onsuccess = event => resolve());
            const clearProjectDataRequest = db.transaction('projectData', 'readwrite').objectStore('projectData')
                .clear();
            const clearProjectPromise = new Promise(resolve => clearProjectDataRequest.onsuccess = event => resolve());

            await Promise.all([clearPlayerPromise, clearProjectPromise]);

            await window.ownAlert(<p><ruby>正常<rt>せいじょう</rt></ruby>に<ruby>削除<rt>さくじょ</rt></ruby>されました</p>);
            window.location.reload();
            return resolve();
        });
    }

    handleDeleteUserData () {
        return new Promise(async (resolve, reject) => {
            const conf = await window.ownConfirm(<p><ruby>本当<rt>ほんとう</rt></ruby>に<ruby>削除<rt>さくじょ</rt></ruby>しますか？</p>);
            
            if (conf) {
                const db = await connectDB();
                const objstore = db.transaction(['playerData'], 'readonly').objectStore('playerData');
                const idRequest = objstore.get('UserId');
                idRequest.onsuccess = event => {
                    const result = event.target.result;
                    if (typeof result === 'undefined') {
                        this.onDeleteIndexedDB();
                    } else {
                        this.handleRequestDeleteUserData(event.target.result.data);
                    }
                    resolve();
                };
                idRequest.onerror = error => reject(error);

            }
        });


    }

    handleSelectPCTraining (){
        this.props.handleSelectPCTraining();
        // this.props.onRequestClose();
    }

    handlePreventEvent (event) {
        event.preventDefault();
    }

    render () {
        return (
            <Modal
                fullScreen
                id={'SelectGame'}
            >
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <PokemonstartkitLogo />
                        <div className={pokemonstartkitStyles.pokemonstartkitContainer}>
                            <div className={styles.gameItems}>
                                <div
                                    className={styles.gameItem}
                                    onClick={this.props.handleSelectScratch}
                                >
                                    <div className={styles.gameItemImgBack}>
                                        <div className={styles.gameItemImg}>
                                            {
                                                // <img
                                                //    src={scratchThumbnail}
                                                //    onContextMenu={this.handlePreventEvent}
                                                //    draggable={false}
                                                // />
                                                <canvas
                                                    id="scratchThumbnail"
                                                    onContextMenu={this.handlePreventEvent}
                                                    draggable={false}
                                                />
                                            }
                                            <h3>
                                                <span>{'ビジュアルプログラミング'}</span>
                                                <span><ruby>{'学習'}<rt>{'がくしゅう'}</rt></ruby>{'が'}<br /></span>
                                                <span>{'できる'}<ruby>{'教材'}<rt>{'きょうざい'}</rt></ruby>{'です'}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={styles.gameItem}
                                    onClick={this.handleSelectPCTraining}
                                >
                                    <div className={styles.gameItemImgBack}>
                                        <div className={styles.gameItemImg}>
                                            {
                                                /*
                                                <img
                                                    src={pcTrainingThumbnail}
                                                    onContextMenu={this.handlePreventEvent}
                                                    draggable={false}
                                                />
                                                */
                                                <canvas
                                                    id="pcTrainingThumbnail"
                                                    onContextMenu={this.handlePreventEvent}
                                                    draggable={false}
                                                />
                                            }

                                            <h3>
                                                <span>{'キーボード・'}<ruby>{'端末操作'}<rt>{'たんまつそうさ'}</rt></ruby>{'の'}</span>
                                                <span><ruby>{'練習'}<rt>{'れんしゅう'}</rt></ruby>{'ができる'}</span>
                                                <span><ruby>{'教材'}<rt>{'きょうざい'}</rt></ruby>{'です'}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className={pokemonstartkitStyles.pokemonstartkitContainer}>
                            <PokemonstartkitButtonComponent
                                onClick={this.handleDeleteUserData}
                                enabled
                                autoWidthMode
                            >
                                <span><ruby>{'先生/児童'}<rt>{'せんせい じどう'}</rt></ruby>{'をえらびなおすときにクリック'}</span><br />
                                <span>プログラミングでポケモンをうごかしてみようの</span><br />
                                <span>セーブデータは すべて</span>
                                <span><ruby>削除<rt>さくじょ</rt></ruby>されます</span>
                            </PokemonstartkitButtonComponent>
                        </div>
                            
                        <div className={classNames(styles.footer)}>
                            <p className={styles.versionText}>{`version:${VERSION_NUMBER}`}</p>
                            
                            <div className={styles.pokemonCaption}>
                                
                                <div className={styles.footerLogoWrapper}>
                                    <img
                                        src={zaidanLogo}
                                        onContextMenu={this.handlePreventEvent}
                                        draggable={false}
                                    />
                                </div>
                                <div className={styles.footerTextWrapper}>
                                    <p className={styles.footerText}>©2024 Pokémon. ©1995-2024 Nintendo/Creatures Inc./GAME FREAK inc. ポケットモンスター・ポケモン・Pokémonは任天堂・クリーチャーズ・ゲームフリークの登録商標です。</p>
                                </div>
                            </div>
                            <div className={styles.footerTextWrapper}><p className={styles.footerText}> Scratchは、MITメディア・ラボのライフロング・キンダーガーテン・グループの協力により、Scratch財団が進めているプロジェクトです。
                                <br />
                                <a
                                    href="https://scratch.mit.edu"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.linkInline}
                                >https://scratch.mit.edu</a> から自由に入手できます。</p></div>
                        </div>
                    </div>
                        
                </div>
                {
                    env.ENV_MODE === 'develop' &&
                    <div className={styles.developNum}>
                        開発用<br />
                        最終更新：
                        {env.DEPLOY_DATETIME}
                    </div>
                }
            </Modal>
        );
    }
}

SelectGame.propTypes = {
    handleSelectScratch: PropTypes.func,
    handleSelectPCTraining: PropTypes.func
};

export default injectIntl(SelectGame);
