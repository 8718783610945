import {config} from 'dotenv';
config();
const env = process.env;
const AWS_S3_URL = env.ENV_MODE === 'develop' ? env.ORIGIN_DEV_URL : env.ORIGIN_PRD_URL;
const ASSETS_URL = env.DEPLOY_MODE === 'local' ? `${env.LOCAL_API_SERVER}tips` : `${AWS_S3_URL}assets/tips`;

export default {
    help_basic_1: {
        name: 'モンスターボールを動かそう',
        tags: ['basic'],
        img: `${ASSETS_URL}/help-assets/basic_1/basic_1_1.png`,
        steps: [
            {
                title: '1-1 「旗が押されたとき」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_1.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_1.png`
            }, {
                title: '1-2 「ずっと」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_2.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_2.png`
            }, {
                title: '1-3 「最前面へ移動する」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_3.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_3.png`
            }, {
                title: '1-4 「どこかの場所へ行く」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_4.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_4.png`
            }, {
                title: '2 ▽の部分をクリックし、「マウスのポインター」をクリック',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_5.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_5.png`
            }, {
                title: '3-1 くぼみに合わせてブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_6.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_6.png`
            }, {
                title: '3-2 同じようにつなげる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_7.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_7.png`
            }, {
                title: '3-3 つなげたブロックをひとつにまとめる',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_8.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_8.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_8.png`
            }, {
                title: '4　スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/basic_1/basic_1_9.png`,
                video: `${ASSETS_URL}/help-assets/basic_1/basic_1_9.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_1/b_help_1_9.png`
            }
        ],
        urlId: 'basic_2',
        useVideo: false
    },
    help_basic_2: {
        name: 'ピカチュウを動かそう',
        tags: ['basic'],
        img: `${ASSETS_URL}/help-assets/basic_2/basic_2_1.png`,
        steps: [
            {
                title: '1 3つのブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_2/basic_2_1.png`,
                video: `${ASSETS_URL}/help-assets/basic_2/basic_2_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_2/b_help_2_1.png`
            }, {
                title: '2 「1秒待つ」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_2/basic_2_2.png`,
                video: `${ASSETS_URL}/help-assets/basic_2/basic_2_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_2/b_help_2_2.png`
            }, {
                title: '3-1 くぼみに合わせてブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/basic_2/basic_2_3.png`,
                video: `${ASSETS_URL}/help-assets/basic_2/basic_2_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_2/b_help_2_3.png`
            }, {
                title: '3-2 同じようにつなげる',
                image: `${ASSETS_URL}/help-assets/basic_2/basic_2_4.png`,
                video: `${ASSETS_URL}/help-assets/basic_2/basic_2_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_2/b_help_2_4.png`
            }, {
                title: '3-3 同じようにつなげる',
                image: `${ASSETS_URL}/help-assets/basic_2/basic_2_5.png`,
                video: `${ASSETS_URL}/help-assets/basic_2/basic_2_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_2/b_help_2_5.png`
            }
        ],
        urlId: 'basic_3',
        useVideo: false
    },
    help_basic_3: {
        name: 'ピカチュウをゲットしよう',
        tags: ['basic'],
        img: `${ASSETS_URL}/help-assets/basic_3/basic_3_1.png`,
        steps: [
            {
                title: '1-1 「表示する」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_1.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_1.png`
            }, {
                title: '1-2 「隠す」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_2.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_2.png`
            }, {
                title: '1-3 「もし～なら」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_3.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_3.png`
            }, {
                title: '1-4 「マウスのポインターに触れた」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_4.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_4.png`
            }, {
                title: '2 ▽の部分をクリックし、「モンスターボール」をクリック',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_5.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_5.png`
            }, {
                title: '3 ブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_6.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_6.png`
            }, {
                title: '4 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/basic_3/basic_3_7.png`,
                video: `${ASSETS_URL}/help-assets/basic_3/basic_3_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/basic_3/b_help_3_7.png`
            }
        ],
        urlId: 'hint_A',
        useVideo: false
    },
    help_hint_A: {
        name: 'A. ポケモンの数をふやす',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_A/hint_A_1.png`,
        steps: [
            {
                title: '1 ピカチュウを複製する',
                image: `${ASSETS_URL}/help-assets/hint_A/hint_A_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_A/hint_A_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_A/b_help_A_1.png`
            }, {
                title: '2 「コスチューム」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_A/hint_A_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_A/hint_A_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_A/b_help_A_2.png`
            }, {
                title: '3 「コスチュームを選ぶ」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_A/hint_A_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_A/hint_A_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_A/b_help_A_3.png`
            }, {
                title: '4  登場させたいポケモンをクリック',
                image: `${ASSETS_URL}/help-assets/hint_A/hint_A_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_A/hint_A_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_A/b_help_A_4.png`
            }, {
                title: '5 「コード」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_A/hint_A_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_A/hint_A_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_A/b_help_A_5.png`
            }
        ],
        urlId: 'hint_B',
        useVideo: false
    },
    help_hint_B: {
        name: 'B. 背景をつける',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_B/hint_B_1.png`,
        steps: [
            {
                title: '1 「背景を選ぶ」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_B/hint_B_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_B/hint_B_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_B/b_help_B_1.png`
            }, {
                title: '2 背景にしたい画像をクリック',
                image: `${ASSETS_URL}/help-assets/hint_B/hint_B_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_B/hint_B_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_B/b_help_B_2.png`
            }, {
                title: '3 選んだ背景になります',
                image: `${ASSETS_URL}/help-assets/hint_B/hint_B_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_B/hint_B_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_B/b_help_B_3.png`
            }
        ],
        urlId: 'hint_C',
        useVideo: false
    },
    help_hint_C: {
        name: 'C. ポーズをかえる',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_C/hint_C_1.png`,
        steps: [
            {
                title: '1 「コスチューム」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_1.png`
            }, {
                title: '2 コスチュームを変えるポケモンをクリック',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_2.png`
            }, {
                title: '3 「コスチュームを選ぶ」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_3.png`
            }, {
                title: '4  変えたいポーズをクリック',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_4.png`
            }, {
                title: '5 「コード」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_5.png`
            }, {
                title: '6-1 「次のコスチュームにする」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_6.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_6.png`
            }, {
                title: '6-2 ブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_7.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_7.png`
            }, {
                title: '7 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_C/hint_C_8.png`,
                video: `${ASSETS_URL}/help-assets/hint_C/hint_C_8.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_C/b_help_C_8.png`
            }
        ],
        urlId: 'hint_D',
        useVideo: false
    },
    help_hint_D: {
        name: 'D. 効果音を出す',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_D/hint_D_1.png`,
        steps: [
            {
                title: '1 「音」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_1.png`
            }, {
                title: '2 「音を選ぶ」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_2.png`
            }, {
                title: '3 出したい音をクリック',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_3.png`
            }, {
                title: '4 「コード」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_4.png`
            }, {
                title: '5 「終わるまで～の音を鳴らす」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_5.png`
            }, {
                title: '6 ブロックをつなげる、出したい音が選ばれていることをチェック',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_6.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_6.png`
            }, {
                title: '7 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_D/hint_D_7.png`,
                video: `${ASSETS_URL}/help-assets/hint_D/hint_D_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_D/b_help_D_7.png`
            }
        ],
        urlId: 'hint_E',
        useVideo: false
    },
    help_hint_E: {
        name: 'E. つかまえた数をスコアとして表示する',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_E/hint_E_1.png`,
        steps: [
            {
                title: '1 「コード」のタブをクリック',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_1.png`
            }, {
                title: '2-1 「変数を0にする」のブロックを持ってきて',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_2.png`
            }, {
                title: '2-2 ▽の部分をクリックし、「変数名を変更」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_3.png`
            }, {
                title: '3 四角の中をクリックし、「スコア」と入力',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_4.png`
            }, {
                title: '4-1 このようにブロックをならべる',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_5.png`
            }, {
                title: '4-2 このようにブロックをならべる',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_6.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_6.png`
            }, {
                title: '4-3 このようにブロックをならべる',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_7.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_7.png`
            }, {
                title: '5 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_E/hint_E_8.png`,
                video: `${ASSETS_URL}/help-assets/hint_E/hint_E_8.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_E/b_help_E_8.png`
            }
        ],
        urlId: 'hint_F',
        useVideo: true
    },
    help_hint_F: {
        name: 'F. マウスでクリックしてつかまえる',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_F/hint_F_1.png`,
        steps: [
            {
                title: '1-1 「～かつ～」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_F/hint_F_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_F/hint_F_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_F/b_help_F_1.png`
            }, {
                title: '1-2 「マウスが押された」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_F/hint_F_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_F/hint_F_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_F/b_help_F_2.png`
            }, {
                title: '1-3 ブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/hint_F/hint_F_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_F/hint_F_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_F/b_help_F_3.png`
            }, {
                title: '2 ブロックどうしを組み合わせる',
                image: `${ASSETS_URL}/help-assets/hint_F/hint_F_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_F/hint_F_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_F/b_help_F_4.png`
            }, {
                title: '3 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_F/hint_F_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_F/hint_F_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_F/b_help_F_5.png`
            }
        ],
        urlId: 'hint_G',
        useVideo: true
    },
    help_hint_G: {
        name: 'G. あるポケモンの出現率を下げる',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_G/hint_G_1.png`,
        steps: [
            {
                title: '1 出現率を下げるポケモンをクリック',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_1.png`
            }, {
                title: '2 ブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_2.png`
            }, {
                title: '3 「〇秒待つ」ブロックの秒数に「10」と入力',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_3.png`
            }, {
                title: '4-1 「すべてを止める」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_4.png`
            }, {
                title: '4-2 ▽の部分をクリックし、「スプライトの他のスクリプトを止める」をクリック',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_5.png`
            }, {
                title: '5 ブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_6.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_6.png`
            }, {
                title: '6 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_G/hint_G_7.png`,
                video: `${ASSETS_URL}/help-assets/hint_G/hint_G_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_G/b_help_G_7.png`
            }
        ],
        urlId: 'hint_H',
        useVideo: true
    },
    help_hint_H: {
        name: 'H. 10ぴきつかまえたらコメントを出す',
        tags: ['applied'],
        img: `${ASSETS_URL}/help-assets/hint_H/hint_H_1.png`,
        steps: [
            {
                title: '1 [ヒントE]を行いスコアを表示させる',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_1.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_1.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_1.png`
            }, {
                title: '2 [ヒントA]を行いポケモンを10ぴきにふやす',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_2.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_2.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_2.png`
            }, {
                title: '3 モンスターボールをクリック',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_3.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_3.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_3.png`
            }, {
                title: '4-1 「こんにちは!と言う」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_4.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_4.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_4.png`
            }, {
                title: '4-2 「こんにちは!」の部分をクリックし、変えたい文字を入力する',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_5.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_5.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_5.png`
            }, {
                title: '5 「〇=50」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_6.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_6.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_6.png`
            }, {
                title: '6 「スコア」のブロックを持ってくる',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_7.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_7.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_7.png`
            }, {
                title: '7 ブロックをつなげて「50」の部分をクリックし「10」を入力する',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_8.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_8.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_8.png`
            }, {
                title: '8 ブロックをつなげる',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_9.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_9.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_9.png`
            }, {
                title: '9 スタートボタンをおしてためしてみよう',
                image: `${ASSETS_URL}/help-assets/hint_H/hint_H_10.png`,
                video: `${ASSETS_URL}/help-assets/hint_H/hint_H_10.mp4`,
                backImage: `${ASSETS_URL}/help-assets/hint_H/b_help_H_10.png`
            }
        ],
        urlId: 'None',
        useVideo: false
    }

};
