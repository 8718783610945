import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import Modal from '../../../containers/noheader-modal.jsx';
// import Spinner from '../../spinner/spinner.jsx';
import LoadingAnimation from '../../loading-animation/loading-animation.jsx';
import styles from './class-mode-ui.css';
import LoadUI from './classmode-load-ui/load-ui.jsx';
import {setAutosaveState} from '../../../reducers/autosave-state';
import {setProjectID} from '../../../reducers/project-id';

import {ExtraTextCard} from '../../text-card/text-card.jsx';

import {data} from '../../../lib/curriculums/data.jsx';


const curriculumList = data;
const classModeTitle = <p><ruby>授業<rt>じゅぎょう</rt></ruby >モード</p>;
const classModeAutosaveToggle = 'classmodeToggleInput';

class ClassModeUi extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelect',
            'handleCloseLoadUI',
            'handleSelectBackButton',
            'handleSelectNewgame',
            'handleSelectContinue'
        ]);

        this.state = {
            selectId: this.props.isSelectedClassModeId,
            isSelected: this.props.isSelectedClassModeId !== null,
            isLoaded: false,
            isLoadedDB: true,
            isError: false
        };
    }

    handleSelect (id) {
        // console.log(`class${id}`);
        this.setState({
            selectId: id,
            isSelected: true
        });
    }

    handleCloseLoadUI () {
        this.props.onRequestClose();
    }

    handleSelectBackButton () {
        this.setState({
            isSelected: false
        });
    }

    handleSelectNewgame () {
        this.props.onSetSelectedClassMode(curriculumList[this.state.selectId].mode);
        this.loadClassProject(true);

    }

    handleSelectContinue () {
        this.props.onSetSelectedClassMode(curriculumList[this.state.selectId].mode);
        this.loadClassProject(false);
    }

    handlePreventDragDropRightClick = event => {
        event.preventDefault();
    };

    loadClassProject (isNewGame = true) {
        if (!this.state.selectId === null) return;

        this.props.onSetAutosave();
        const element = document.getElementById(classModeAutosaveToggle);
        if (element) {
            this.props.onSetAutosaveState(element.checked);
        }

        this.setState({isLoaded: true});
        const classmode = curriculumList[this.state.selectId];
        this.props.onSetProjectID(classmode.id);

        return new Promise(async (resolve, reject) => {
            try {
                await this.props.onStartLoadingProject(classmode.id, classmode.name, isNewGame);
                // await this.props.onSaveProject(classmode.id);
                this.handleCloseLoadUI();
                resolve();
            } catch (err) {
                // alert('ロードに失敗しました');
                await window.ownAlert(
                    <p>
                        データのロードに<ruby>失敗<rt>しっぱい</rt></ruby>しました。
                        インターネットに<ruby>接続<rt>せつぞく</rt></ruby>していることをご<ruby>確認<rt>かくにん</rt></ruby>ください。
                    </p>
                );
                this.setState({
                    isLoaded: false
                });
                
                return reject(err);
            }
        });
    }


    render () {
        return (
            <Modal
                fullScreen
                id={'ClassMode'}
                contentLabel={this.state.isLoaded ? <>ロード<ruby>中<rt>ちゅう</rt></ruby></> : classModeTitle}
                onRequestClose={this.props.onSelectBackButton}
                isShowingBackButton={!this.state.isLoaded || this.state.isError}
                header
                scratchPage
            >
                <div className={styles.back}>
                    {
                        this.state.isLoaded ?
                            <LoadingAnimation /> :
                            (this.state.isSelected ?
                                <div className={styles.game}>
                                    <LoadUI
                                        id={'class-load-ui'}
                                        onRequestClose={this.handleCloseLoadUI}
                                        onSelectBackButton={this.handleSelectBackButton}
                                        onSelectedNewgame={this.handleSelectNewgame}
                                        onSelectedContinue={this.handleSelectContinue}
                                        selectId={this.state.selectId}
                                        toggleId={classModeAutosaveToggle}
                                    />
                                </div> :
                                <div className={styles.game}>
                                    <div className={styles.classmodeBody}>
                                        {curriculumList.map(
                                            curriculumData => {
                                                const index = curriculumData.id.slice(-1) - 1;
                                                return (
                                                    <ExtraTextCard
                                                        key={curriculumData.id}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        onClick={() => this.handleSelect(index)}
                                                        disabled={!curriculumData.implemented}
                                                    >
                                                        <h3 className={classNames(styles.titleText, styles.highlighter)}>
                                                            <span><b>{curriculumData.titleElement}</b></span>
                                                        </h3>
                                                        <img
                                                            className={styles.classImage}
                                                            src={curriculumData.image}
                                                            onDragStart={this.handlePreventDragDropRightClick}
                                                        />
                                                        <table
                                                            border="none"
                                                            className={styles.tableClass}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td className={styles.index}><b><ruby>学年<rt>がくねん</rt></ruby></b></td>
                                                                    <td><b>{curriculumData.descriptionTableElements.target}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={styles.index}><b><ruby>科目<rt>かもく</rt></ruby></b></td>
                                                                    <td><b>{curriculumData.descriptionTableElements.subject}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={styles.index}><b><ruby>時間<rt>じかん</rt></ruby></b></td>
                                                                    <td><b>{curriculumData.descriptionTableElements.time}</b></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={styles.index}><b><ruby>目標<rt>もくひょう</rt></ruby></b></td>
                                                                    <td><b>{curriculumData.descriptionTableElements.goal}</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </ExtraTextCard>
                                                );
                                            }
                                        )
                                        }
                                            
                                    </div>
                                </div>

                            )
                    }
                </div>

            </Modal>
        );
    }
}

ClassModeUi.propTypes = {
/*    /intl: intlShape.isRequired,*/
    onRequestClose: PropTypes.func,
    onSelectBackButton: PropTypes.func,
    onStartLoadingProject: PropTypes.func,
    // onSaveProject: PropTypes.func,
    onSetAutosave: PropTypes.func,

    onSetAutosaveState: PropTypes.func,
    onSetProjectID: PropTypes.func,
    onSetSelectedClassMode: PropTypes.func,
    isSelectedClassModeId: PropTypes.number

};

const mapStateToProps = state => ({
    projectChanged: state.scratchGui.projectChanged
});
const mapDispatchToProps = dispatch => (
    {
        onSetProjectID: name => dispatch(setProjectID(name)),
        onSetAutosaveState: state => dispatch(setAutosaveState(state)),
    }
);

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClassModeUi));
