/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {FormattedMessage} from 'react-intl';

export default
[
    {
        name: 'ピカチュウ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'a7e8c3b3efb16d66d53bc86b6f1c60a3',
                name: 'ピカチュウ1',
                bitmapResolution: 2,
                md5ext: 'a7e8c3b3efb16d66d53bc86b6f1c60a3.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '729c2829be2f872459b1750a781d5c16',
                name: 'ピカチュウ2',
                bitmapResolution: 2,
                md5ext: '729c2829be2f872459b1750a781d5c16.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ3',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'cb5f29d94faf871ef8c5bc92e48a2d14',
                name: 'ピカチュウ3',
                bitmapResolution: 2,
                md5ext: 'cb5f29d94faf871ef8c5bc92e48a2d14.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ4',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'f2a6e722bfb7117cbdbd4d8a7bef11ce',
                name: 'ピカチュウ4',
                bitmapResolution: 2,
                md5ext: 'f2a6e722bfb7117cbdbd4d8a7bef11ce.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ5',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'd477da75b526ab4f1fcf8bb615c7e2e1',
                name: 'ピカチュウ5',
                bitmapResolution: 2,
                md5ext: 'd477da75b526ab4f1fcf8bb615c7e2e1.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ6',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '11f2c97f833713b5e5cbadbb1f80b5dc',
                name: 'ピカチュウ6',
                bitmapResolution: 2,
                md5ext: '11f2c97f833713b5e5cbadbb1f80b5dc.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ7',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '478308a25af96b1c41dfa8bfa5f7ff61',
                name: 'ピカチュウ7',
                bitmapResolution: 2,
                md5ext: '478308a25af96b1c41dfa8bfa5f7ff61.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ8',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'bff23f4ebe184c9082631130ed6826d4',
                name: 'ピカチュウ8',
                bitmapResolution: 2,
                md5ext: 'bff23f4ebe184c9082631130ed6826d4.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ9',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '5fed8739f9ea4d6d0a5323c80ab9d6e8',
                name: 'ピカチュウ9',
                bitmapResolution: 2,
                md5ext: '5fed8739f9ea4d6d0a5323c80ab9d6e8.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ10',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '63ed8a07338e60efc2e2dff34a15f480',
                name: 'ピカチュウ10',
                bitmapResolution: 2,
                md5ext: '63ed8a07338e60efc2e2dff34a15f480.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ピカチュウ11',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '9a84bf8f382dcc1aedad8b8e7b21586e',
                name: 'ピカチュウ11',
                bitmapResolution: 2,
                md5ext: '9a84bf8f382dcc1aedad8b8e7b21586e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'イーブイ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '65c480c97f8bcc682152b8b122d43653',
                name: 'イーブイ1',
                bitmapResolution: 2,
                md5ext: '65c480c97f8bcc682152b8b122d43653.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'イーブイ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'e36b4c6e2022c5a4355620ab2c6d3465',
                name: 'イーブイ2',
                bitmapResolution: 2,
                md5ext: 'e36b4c6e2022c5a4355620ab2c6d3465.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'イーブイ3',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'ff0570caf717eaad96d573f299dad142',
                name: 'イーブイ3',
                bitmapResolution: 2,
                md5ext: 'ff0570caf717eaad96d573f299dad142.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'イーブイ4',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '3c87c276df321e72b87f2e2f2f90ed7e',
                name: 'イーブイ4',
                bitmapResolution: 2,
                md5ext: '3c87c276df321e72b87f2e2f2f90ed7e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ヒトカゲ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '4d9a5eeb262c4bb3a74b00822a2e4cd6',
                name: 'ヒトカゲ1',
                bitmapResolution: 2,
                md5ext: '4d9a5eeb262c4bb3a74b00822a2e4cd6.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ヒトカゲ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '94b168e5c99dcf765d5c0e6238844574',
                name: 'ヒトカゲ2',
                bitmapResolution: 2,
                md5ext: '94b168e5c99dcf765d5c0e6238844574.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ゼニガメ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '173a1065c6258d5d821754b61383c0c2',
                name: 'ゼニガメ1',
                bitmapResolution: 2,
                md5ext: '173a1065c6258d5d821754b61383c0c2.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ゼニガメ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'a8f5403c41b0017422fff4c0e6a07e4e',
                name: 'ゼニガメ2',
                bitmapResolution: 2,
                md5ext: 'a8f5403c41b0017422fff4c0e6a07e4e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'フシギダネ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'd360baf767a6cb24c2d900b47e9e3a0c',
                name: 'フシギダネ1',
                bitmapResolution: 2,
                md5ext: 'd360baf767a6cb24c2d900b47e9e3a0c.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'フシギダネ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '4ef8ce2a289f84e59a5fb5685cfa7398',
                name: 'フシギダネ2',
                bitmapResolution: 2,
                md5ext: '4ef8ce2a289f84e59a5fb5685cfa7398.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'モンスターボール',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '87a04a54893e97c0058dd0a8e6b19fb4',
                name: 'モンスターボール',
                bitmapResolution: 2,
                md5ext: '87a04a54893e97c0058dd0a8e6b19fb4.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'スーパーボール',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '4c3be265f7467f6d9f2d4b29d430a7f7',
                name: 'スーパーボール',
                bitmapResolution: 2,
                md5ext: '4c3be265f7467f6d9f2d4b29d430a7f7.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ハイパーボール',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'b7e69dc536a1f803a4ff1e6af19efbd1',
                name: 'ハイパーボール',
                bitmapResolution: 2,
                md5ext: 'b7e69dc536a1f803a4ff1e6af19efbd1.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'マスターボール',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '2738329e7a1e7482320826d0c7e6ff7d',
                name: 'マスターボール',
                bitmapResolution: 2,
                md5ext: '2738329e7a1e7482320826d0c7e6ff7d.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'タマゴ',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'a1d2708c4941f7b8fc96da1284f526b4',
                name: 'タマゴ',
                bitmapResolution: 2,
                md5ext: 'a1d2708c4941f7b8fc96da1284f526b4.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'みがわり',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'e9f37570ea72b04d6bdfdc48bacf14cf',
                name: 'みがわり',
                bitmapResolution: 2,
                md5ext: 'e9f37570ea72b04d6bdfdc48bacf14cf.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'オボンのみ',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '6a4e319c0d5e6884c4b72c2488d5c3a2',
                name: 'オボンのみ',
                bitmapResolution: 2,
                md5ext: '6a4e319c0d5e6884c4b72c2488d5c3a2.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'カゴのみ',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'f561cf3cc5a7c47e132690843503bddf',
                name: 'カゴのみ',
                bitmapResolution: 2,
                md5ext: 'f561cf3cc5a7c47e132690843503bddf.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'モモンのみ',
        tags: [
            'tools'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '9218c6d1b9a0e35b61bbfe1c26b3f3fe',
                name: 'モモンのみ',
                bitmapResolution: 2,
                md5ext: '9218c6d1b9a0e35b61bbfe1c26b3f3fe.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'リザードン1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'c8e7fa4d8291ed610a396159d0e5b073',
                name: 'リザードン1',
                bitmapResolution: 2,
                md5ext: 'c8e7fa4d8291ed610a396159d0e5b073.png',
                dataFormat: 'png',
                rotationCenterX: 440,
                rotationCenterY: 320
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'リザードン2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '5ed373d91d9c6700d46b03fe0c4560d9',
                name: 'リザードン2',
                bitmapResolution: 2,
                md5ext: '5ed373d91d9c6700d46b03fe0c4560d9.png',
                dataFormat: 'png',
                rotationCenterX: 450,
                rotationCenterY: 310
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ディグダ1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'a6cb6525da60f5e9736477ef7df68897',
                name: 'ディグダ1',
                bitmapResolution: 2,
                md5ext: 'a6cb6525da60f5e9736477ef7df68897.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ディグダ2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '0e95e097e4646e4f213cef7d6e6ce377',
                name: 'ディグダ2',
                bitmapResolution: 2,
                md5ext: '0e95e097e4646e4f213cef7d6e6ce377.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'コイキング1',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'fd08d6a3f53c8c8f320772af3db1da4e',
                name: 'コイキング1',
                bitmapResolution: 2,
                md5ext: 'fd08d6a3f53c8c8f320772af3db1da4e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'コイキング2',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '2c9d87c46066fba07bf130826fa44c53',
                name: 'コイキング2',
                bitmapResolution: 2,
                md5ext: '2c9d87c46066fba07bf130826fa44c53.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'シャワーズ',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '48c1addd654e7c20e86c008b887e229c',
                name: 'シャワーズ',
                bitmapResolution: 2,
                md5ext: '48c1addd654e7c20e86c008b887e229c.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'サンダース',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '6cd8c7a044c848da9a515d119618b6b1',
                name: 'サンダース',
                bitmapResolution: 2,
                md5ext: '6cd8c7a044c848da9a515d119618b6b1.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ブースター',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '3d5db6de182b8ce79a8dcde14fd9b05e',
                name: 'ブースター',
                bitmapResolution: 2,
                md5ext: '3d5db6de182b8ce79a8dcde14fd9b05e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ミュウツー',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'de8e511b8ee8b1a3df69aa9c6caae917',
                name: 'ミュウツー',
                bitmapResolution: 2,
                md5ext: 'de8e511b8ee8b1a3df69aa9c6caae917.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ヒバニー',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '4a107cdd0e9f3103d6fca65de995bb15',
                name: 'ヒバニー',
                bitmapResolution: 2,
                md5ext: '4a107cdd0e9f3103d6fca65de995bb15.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ケロマツ',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '8922db76a0e4f6858f7b6f33e1635be1',
                name: 'ケロマツ',
                bitmapResolution: 2,
                md5ext: '8922db76a0e4f6858f7b6f33e1635be1.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ロトム',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'ef8267b73caf5d0f138c16b28be3696f',
                name: 'ロトム',
                bitmapResolution: 2,
                md5ext: 'ef8267b73caf5d0f138c16b28be3696f.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ニャオハ',
        tags: [
            'pokemon'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '6f867109cc785d70020f41d6c1171b3b',
                name: 'ニャオハ',
                bitmapResolution: 2,
                md5ext: '6f867109cc785d70020f41d6c1171b3b.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'でんき',
        tags: [
            'effects'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '6ed418f053d2975aa1b31950d91972fd',
                name: 'でんき',
                bitmapResolution: 2,
                md5ext: '6ed418f053d2975aa1b31950d91972fd.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ほのお',
        tags: [
            'effects'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'f85a6e36f78e4fc0955a7b1906ef1102',
                name: 'ほのお',
                bitmapResolution: 2,
                md5ext: 'f85a6e36f78e4fc0955a7b1906ef1102.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'みず',
        tags: [
            'effects'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'c4468bf2d3472a9364b0a4e27e6f3bcc',
                name: 'みず',
                bitmapResolution: 2,
                md5ext: 'c4468bf2d3472a9364b0a4e27e6f3bcc.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'くさ',
        tags: [
            'effects'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '79d50b3e43645d700b9655a040e9262f',
                name: 'くさ',
                bitmapResolution: 2,
                md5ext: '79d50b3e43645d700b9655a040e9262f.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'まる',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'b964c1df5ab493f8643e26e3b951fb0d',
                name: 'まる',
                bitmapResolution: 2,
                md5ext: 'b964c1df5ab493f8643e26e3b951fb0d.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'ばつ',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '3e098c4de6c6dd00074db7340f5c85f9',
                name: 'ばつ',
                bitmapResolution: 2,
                md5ext: '3e098c4de6c6dd00074db7340f5c85f9.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'やじるし',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '1d3a9b5d9de41dd9965729ed6dc9e9a2',
                name: 'やじるし',
                bitmapResolution: 2,
                md5ext: '1d3a9b5d9de41dd9965729ed6dc9e9a2.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'チェック',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '8e5a26f3c3ac91ff92334686f5deaadf',
                name: 'チェック',
                bitmapResolution: 2,
                md5ext: '8e5a26f3c3ac91ff92334686f5deaadf.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'はてな',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'e7cffeb19689e75740e6fc2b99acea1a',
                name: 'はてな',
                bitmapResolution: 2,
                md5ext: 'e7cffeb19689e75740e6fc2b99acea1a.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'びっくり',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'ac980fe7ce60963a693d14fd2080ce4c',
                name: 'びっくり',
                bitmapResolution: 2,
                md5ext: 'ac980fe7ce60963a693d14fd2080ce4c.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="くさむら"
                description="草むらのスプライト名"
                id="gui.sprite.grass"
            />
        ),
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '2fcd5bce04eea52e0b663ee9e74d9732',
                name: (
                    <FormattedMessage
                        defaultMessage="くさむら"
                        description="草むらのスプライト名"
                        id="gui.sprite.grass"
                    />
                ),
                bitmapResolution: 2,
                md5ext: '2fcd5bce04eea52e0b663ee9e74d9732.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="いわ"
                description="岩のスプライト名"
                id="gui.sprite.rock"
            />
        ),
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'd4b06fae0f34bdae327313b2009efd4e',
                name: (
                    <FormattedMessage
                        defaultMessage="いわ"
                        description="岩のスプライト名"
                        id="gui.sprite.rock"
                    />
                ),
                bitmapResolution: 2,
                md5ext: 'd4b06fae0f34bdae327313b2009efd4e.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="かさ"
                description="傘のスプライト名"
                id="gui.sprite.umbrella"
            />
        ),
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '61e81352d8eb396e8e6c1819598ef1b4',
                name: (
                    <FormattedMessage
                        defaultMessage="かさ"
                        description="傘のスプライト名"
                        id="gui.sprite.umbrella"
                    />
                ),
                bitmapResolution: 2,
                md5ext: '61e81352d8eb396e8e6c1819598ef1b4.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="くるま"
                description="車のスプライト名"
                id="gui.sprite.car"
            />
        ),
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'b7fbe4fc673b2a9a53a608779a2d9d9c',
                name: (
                    <FormattedMessage
                        defaultMessage="くるま"
                        description="車のスプライト名"
                        id="gui.sprite.car"
                    />
                ),
                bitmapResolution: 2,
                md5ext: 'b7fbe4fc673b2a9a53a608779a2d9d9c.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'パソコン',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: '801c18040e4142d18e6bc0cd9e74c3ed',
                name: 'パソコン',
                bitmapResolution: 2,
                md5ext: '801c18040e4142d18e6bc0cd9e74c3ed.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    },
    {
        name: 'リボン',
        tags: [
            'others'
        ],
        isStage: false,
        variables: {},
        costumes: [
            {
                assetId: 'f970ce63a5eace209cc8ade6150718c7',
                name: 'リボン',
                bitmapResolution: 2,
                md5ext: 'f970ce63a5eace209cc8ade6150718c7.png',
                dataFormat: 'png'
            }
        ],
        sounds: [],
        blocks: {}
    }
];
