const SET_ALLOW_INPUT_STATE = 'allowInputState/SET_ALLOW_INPUT_STATE';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialState = true;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_ALLOW_INPUT_STATE:
        document.dispatchEvent(new CustomEvent('changeAllowInputState', {detail: {state: action.state}}));
        return action.state;
    default:
        return state;
    }
};
const setAllowInputState = state => ({
    type: SET_ALLOW_INPUT_STATE,
    state: state
});

export {
    reducer as default,
    initialState as allowInputInitialState,
    setAllowInputState
};
