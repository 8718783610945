/* eslint-disable react/jsx-no-bind */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import dotenv from 'dotenv';
// import {connect} from 'react-redux';
import PokemonstartkitLogo from '../first-ui-library/pokemonstartkit-logo.jsx';
import styles from '../select-school-ui/select-school-ui.css';

import PokemonstartkitButtonComponent
from '../first-ui-library/pokemonstartkit-button/pokemonstartkit-button.jsx';
import {AvailableCompetitionTag} from '../../lib/libraries/competition.js';

const submitButtonName = 'submitButton';
dotenv.config();

const COMPETITION_TAG = process.env.COMPETITION_TAG;
const DEPLOY_MODE = process.env.DEPLOY_MODE;

class CompetitionEntryUi extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleExportProject',
            'handleOpenOverlay',
            'handleCloseOverlay',
            'handleSelectTag',
            'handleSetEditMode',
            'handleOpenEditMode',
            'handleLoadUserData',
            'onChangeName'
        ]);

        this.state = {
            isAvailable: true,
            isOpenUserOverlay: false,
            isLoadingUserData: false,
            isErrorUserData: false,
            userData: {
                userName: '',
                prefName: '',
                cityName: '',
                schoolName: ''
            }
        };
    }

    handleExportProject () {
        this.props.onExportCompetition();
    }

    handleOpenOverlay () {
        this.props.onOpenOverlay();
    }

    handleCloseOverlay () {
        this.props.onCloseOverlay();
    }

    handleSelectTag (event) {
        const tag = event.currentTarget.value;
        this.props.onSelectTag(tag);
    }

    handleSetEditMode () {
        this.props.onSetEditMode();
    }

    handleOpenEditMode () {
        this.setState(state => ({
            isOpenUserOverlay: !state.isOpenUserOverlay
        }));

        if (!this.state.isLoadingUserData) {
            this.handleLoadUserData();
        }
    }

    handleLoadUserData (){
        this.props.onLoadUserData()
            .then(d => {
                if (d) {
                    this.setState({
                        userData: {
                            userName: d.userName,
                            prefName: d.prefName,
                            cityName: d.cityName,
                            schoolName: d.schoolName
                        },
                        isLoadingUserData: true
                    });
                } else {
                    this.setState({isErrorUserData: true});
                }

            });
    }

    onChangeName (event, name) {
        const userData = this.state.userData;
        const afterName = event.currentTarget.value;
        userData[name] = afterName;
        this.setState({userData: userData});
    }

    render () {
        return (
            <>
                {
                    !this.props.isSenndingData &&
                    <div
                        className={styles.userEditButton}
                    >
                        <div
                            className={styles.openUserDataButton}
                            onClick={this.handleOpenEditMode}
                        >
                            ユーザー<ruby>情報<rt>じょうほう</rt></ruby>
                        </div>
                        {
                            this.state.isOpenUserOverlay &&
                                !this.props.isSenndingData &&
                                (
                                    this.state.isLoadingUserData ?

                                        (
                                            this.state.isErrorUserData ?
                                                <div className={styles.userEditWrapper}>
                                                    <div className={styles.userEditBody}>
                                                        <p>
                                                            ユーザーが取得できませんでした。
                                                            「ユーザー<ruby>情報<rt>じょうほう</rt></ruby>を
                                                            <ruby>変更<rt>へんこう</rt></ruby>する」
                                                            から<ruby>再設定<rt>さいせってい</rt></ruby>
                                                            してください
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={styles.editUserDataButton}
                                                        onClick={this.handleSetEditMode}
                                                    >
                                                        <p>
                                                            ユーザー<ruby>情報<rt>じょうほう</rt></ruby>を
                                                            <ruby>変更<rt>へんこう</rt></ruby>する
                                                        </p>

                                                    </div>

                                                </div> :
                                                <div className={styles.userEditWrapper}>
                                                    <div className={styles.userEditBody}>
                                                        <p>ユーザー<ruby>名<rt>めい</rt></ruby></p>
                                                        <div className={styles.userEditText}>{this.state.userData.userName}</div>
                                                        <p><ruby>都道府県<rt>とどうふけん</rt></ruby></p>
                                                        <div className={styles.userEditText}>{this.state.userData.prefName}</div>
                                                        <p><ruby>市区町村<rt>しくちょうそん</rt></ruby></p>
                                                        <div className={styles.userEditText}>{this.state.userData.cityName}</div>
                                                        <p><ruby>小学校名<rt>しょうがっこうめい</rt></ruby></p>
                                                        <div className={styles.userEditText}>{this.state.userData.schoolName}</div>
                                                    </div>
                                                    <div
                                                        className={styles.editUserDataButton}
                                                        onClick={this.handleSetEditMode}
                                                    >
                                                        <p>
                                                            ユーザー<ruby>情報<rt>じょうほう</rt></ruby>を
                                                            <ruby>変更<rt>へんこう</rt></ruby>する
                                                        </p>

                                                    </div>

                                                </div>
                                        ) : <div className={styles.userEditWrapper}>
                                            <p>ユーザーデータを取得中です・・・</p>
                                        </div>
                                )
                        }
                    </div>
                }
                <div
                    className={this.props.isSenndingData ? styles.logo : styles.relativeLogo}
                >
                    <PokemonstartkitLogo />
                </div>
                {
                    this.props.isSenndingData &&
                        < div className={styles.sendingWrapper}>
                            <p>
                                <ruby>送信中<rt>そうしんちゅう</rt></ruby>です。しばらくお<ruby>待<rt>ま</rt></ruby>ちください。
                                <br />
                                <span>
                                    (<ruby>通信環境<rt>つうしんかんきょう</rt></ruby>によって
                                    <ruby>数分<rt>すうふん</rt></ruby>かかる<ruby>場合<rt>ばあい</rt></ruby>があります。)
                                </span>
                            </p>
                            <div
                                className={styles.spinner}
                            />
                        </div>
                }

                {
                    !this.props.isSenndingData &&
                        <div className={styles.submitBody}>
                            <div className={styles.submitTextBody}>
                                <p>
                                    <ruby>現在<rt>げんざい</rt></ruby>の<ruby>作品<rt>さくひん</rt></ruby>を
                                    <ruby>大会<rt>たいかい</rt></ruby>に<ruby>応募<rt>おうぼ</rt></ruby>します。
                                </p>
                                <p>
                                    <ruby>必要<rt>ひつよう</rt></ruby>なことを<ruby>入力<rt>にゅうりょく</rt></ruby>して、
                                    <ruby>応募<rt>おうぼ</rt></ruby>ボタンを<ruby>押<rt>お</rt></ruby>してください。
                                </p>


                            </div>
                            {
                                DEPLOY_MODE === 'local' ?
                                    <p><ruby>応募<rt>おうぼ</rt></ruby>する
                                        <ruby>大会<rt>たいかい</rt></ruby>：
                                        <select
                                            className={styles.competitionModeSelector}
                                            onChange={this.handleSelectTag}
                                        >
                                            {
                                                AvailableCompetitionTag.map(tag => (
                                                    <option
                                                        className={styles.competitionModeOption}
                                                        // eslint-disable-next-line no-shadow, react/jsx-no-bind
                                                        key={tag.key}
                                                        value={tag.key}
                                                    >
                                                        {tag.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </p> : <p><ruby>現在<rt>げんざい</rt></ruby><ruby>開催中<rt>かいさいちゅう</rt></ruby>の
                                        <ruby>大会<rt>たいかい</rt></ruby>：
                                        {AvailableCompetitionTag.find(tag => tag.key === COMPETITION_TAG).name}
                                    </p>
                            }
                            <div className={styles.bodyWrapper}>
                                <div
                                    id={'projectName-body'}
                                    className={styles.selectSchoolModal}
                                >
                                    <p>
                                        <ruby>作品名<rt>さくひんめい</rt></ruby>
                                    </p>
                                    <textarea
                                        id={'projectName'}
                                        type="text"
                                        className={styles.sendDataName}
                                        onChange={this.props.onSetProjectName}
                                        placeholder="作品名をいれてね！(64文字まで)"
                                        maxLength={64}
                                    />
                                </div>
                                <div
                                    id={'comment-body'}
                                    className={styles.selectSchoolModal}
                                >
                                    <p>
                                        コメント
                                    </p>
                                    <textarea
                                        id={'comment'}
                                        type="text"
                                        className={styles.sendDataComment}
                                        onChange={this.props.onSetComment}
                                        placeholder="コメントをいれてね！(256文字まで)"
                                        maxLength={256}
                                    />
                                </div>
                            </div>
                            <div className={styles.sendBlock}>
                                {
                                    this.props.isErrorSending ?
                                        <PokemonstartkitButtonComponent
                                            id={submitButtonName}
                                            onClick={this.handleOpenOverlay}
                                            enabled
                                        >
                                            <ruby>応募<rt>おうぼ</rt></ruby>できない<ruby>方用<rt>かたよう</rt></ruby>
                                        </PokemonstartkitButtonComponent> :
                                        <>
                                            {
                                                (
                                                    this.props.isAvailableSubmittion &&
                                                    this.props.userId &&
                                                    this.props.projectName.match(/\S/g)
                                                ) ?
                                                    <PokemonstartkitButtonComponent
                                                        id={submitButtonName}
                                                        onClick={this.handleExportProject}
                                                        enabled
                                                    >
                                                        <ruby>応募<rt>おうぼ</rt></ruby></PokemonstartkitButtonComponent> :
                                                    <PokemonstartkitButtonComponent
                                                        id={submitButtonName}
                                                    >
                                                        <ruby>応募<rt>おうぼ</rt></ruby>
                                                    </PokemonstartkitButtonComponent>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                }
            </>
        );
    }
}

CompetitionEntryUi.propTypes = {
    onExportCompetition: PropTypes.func,
    onOpenOverlay: PropTypes.func,
    onCloseOverlay: PropTypes.func,

    onSetProjectName: PropTypes.func,
    onSetComment: PropTypes.func,

    isAvailableSubmittion: PropTypes.bool,

    userId: PropTypes.string,
    projectName: PropTypes.string,

    isErrorSending: PropTypes.bool,
    isSenndingData: PropTypes.bool,
    onSelectTag: PropTypes.func,
    onSetEditMode: PropTypes.func,
    onLoadUserData: PropTypes.func
};

export default injectIntl(CompetitionEntryUi);
