import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';

import Modal from '../../../containers/noheader-modal.jsx';
import SelectMode from './select-mode.jsx';
// import {connectDB} from '../../../lib/indexedDB.jsx';
// import downloadBlob from '../../../lib/download-blob';
// import Minizip from 'minizip-asm.js';
// import styles from '../pokemonstartkit.css';

class SelectModeUiComponent extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClick',
            'handleClose'
        ]);
    }

    handleClick () {
        this.props.onRequestOpen();
    }
    handleClose () {
        this.props.onRequestClose();
    }


    render () {
        return (
            <>

                <Modal
                    fullScreen
                    contentLabel={this.props.title}
                    id={this.props.id}
                    onRequestOpen={this.handleClick}
                    onRequestClose={this.props.onSelectBackButton}
                    isShowingBackButton
                    header
                    scratchPage
                >
                    <SelectMode
                        onSelectClassMode={this.props.onSelectClassMode}
                        onSelectFreeMode={this.props.onSelectFreeMode}
                        onSelectTeacherTool={this.props.onSelectTeacherTool}
                        onSelectBackButton={this.props.onSelectBackButton}
                    />

                </Modal>

            </>
        );
    }
}
SelectModeUiComponent.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.object,
    // modalTitleList: PropTypes.arrayOf(PropTypes.string),
    // selectedId: PropTypes.number,
    onRequestOpen: PropTypes.func,
    onRequestClose: PropTypes.func,

    selectedTeacher: PropTypes.bool,

    onSelectBackButton: PropTypes.func,
    onSelectClassMode: PropTypes.func,
    onSelectFreeMode: PropTypes.func,
    onSelectTeacherTool: PropTypes.func
};

SelectModeUiComponent.defaultProps = {
    selectedTeacher: false
};

export default injectIntl((SelectModeUiComponent));
