/* eslint-disable react/jsx-key */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-handler-names */
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';

import styles from './loading-animation.css';

class LoadingAnimationComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'isValidIndex'
        ]);
    }

    onPreventDefault (event) {
        event.preventDefault();
    }

    isValidIndex (index, length) {
        return this.props.spinnerIndex === index ||
            (this.props.spinnerIndex === -1 && index === length - 1);
    }

    render (){
        const textBoxWrapper = this.props.isUserData ? styles.userdataTextBoxWrapper : styles.textBoxWrapper;
        const loadingText = this.props.isUserData ? styles.userdataLoadingText : styles.loadingText;
        const spinner = this.props.isUserData ? styles.userdataSpinner : styles.spinner;
        return (
            <div
                className={styles.loadingWrapper}
                id="loading-wrapper"
                onContextMenu={this.onPreventDefault}
            >
                <div
                    className={styles.loadingContent}
                    id="loading-content"
                >
                    <div className={textBoxWrapper}>
                        {
                            this.props.textContents.map((content, i) =>
                                (
                                    <div
                                        className={styles.bodyWrapper}
                                        key={`loading-animation-body-${i}`}
                                    >
                                        <div className={loadingText}>
                                            {content}
                                            {
                                                this.isValidIndex(i, this.props.textContents.length) &&
                                                <div className={spinner} />
                                            }
                                        </div>
                                    </div>)
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

LoadingAnimationComponent.propTypes = {
    isUserData: PropTypes.bool,
    textContents: PropTypes.arrayOf(PropTypes.element),
    spinnerIndex: PropTypes.number
};

LoadingAnimationComponent.defaultProps = {
    isUserData: false,
    textContents: [
        <p>ロード<ruby>中<rt>ちゅう</rt></ruby>です</p>,
        <p>しばらくお<ruby>待<rt>ま</rt></ruby>ちください</p>,
        <p title="small">
            ※<ruby>環境<rt>かんきょう</rt></ruby>によって<ruby>数分<rt>すうふん</rt></ruby>かかる<ruby>場合<rt>ばあい</rt></ruby>があります
        </p>
    ],
    spinnerIndex: -1
};

export default LoadingAnimationComponent;
