import Select from 'react-select';
import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        'width': '25rem',
        'margin': '0rem 2rem',
        'backgroundColor': state.isDisabled ? '#777' : '#eee',
        'textAlign': 'center',
        'fontSize': '1.1rem',
        '&:hover': {
            cursor: 'pointer'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        'backgroundColor': state.isSelected ? '#B4B4B4' : '#fff',
        'color': state.isSelected ? 'white' : 'black',
        'fontSize': '1.5rem',
        '&:hover': {
            backgroundColor: '#0064FF',
            color: 'white'
        }
    }),
    menu: provided => ({
        ...provided,
        backgroundColor: '#eee'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black'

    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#ccc' : '#666'

    })
};

class SearchSelectBox extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleChange',
            'onClear'
        ]);
        this.state = {
            selectedValue: 'default'
        };
    }

    componentDidUpdate (prevState){
        if ((JSON.stringify(prevState.options) !== JSON.stringify(this.props.options)) || ((prevState.compareText !== this.props.compareText) && this.props.compareText === '')) {
            this.onClear();
        }
    }

    handleChange (e){
        this.props.onChangeValue(e);
        this.setState({
            selectedValue: e
        });
    }

    onClear () {
        this.setState({
            selectedValue: 'default'
        });
    }

    render () {
        return (
            <Select
                instanceId="search-select-box"
                styles={selectStyles}
                inputId={this.props.id}
                defaultValue={'default'}
                options={this.props.options}
                onChange={this.handleChange}
                // eslint-disable-next-line react/jsx-no-bind
                noOptionsMessage={() => '選択肢が見つかりません'}
                placeholder={
                    <>
                        <ruby>選択<rt>せんたく</rt></ruby>してください（
                        <ruby>文字入力<rt>もじにゅうりょく</rt></ruby>
                        で<ruby>検索<rt>けんさく</rt></ruby>）
                    </>
                }
                isSearchable
                isDisabled={this.props.disabled}
                value={this.state.selectedValue}
            />
        );
    }
}

SearchSelectBox.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
            extra: PropTypes.string
        })
    ),
    id: PropTypes.string,
    onChangeValue: PropTypes.func,
    disabled: PropTypes.bool,
    compareText: PropTypes.string
};

export default injectIntl(SearchSelectBox);
