// import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import SelectSchoolUI from '../../containers/select-school-ui.jsx';
// import styles from './competition.css';

class CompetitionComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose'
        ]);

    }
    handleClose () {
        this.props.onRequestClose();
    }

    render () {
        return (
            <SelectSchoolUI
                onRequestClose={this.handleClose}
                isCompetitionMode
                onDownLoadProject={this.props.onDownLoadProject}
            />
        );
    }
}

CompetitionComponent.propTypes = {
    onRequestClose: PropTypes.func,
    onDownLoadProject: PropTypes.func
};

export default injectIntl(CompetitionComponent);
