/* eslint-disable no-console */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import styles from './load-ui.css';
import Modal from '../../../../containers/noheader-modal.jsx';
// import DemoCaption from '../../../demo-caption/demo-caption.jsx';
// import PokemonstartkitButtonComponent from '../../pokemonstartkit-button/pokemonstartkit-button.jsx';
import TextCard from '../../../text-card/text-card.jsx';
import {connectDB} from '../../../../lib/indexedDB.jsx';

const messages = [
    [
        <h1 key="caption_title">ポケモンのゲームを<ruby>作<rt>つく</rt></ruby>ってみよう</h1>,
        <p key="caption_main-text1">
            このカリキュラムではかんたんなゲーム<ruby>作<rt>づく</rt></ruby><ruby><rt /></ruby>りを
            <ruby>通<rt>とお</rt></ruby>してプログラミングの<ruby>基本<rt>きほん</rt></ruby>が<ruby>学<rt>まな</rt></ruby>べます。
        </p>,
        <p key="caption_main-text2">
            まずはピカチュウをゲットするゲーム<ruby>作<rt>づく</rt></ruby>りにチャレンジしましょう。
        </p>,
        <p key="caption_main-text3">
            そのあとはヒントカードもみながら<ruby>自分<rt>じぶん</rt></ruby>だけのゲームを<ruby>作<rt>つく</rt></ruby>ってみましょう。
        </p>,
        <img
            src={'static/b7a8ed691c06f320ced8f2b40d97351e.jpg'}
            key="caption_image"
        />
    ],
    [
        <h1 key="caption_title">
            <ruby>多角形<rt>たかくけい</rt></ruby>と<ruby>円<rt>えん</rt></ruby><br />～
            ポケモンと<ruby>一緒<rt>いっしょ</rt></ruby>にコンピュータで<br /><ruby>図形<rt>ずけい</rt></ruby>を<ruby>描<rt>か</rt></ruby>こう～
        </h1>,
        <p key="caption_main-text1">
            このカリキュラムではプログラミングを<ruby>用<rt>もち</rt></ruby>いて
            ポケモンと<ruby>多角形<rt>たかくけい</rt></ruby>を<ruby>作図<rt>さくず</rt></ruby>します。
        </p>,
        <p key="caption_main-text2">
            まずはポケモンといっしょにいろいろな<ruby>多角形<rt>たかくけい</rt></ruby>を<ruby>描<rt>か</rt></ruby>いてみましょう。</p>,
        <p key="caption_main-text3">
            そのあとはポケモンに<ruby>合<rt>あ</rt></ruby>う<ruby>模様<rt>もよう</rt></ruby>を
            <ruby>考<rt>かんが</rt></ruby>えて<ruby>作<rt>つく</rt></ruby>ってみましょう。
        </p>,
        <img
            src={'static/a84f2bd63c1905e8fa71d6e54b25867c.png'}
            key="caption_image"
        />
    ],
    [
        <h1 key="caption_title">
            ポケモンたちと<br /><ruby>物語<rt>ものがたり</rt></ruby>を<ruby>作<rt>つく</rt></ruby>ってみよう<br />
        </h1>,
        <p key="caption_main-text1">
            このカリキュラムではプログラミングを<ruby>用<rt>もち</rt></ruby>いて
            ポケモンたちが<ruby>出<rt>で</rt></ruby>てくる<ruby>物語<rt>ものがたり</rt></ruby>を<ruby>作<rt>つく</rt></ruby>ります。</p>,
        <p key="caption_main-text2">まずはポケモンの<ruby>動<rt>うご</rt></ruby>かし<ruby>方<rt>かた</rt></ruby>や
            <ruby>場面転換<rt>ばめんてんかん</rt></ruby>の<ruby>仕方<rt>しかた</rt></ruby>といった
            <ruby>物語<rt>ものがたり</rt></ruby><ruby>作<rt>づく</rt></ruby>りに
            <ruby>必要<rt>ひつよう</rt></ruby>なことを<ruby>学<rt>まな</rt></ruby>びます。
        </p>,
        <p key="caption_main-text3">
            そのあとは<ruby>自分<rt>じぶん</rt></ruby>で<ruby>考<rt>かんが</rt></ruby>えた
            <ruby>物語<rt>ものがたり</rt></ruby>をプログラミングして<ruby>作<rt>つく</rt></ruby>ってみましょう。
        </p>,
        <img
            src={'static/2cf1b6e08d3679a472e58c4d91af375b.jpg'}
            key="caption_image"
        />
    ]
];

class LoadUI extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectedNewgame',
            'handleSelectedContinue',
            'handleToggleChange'
        ]);

        this.state = {
            isExistData: false,
            isLoaded: false,
            lastPlayTime: null,
            isValidAutoSave: true
        };
    }

    componentDidMount () {
        this.onCheckData();
    }

    handleSelectedNewgame () {
        return new Promise(async resolve => {
            console.log('はじめから');
            if (this.state.isExistData) {
                // const allow = confirm('うわがきしてあたらしくはじめますか？すでにセーブされているデータはきえてしまいます。');
                const allow = await window.ownConfirm(<p><ruby>上書<rt>うわが</rt></ruby>きして<ruby>新<rt>あたら</rt></ruby>しくはじめますか？すでにセーブされているデータは<ruby>消<rt>き</rt></ruby>えてしまいます</p>);

                if (!allow) return;
            }
            this.props.onSelectedNewgame();
            resolve();
        });
    }

    handleSelectedContinue () {
        return new Promise(async resolve => {
            console.log('つづきから');
            const element = document.getElementById(this.props.toggleId);

            let accept = true;
            if (element.checked) {
                // accept = confirm(`オートセーブをゆうこうにすると、じどうでへんこうがほぞんされます。これまでのデータはうわがきされますが、このせっていではじめますか？`);
                accept = await window.ownConfirm(<p>オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にすると、<ruby>自動<rt>じどう</rt></ruby>で<ruby>変更<rt>へんこう</rt></ruby>が<ruby>保存<rt>ほぞん</rt></ruby>されます。これまでのデータは<ruby>上書<rt>うわが</rt></ruby>きされますが、この<ruby>設定<rt>せってい</rt></ruby>ではじめますか？</p>);
            }
            if (!accept) return;
            this.props.onSelectedContinue();
            resolve();
        });
    }

    onCheckData () {
        return new Promise(async (resolve, reject) => {
            const db = await connectDB();
            const transaction = db.transaction(['playerData'], 'readonly');
            const objstore = transaction.objectStore('playerData');
            const getReq = objstore.get(`classmode_${this.props.selectId + 1}`);
            getReq.onsuccess = e => {
                const result = e.target.result;
                if (typeof result !== 'undefined') {
                    this.setState({
                        isExistData: true,
                        lastPlayTime: result.time
                    });
                }
                this.setState({isLoaded: true});
                db.close();
                return resolve();
            };

            getReq.onerror = err => {
                db.close();
                return reject(err);
            };
        });
    }

    handleToggleChange (e) {
        const boolean = e.target.checked;
        this.setState({isValidAutoSave: boolean});
    }

    render () {
        return (
            <Modal
                header
                scratchPage
                fullScreen
                contentLabel={<p>はじめ<ruby>方<rt>かた</rt></ruby>を<ruby>選<rt>えら</rt></ruby>んでください</p>}
                id={this.props.id}
                onRequestClose={this.props.onSelectBackButton}
                isShowingBackButton
            >
                {
                    this.state.isLoaded &&
                        <div className={styles.bodyAll}>
                            <div className={styles.classContainer}>
                                <div
                                    className={styles.title}
                                >
                                    <div className={styles.titleBox}>
                                        {
                                            messages[this.props.selectId].map(d => d)
                                        }
                                    </div>
                                </div>
                                <div className={styles.selectModeContainer} >
                                    <TextCard
                                        onClick={this.handleSelectedNewgame}
                                        reversalMode
                                    ><h2>{'はじめから'}</h2></TextCard>
                                    <TextCard
                                        onClick={this.handleSelectedContinue}
                                        reversalMode
                                        disabled={!this.state.isExistData}
                                    ><h2>{'つづきから'}</h2></TextCard>
                                </div>
                                {this.state.isExistData &&
                                    <h3 className={styles.timeText}>
                                        <ruby>最後<rt>さいご</rt></ruby>に<ruby>保存<rt>ほぞん</rt></ruby>した<ruby>日時<rt>にちじ</rt></ruby>：{this.state.lastPlayTime}
                                    </h3>
                                }
                               
                                <div className={styles.toggleBox}>
                                    <input
                                        className={styles.toggleInput}
                                        id={this.props.toggleId}
                                        type="checkbox"
                                        defaultChecked={this.props.autoSaveState}
                                        onChange={this.handleToggleChange}
                                    />
                                    <label htmlFor={this.props.toggleId}>
                                        {this.state.isValidAutoSave ?
                                            <h4 className={styles.validAutoSave}>オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にする<br />
                                                （<ruby>有効<rt>ゆうこう</rt></ruby>にすると、
                                                20<ruby>秒<rt>びょう</rt></ruby>に1<ruby>回<rt>かい</rt></ruby>
                                                <ruby>自動<rt>じどう</rt></ruby>で<ruby>保存<rt>ほぞん</rt></ruby>されます）
                                            </h4> :
                                            <h4
                                                className={styles.invalidAutoSave}
                                            >
                                                オートセーブを<ruby>有効<rt>ゆうこう</rt></ruby>にする<br />
                                                （<ruby>有効<rt>ゆうこう</rt></ruby>にすると、
                                                20<ruby>秒<rt>びょう</rt></ruby>に1<ruby>回<rt>かい</rt></ruby>
                                                <ruby>自動<rt>じどう</rt></ruby>で<ruby>保存<rt>ほぞん</rt></ruby>されます）
                                            </h4>
                                        }
                                    </label>
                                </div>
                                {
                                    this.state.isValidAutoSave &&
                                    <p className={styles.multiTabAlert}>
                                        <ruby>複数<rt>ふくすう</rt></ruby>のタブで<ruby>操作<rt>そうさ</rt></ruby>
                                        するとデータが<ruby>上書<rt>うわが</rt></ruby>きされる<ruby>可能性<rt>かのうせい</rt></ruby>があります。
                                        ご<ruby>注意<rt>ちゅうい</rt></ruby>ください。
                                    </p>
                                }
                            </div>
                        </div>
                }


            </Modal>
        );
    }
}

LoadUI.propTypes = {
    id: PropTypes.string.isRequired,
    // onRequestClose: PropTypes.func,
    onSelectBackButton: PropTypes.func,
    onSelectedNewgame: PropTypes.func,
    onSelectedContinue: PropTypes.func,
    selectId: PropTypes.number,
    toggleId: PropTypes.string,
    autoSaveState: PropTypes.bool
};

const mapStateToProps = state => ({
    projectChanged: state.scratchGui.projectChanged,
    autoSaveState: state.scratchGui.autosaveState

});

export default injectIntl(connect(
    mapStateToProps,
    {},
)(LoadUI));
