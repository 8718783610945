const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const CLOSE_MODAL = 'scratch-gui/modals/CLOSE_MODAL';

//
const MODAL_FIRST_TIME_UI = 'firstTimeUI';
const MODAL_LOAD_UI = 'loadUI';
const MODAL_FREE_MODE_SAVE_UI = 'freeModeSaveUI';
const MODAL_FREE_MODE_LOAD_UI = 'freeModeLoadUI';

// const MODAL_TITLE_UI = 'titleUI';
const MODAL_POKEMON_HELP_LIBRARY = 'pokemonHelpLibrary';
const MODAL_POKEMON_TIPS_LIBRARY = 'pokemonTipsLibrary';
const MODAL_PC_TRAINING = 'pcTraining';
const MODAL_COMPETITION = 'competition';
const MODAL_COMPETITION_DEV = 'competitionDev';
//

const MODAL_BACKDROP_LIBRARY = 'backdropLibrary';
const MODAL_COSTUME_LIBRARY = 'costumeLibrary';
const MODAL_EXTENSION_LIBRARY = 'extensionLibrary';
const MODAL_LOADING_PROJECT = 'loadingProject';
// const MODAL_TELEMETRY = 'telemetryModal';
const MODAL_SOUND_LIBRARY = 'soundLibrary';
const MODAL_SPRITE_LIBRARY = 'spriteLibrary';
const MODAL_SOUND_RECORDER = 'soundRecorder';
const MODAL_CONNECTION = 'connectionModal';
const MODAL_TIPS_LIBRARY = 'tipsLibrary';

const initialState = {
    //
    // [MODAL_TITLE_UI]: false,
    [MODAL_FIRST_TIME_UI]: true,
    [MODAL_LOAD_UI]: false,
    [MODAL_FREE_MODE_LOAD_UI]: false,
    [MODAL_FREE_MODE_SAVE_UI]: false,

    [MODAL_POKEMON_HELP_LIBRARY]: false,
    [MODAL_POKEMON_TIPS_LIBRARY]: false,
    [MODAL_PC_TRAINING]: false,
    [MODAL_COMPETITION]: false,
    [MODAL_COMPETITION_DEV]: false,
    //
    [MODAL_BACKDROP_LIBRARY]: false,
    [MODAL_COSTUME_LIBRARY]: false,
    [MODAL_EXTENSION_LIBRARY]: false,
    [MODAL_LOADING_PROJECT]: false,
    // [MODAL_TELEMETRY]: false,
    [MODAL_SOUND_LIBRARY]: false,
    [MODAL_SPRITE_LIBRARY]: false,
    [MODAL_SOUND_RECORDER]: false,
    [MODAL_CONNECTION]: false,
    [MODAL_TIPS_LIBRARY]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MODAL:
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    default:
        return state;
    }
};
const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};
const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};

//
const openFirstTimeUI = function () {
    return openModal(MODAL_FIRST_TIME_UI);
};
const openLoadUI = function () {
    return openModal(MODAL_LOAD_UI);
};
const openFreeModeSaveUI = function () {
    return openModal(MODAL_FREE_MODE_SAVE_UI);
};
const openFreeModeLoadUI = function () {
    return openModal(MODAL_FREE_MODE_LOAD_UI);
};

// const openTitleUI = function () {
//    return openModal(MODAL_TITLE_UI);
// };
const openPokemonHelpLibrary = function () {
    return openModal(MODAL_POKEMON_HELP_LIBRARY);
};
const openPokemonTipsLibrary = function () {
    return openModal(MODAL_POKEMON_TIPS_LIBRARY);
};
const openPcTraining = function () {
    return openModal(MODAL_PC_TRAINING);
};
const openCompetition = function () {
    return openModal(MODAL_COMPETITION);
};
const openCompetitionDev = function () {
    return openModal(MODAL_COMPETITION_DEV);
};
//
const openBackdropLibrary = function () {
    return openModal(MODAL_BACKDROP_LIBRARY);
};
const openCostumeLibrary = function () {
    return openModal(MODAL_COSTUME_LIBRARY);
};
const openExtensionLibrary = function () {
    return openModal(MODAL_EXTENSION_LIBRARY);
};
const openLoadingProject = function () {
    return openModal(MODAL_LOADING_PROJECT);
};
// const openTelemetryModal = function () {
//    return openModal(MODAL_TELEMETRY);
// };
const openSoundLibrary = function () {
    return openModal(MODAL_SOUND_LIBRARY);
};
const openSpriteLibrary = function () {
    return openModal(MODAL_SPRITE_LIBRARY);
};
const openSoundRecorder = function () {
    return openModal(MODAL_SOUND_RECORDER);
};
const openConnectionModal = function () {
    return openModal(MODAL_CONNECTION);
};
const openTipsLibrary = function () {
    return openModal(MODAL_TIPS_LIBRARY);
};
/*
const openOverlay = function () {
    return openModal(MODAL_OVERLAY);
};
*/

//
const closeFirstTimeUI = function () {
    return closeModal(MODAL_FIRST_TIME_UI);
};
const closeLoadUI = function () {
    return closeModal(MODAL_LOAD_UI);
};
const closeFreeModeSaveUI = function () {
    return closeModal(MODAL_FREE_MODE_SAVE_UI);
};
const closeFreeModeLoadUI = function () {
    return closeModal(MODAL_FREE_MODE_LOAD_UI);
};

// const closeTitleUI = function () {
//    return closeModal(MODAL_TITLE_UI);
// };
const closePokemonHelpLibrary = function () {
    return closeModal(MODAL_POKEMON_HELP_LIBRARY);
};
const closePokemonTipsLibrary = function () {
    return closeModal(MODAL_POKEMON_TIPS_LIBRARY);
};
const closePcTraining = function () {
    return closeModal(MODAL_PC_TRAINING);
};
const closeCompetition = function () {
    return closeModal(MODAL_COMPETITION);
};
const closeCompetitionDev = function () {
    return closeModal(MODAL_COMPETITION_DEV);
};
//
const closeBackdropLibrary = function () {
    return closeModal(MODAL_BACKDROP_LIBRARY);
};
const closeCostumeLibrary = function () {
    return closeModal(MODAL_COSTUME_LIBRARY);
};
const closeExtensionLibrary = function () {
    return closeModal(MODAL_EXTENSION_LIBRARY);
};
const closeLoadingProject = function () {
    return closeModal(MODAL_LOADING_PROJECT);
};
// const closeTelemetryModal = function () {
//    return closeModal(MODAL_TELEMETRY);
// };
const closeSpriteLibrary = function () {
    return closeModal(MODAL_SPRITE_LIBRARY);
};
const closeSoundLibrary = function () {
    return closeModal(MODAL_SOUND_LIBRARY);
};
const closeSoundRecorder = function () {
    return closeModal(MODAL_SOUND_RECORDER);
};
const closeTipsLibrary = function () {
    return closeModal(MODAL_TIPS_LIBRARY);
};
const closeConnectionModal = function () {
    return closeModal(MODAL_CONNECTION);
};
/*
const closeOverlay = function () {
    return closeModal(MODAL_OVERLAY);
};
*/

export {
    reducer as default,
    initialState as modalsInitialState,

    //
    openFirstTimeUI,
    openLoadUI,
    openFreeModeSaveUI,
    openFreeModeLoadUI,

    openPokemonHelpLibrary,
    openPokemonTipsLibrary,
    openPcTraining,
    openCompetition,
    openCompetitionDev,
    //

    openBackdropLibrary,
    openCostumeLibrary,
    openExtensionLibrary,
    openLoadingProject,
    openSoundLibrary,
    openSpriteLibrary,
    openSoundRecorder,
    // openTelemetryModal,
    openTipsLibrary,
    openConnectionModal,
    // openOverlay,

    //
    closeFirstTimeUI,
    closeLoadUI,
    closeFreeModeSaveUI,
    closeFreeModeLoadUI,

    closePokemonHelpLibrary,
    closePokemonTipsLibrary,
    closePcTraining,
    closeCompetition,
    closeCompetitionDev,
    //

    closeBackdropLibrary,
    closeCostumeLibrary,
    closeExtensionLibrary,
    closeLoadingProject,
    closeSpriteLibrary,
    closeSoundLibrary,
    closeSoundRecorder,
    // closeTelemetryModal,
    closeTipsLibrary,
    closeConnectionModal
    // closeOverlay
};
