/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';

import {connect} from 'react-redux';
// import Modal from '../../containers/noheader-modal.jsx';
import SaveloadUi from '../../containers/save-load-ui.jsx';
// import Spinner from '../spinner/spinner.jsx';
import LoadingAnimation from '../loading-animation/loading-animation.jsx';

import styles from './first-ui-library.css';

import TeacherUiComponent from './teacher-ui/teacher-ui.jsx';
import StudentUiComponent from './student-ui/student-ui.jsx';
import SelectGameUiComponent from './select-game/select-game.jsx';
import ClassModeUi from './class-mode-ui/class-mode-ui.jsx';

import TeacherToolUiComponent from './teacher-tool-ui/teacher-tool-ui.jsx';

import SelectModeUiComponent from './select-mode/select-mode-ui.jsx';
import SelectUser from './select-user/select-user.jsx';
import {connectDB} from '../../lib/indexedDB.jsx';

const modalTitleList = [
    <></>,
    <p key={'selectUser'}>ユーザーを<ruby>選<rt>えら</rt></ruby>んでください</p>,
    <p key={'forTeacher'}>先生のみなさまへ</p>,
    <p key={'forUser'}>小学校以外の教育施設の利用希望者のみなさまへ</p>,
    <p key={'forStudends'}><ruby>児童<rt>じどう</rt></ruby>のみなさまへ</p>,
    <p key={'selectMode'}>モードを<ruby>選<rt>えら</rt></ruby>んでください</p>
];

const userDataID = 'UserType';
import {
    openPcTraining
} from '../../reducers/modals';
import {setProjectID} from '../../reducers/project-id';
import {setUserState} from '../../reducers/user-state';
// import {setProjectTitle} from '../../reducers/project-title';
import {setSelectedClassMode} from '../../reducers/selected-class-mode';

const SELECT_GAME_UI_ID = 0;

const SELECT_USER_UI_ID = 1;
const FIRST_TEACHER_UI_ID = 2;
const FIRST_USER_UI_ID = 3;
const FIRST_STUDENT_UI_ID = 4;

const SELECT_MODE_UI_ID = 5;
const CLASSMODE_UI_ID = 6;
const TEACHER_TOOL_ID = 7;

const FREEMODE_UI_ID = 8;

const CHECKLOGIN_INTERVAL_MILISECOND = 2000;
const CHECKLOGIN_TIMEOUT_MILISECOND = 7000;

class FirstUiLibraryComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleSelect',

            'onSetUserData',
            /* 'handleBackButtonEvent',*/
            'handleClosePopup',
            'handleSetFreemode',
            // 'handleSelectedNewGame',
            // 'handleSelectedContinue',
            'handleClosedTeacherUI',
            'handleClosedStudentUI',
            'handleSelectFreemode',
            'allowUser',
            'handleChangeToSchoolTeacher'
        ]);

        let stateId = SELECT_GAME_UI_ID;
        let classModeId = null;

        this.timeoutId = null;
        this.intervalId = null;

        if (this.props.isSelectedLoadUI) {
            if (this.props.selectedClassMode === 'FREE') {
                stateId = FREEMODE_UI_ID;
            }
            if (this.props.selectedClassMode === 'CLASS_MAIN') {
                stateId = CLASSMODE_UI_ID;
                classModeId = 0;
            }
            if (this.props.selectedClassMode === 'CHARIZARD') {
                stateId = CLASSMODE_UI_ID;
                classModeId = 1;
            }
            if (this.props.selectedClassMode === 'BOOK') {
                stateId = CLASSMODE_UI_ID;
                classModeId = 2;
            }
        }

        this.state = {
            loaded: this.props.isSelectedLoadUI,
            selectStateId: stateId,
            selectedTeacher: false,
            isPopupOpened: false,
            selectClassModeId: classModeId
        };
    }
    componentDidMount () {
        if (this.props.userState === null) {
            this.intervalId = setInterval(() => {
                this.checkLogin().catch(err => console.log(err));
            }, CHECKLOGIN_INTERVAL_MILISECOND);

            this.timeoutId = setTimeout(() => {
                if (this.intervalId) {
                    window.ownAlert('ユーザーデータの読み込みに失敗しました。再起動します。');
                    window.location.reload();
                }
            }, CHECKLOGIN_TIMEOUT_MILISECOND);
        }

    }
    componentDidUpdate (prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }

    componentWillUnmount () {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    handleSelect (id) {
        this.setState({
            selectStateId: id
        });
    }
    handleClose () {
        this.props.onRequestClose();
    }
    onSelectedTeacher () {
        this.setState({
            selectedTeacher: true
        });
    }
    handleClosePopup () {
        this.setState({
            isPopupOpened: false
        });
    }

    handleSetFreemode () {
        this.props.onSetSelectedClassMode('FREE');
    }

    allowUser (isTeacher) {
        this.handleSelect(SELECT_GAME_UI_ID);
        this.onSetUserData(isTeacher);
    }

    handleClosedTeacherUI () {
        this.allowUser(true);
    }

    handleClosedStudentUI () {
        this.allowUser(false);
    }

    removeLoadingAnimation () {
        const element = document.getElementById('loading-wrapper');
        element.remove();
        const styleElement = document.getElementById('loading-style');
        styleElement.remove();
    }

    checkLogin () {
        return new Promise(async (resolve, reject) => {
            let db;
            try {
                if (this.props.userState !== null) resolve();
                db = await connectDB();
                const result = await this.getUserType(db);
                // this.removeLoadingAnimation();
                if (typeof result === 'undefined') {
                    // this.setState({ isPopupOpened: true });
                    await window.ownAlert(
                        <p>
                            <ruby>本<rt>ほん</rt></ruby>キットを<ruby>初<rt>はじ</rt></ruby>めて
                            <ruby>利用<rt>りよう</rt></ruby>する<ruby>場合<rt>ばあい</rt></ruby>、
                            <ruby>利用者<rt>りようしゃ</rt></ruby>は<ruby>利用規約<rt>りようきやく</rt></ruby>
                            への<ruby>同意<rt>どうい</rt></ruby>などの
                            <ruby>手続<rt>てつづ</rt></ruby>きをする<ruby>必要<rt>ひつよう</rt></ruby>があります。
                            <br />
                            「OK」を<ruby>押<rt>お</rt></ruby>して<ruby>画面<rt>がめん</rt></ruby>の
                            <ruby>指示<rt>しじ</rt></ruby>に<ruby>従<rt>したが</rt></ruby>ってください。
                        </p>
                    );

                    this.handleSelect(SELECT_USER_UI_ID);
                    this.handleClosePopup();
                } else {
                    const data = result.Data;
                    if (data) {
                        this.setState({selectedTeacher: data});
                    }
                    this.props.onSetUserState(data);
                    // window.sessionStorage.setItem(['Allow_Login_Pctraining'], 'Allow');
                }
                this.setState({loaded: true});

                db.close();
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
                this.intervalId = null;
                return resolve();
            } catch (err){
                if (db) db.close();
                return reject(err);
            }
        });
    }

    getUserType = db => new Promise((resolve, reject) => {
        const objstore = db.transaction(['playerData'], 'readonly').objectStore('playerData');
        const request = objstore.get(userDataID);

        request.onsuccess = event => {
            db.close();
            return resolve(event.target.result);
        };
        request.onerror = err => {
            console.log('error : first checker');
            db.close();
            return reject(err);
        };
        request.onblock = err => {
            console.log('block : first checker');
            db.close();
            return reject(err);
        };
    });

    onSetUserData (isTeacher) {
        return new Promise(async (resolve, reject) => {
            const db = await connectDB();
            const objStore = db.transaction(['playerData'], 'readwrite').objectStore('playerData');

            const putRequest = objStore.put(
                {id: 'UserType', Data: isTeacher}
            );
            putRequest.onsuccess = () => {
                console.log('User : ', isTeacher ? 'Teacher' : 'Student');
                if (isTeacher) this.setState({selectedTeacher: isTeacher});
                this.props.onSetUserState(isTeacher);
                db.close();
                return resolve();
            };
            putRequest.onerror = err => {
                db.close();
                return reject(err);
            };
        });

    }

    handleSelectFreemode () {
        this.handleSelect(FREEMODE_UI_ID);
    }

    handleChangeToSchoolTeacher () {
        this.handleSelect(FIRST_TEACHER_UI_ID);
    }

    render () {
        return (
            <>
                {
                    this.state.loaded ?
                        <>
                            {
                                (this.state.selectStateId === SELECT_GAME_UI_ID || this.state.selectStateId === SELECT_USER_UI_ID) &&
                                <SelectGameUiComponent
                                    id={this.props.id}
                                    showModal={this.state.showModal}
                                    onRequestClose={this.handleClose}
                                    handleSelectScratch={() => {
                                        this.handleSelect(SELECT_MODE_UI_ID);
                                    }}
                                    handleSelectPCTraining={this.props.onOpenPcTraining}
                                />

                            }
                            {
                                this.state.selectStateId === SELECT_USER_UI_ID &&
                                <SelectUser
                                    id={this.props.id}
                                    onRequestClose={() => { }}
                                    onSelectedTeacher={() => {
                                        this.handleSelect(FIRST_TEACHER_UI_ID);
                                    }}
                                    onSelectedUser={() => {
                                        this.handleSelect(FIRST_USER_UI_ID);
                                    }}
                                    onSelectedStudent={() => {
                                        this.handleSelect(FIRST_STUDENT_UI_ID);
                                    }}
                                />

                            }
                            {
                                (this.state.selectStateId === FIRST_TEACHER_UI_ID || this.state.selectStateId === FIRST_USER_UI_ID) && (
                                    <TeacherUiComponent
                                        id={this.props.id}
                                        title={modalTitleList[this.state.selectStateId]}
                                        isSchoolTeacher={this.state.selectStateId === FIRST_TEACHER_UI_ID}
                                        showModal={this.state.showModal}
                                        // modalTitleList={modalTitleList}
                                        // selectedId={this.state.selectStateId}
                                        onRequestClose={this.handleClosedTeacherUI}
                                        onSelectBackButton={() => this.handleSelect(SELECT_USER_UI_ID)}
                                        onChangeToSchoolTeacher={this.handleChangeToSchoolTeacher}
                                    />
                                )
                            }
                            {
                                this.state.selectStateId === FIRST_STUDENT_UI_ID &&
                                <StudentUiComponent
                                    id={this.props.id}
                                    title={modalTitleList[this.state.selectStateId]}
                                    showModal={this.state.showModal}
                                    // modalTitleList={modalTitleList}
                                    // selectedId={this.state.selectStateId}
                                    onRequestClose={this.handleClosedStudentUI}
                                    onSelectBackButton={() => this.handleSelect(SELECT_USER_UI_ID)}
                                />

                            }
                            {
                                this.state.selectStateId === TEACHER_TOOL_ID &&
                                <TeacherToolUiComponent
                                    id={this.props.id}
                                    showModal={this.state.showModal}
                                    modalTitleList={modalTitleList}
                                    selectedId={this.state.selectStateId}
                                    onRequestClose={this.handleClose}
                                    onSelectBackButton={() => this.handleSelect(SELECT_MODE_UI_ID)}
                                />
                            }
                            {
                                this.state.selectStateId === SELECT_MODE_UI_ID &&
                                <SelectModeUiComponent
                                    id={this.props.id}
                                    title={modalTitleList[this.state.selectStateId]}
                                    showModal={this.state.showModal}
                                    // modalTitleList={modalTitleList}
                                    // selectedId={this.state.selectStateId}
                                    onRequestClose={this.handleClose}
                                    selectedTeacher={this.state.selectedTeacher}
                                    onSelectClassMode={() => this.handleSelect(CLASSMODE_UI_ID)}
                                    onSelectFreeMode={this.handleSelectFreemode}
                                    onSelectTeacherTool={() => this.handleSelect(TEACHER_TOOL_ID)}
                                    onSelectBackButton={() => this.handleSelect(SELECT_GAME_UI_ID)}
                                />

                            }
                            {
                                this.state.selectStateId === CLASSMODE_UI_ID &&
                                <ClassModeUi
                                    onRequestClose={this.handleClose}
                                    onSelectBackButton={() => {
                                        this.handleSelect(SELECT_MODE_UI_ID);
                                        this.setState({selectClassModeId: null});
                                    }}
                                    onStartLoadingProject={this.props.onStartLoadingProject}
                                    onSaveProject={this.props.onSaveProject}
                                    onSetAutosave={this.props.onSetAutosave}
                                    onSetSelectedClassMode={this.props.onSetSelectedClassMode}
                                    isSelectedClassModeId={this.state.selectClassModeId}
                                />

                            }
                            {
                                this.state.selectStateId === FREEMODE_UI_ID &&
                                <SaveloadUi
                                    onRequestClose={this.handleClose}
                                    onSelectBackButton={() => this.handleSelect(SELECT_MODE_UI_ID)}
                                    onStartLoadingProject={this.props.onStartLoadingProject}
                                    onSaveProject={this.props.onSaveProject}
                                    onSetAutosave={this.props.onSetAutosave}
                                    onSetSelectedClassMode={this.handleSetFreemode}
                                    fromFirstUI
                                />

                            }

                        </> :
                        <div className={styles.animWrapper}>
                            <LoadingAnimation
                                isUserData
                                textContents={[
                                    <p>ユーザーデータの<ruby>確認中<rt>かくにんちゅう</rt></ruby>です</p>,
                                    <p>しばらくお<ruby>待<rt>ま</rt></ruby>ちください</p>
                                ]}
                            />
                        </div>
                }
            </>
        );
    }
}

FirstUiLibraryComponent.propTypes = {
    id: PropTypes.string.isRequired,
    // intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    // title: PropTypes.string.isRequired,
    // isShowingBackButton: PropTypes.bool,
    onStartLoadingProject: PropTypes.func.isRequired,
    onSaveProject: PropTypes.func.isRequired,
    onSetAutosave: PropTypes.func.isRequired,
    onSetSelectedClassMode: PropTypes.func,
    onOpenPcTraining: PropTypes.func,
    isSelectedLoadUI: PropTypes.bool,
    selectedClassMode: PropTypes.string,
    // checkLogin:PropTypes.func,
    onSetUserState: PropTypes.func,
    userState: PropTypes.bool
};

const mapStateToProps = state => ({
    selectedClassMode: state.scratchGui.selectedClassMode,
    userState: state.scratchGui.userState
});

const mapDispatchToProps = dispatch => ({
    onOpenPcTraining: () => dispatch(openPcTraining()),
    onSetProjectID: name => dispatch(setProjectID(name)),
    // onSetProjectTitle: name => dispatch(setProjectTitle(name)),
    onSetSelectedClassMode: state => dispatch(setSelectedClassMode(state)),
    onSetUserState: state => dispatch(setUserState(state))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(FirstUiLibraryComponent));
