import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import PcTrainingComponent from '../components/pc-training/pc-training.jsx';

const PcTraining = ({onRequestClose}) => (
    <PcTrainingComponent
        id="pc-training-component"
        onRequestClose={onRequestClose}
    />
);


PcTraining.propTypes = {
    onRequestClose: PropTypes.func
};

export default injectIntl(PcTraining);
