import PropTypes from 'prop-types';
import React, {Fragment, useState, useEffect} from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Rnd} from 'react-rnd';

import styles from './card-help-rnd.css';

import rightArrow from './icon--next.svg';
import leftArrow from './icon--prev.svg';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import closeIcon from './icon--close.svg';

const CardHeader = ({onCloseCards, onShowAll, totalSteps, step, expanded}) => {
    const thisProps = {
        handleAllButtonClick: event => {
            onShowAll();
            event.stopPropagation();
        },
        handleRemoveButtonClick: event => {
            onCloseCards();
            event.stopPropagation();
        }
    };
    return (
        <div className={expanded ? styles.headerButtons : classNames(styles.headerButtons, styles.headerButtonsHidden)}>
            <div
                className={styles.allButton}
                onClick={onShowAll}
                onTouchEnd={thisProps.handleAllButtonClick}
            >
                <img
                    className={styles.helpIcon}
                    src={helpIcon}
                />
                {'ヘルプ'}
            </div>
            {totalSteps > 1 ? (
                <div className={styles.stepsList}>
                    {Array(totalSteps).fill(0)
                        .map((_, i) => (
                            <div
                                className={i === step ? styles.activeStepPip : styles.inactiveStepPip}
                                key={`pip-step-${i}`}
                            />
                        ))}
                </div>
            ) : null}
            <div className={styles.headerButtonsRight}>
                <div
                    className={styles.removeButton}
                    onClick={onCloseCards}
                    onTouchEnd={thisProps.handleRemoveButtonClick}
                >
                    <img
                        className={styles.closeIcon}
                        src={closeIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Close"
                        description="Title for button to close how-to card"
                        id="gui.cards.close"
                    />
                </div>
            </div>
        </div>
    );
};

const ImageStep = ({title, image}) => {
    const [isLoading, setIsLoading] = useState(true);
    const thisProps = {
        handleImageLoaded: () => {
            setIsLoading(false);
        },
        handleContextMenu: event => event.preventDefault()
    };
    return (
        <Fragment>
            <div className={styles.stepTitle}>
                {title}
            </div>
            <div className={styles.stepImageContainer}>
                {isLoading ? (<div>Now Loading...</div>) : null}
                <img
                    className={styles.stepImage}
                    draggable={false}
                    key={image} /* Use src as key to prevent hanging around on slow connections */
                    src={image}
                    onLoad={thisProps.handleImageLoaded}
                    onContextMenu={thisProps.handleContextMenu}
                />
            </div>
        </Fragment>
    );
};
ImageStep.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired
};

const VideoStep = ({title, video}) => {
    const [isVideoLoading, setVideoIsLoading] = useState(true);
    const thisProps = {
        handleVideoLoaded: () => {
            setVideoIsLoading(false);
        },
        handleContextMenu: event => event.preventDefault()
    };
    return (
        <Fragment>
            <div className={styles.stepTitle}>
                {title}
            </div>
            <div className={styles.stepImageContainer}>
                {isVideoLoading ? (<div>Now Loading...</div>) : null}
                <video
                    className={styles.stepVideoImage}
                    draggable={false}
                    key={video} /* Use src as key to prevent hanging around on slow connections */
                    src={video}
                    onLoadedData={thisProps.handleVideoLoaded}
                    onContextMenu={thisProps.handleContextMenu}
                    autoPlay
                    muted
                    loop
                    playsInline
                />
            </div>
        </Fragment>
    );
};
VideoStep.propTypes = {
    video: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired
};

const BackImageStep = ({image}) => {
    const thisProps = {
        handleContextMenu: event => event.preventDefault()
    };

    return (
        <Fragment>
            <div className={styles.stepBackImageContainer}>
                <img
                    className={styles.stepBackImage}
                    draggable={false}
                    key={image} /* Use src as key to prevent hanging around on slow connections */
                    src={image}
                    onContextMenu={thisProps.handleContextMenu}
                />
            </div>
        </Fragment>
    );
};
BackImageStep.propTypes = {
    image: PropTypes.string.isRequired
};
const NextPrevButtons = ({isRtl, onNextStep, onPrevStep, expanded}) => {
    const thisProps = {
        handleNextButtonClick: event => {
            onNextStep();
            event.stopPropagation();
        },
        handlePrevButtonClick: event => {
            onPrevStep();
            event.stopPropagation();
        }
    };
    return (
        <Fragment>
            {onNextStep ? (
                <div>
                    <div className={expanded ? (isRtl ? styles.leftCard : styles.rightCard) : styles.hidden} />
                    <button
                        className={expanded ? (isRtl ? styles.leftButton : styles.rightButton) : styles.hidden}
                        onClick={onNextStep}
                        onTouchEnd={thisProps.handleNextButtonClick}
                    >
                        <img
                            draggable={false}
                            src={isRtl ? leftArrow : rightArrow}
                        />
                    </button>
                </div>
            ) : null}
            {onPrevStep ? (
                <div>
                    <div className={expanded ? (isRtl ? styles.rightCard : styles.leftCard) : styles.hidden} />
                    <button
                        className={expanded ? (isRtl ? styles.rightButton : styles.leftButton) : styles.hidden}
                        onClick={onPrevStep}
                        onTouchEnd={thisProps.handlePrevButtonClick}
                    >
                        <img
                            draggable={false}
                            src={isRtl ? rightArrow : leftArrow}
                        />
                    </button>
                </div>
            ) : null}
        </Fragment>
    );
};

NextPrevButtons.propTypes = {
    expanded: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    onNextStep: PropTypes.func,
    onPrevStep: PropTypes.func
};
CardHeader.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired,
    step: PropTypes.number,
    totalSteps: PropTypes.number
};

const PreviewsStep = ({onShowAll}) => (
    <Fragment>
        <div className={styles.seeAll}>
            <div
                className={styles.seeAllButton}
                onClick={onShowAll}
                onTouchEnd={onShowAll}
            >
                <FormattedMessage
                    defaultMessage="See more"
                    description="Title for button to see more in how-to library"
                    id="gui.cards.see-more"
                />
            </div>
        </div>
    </Fragment>
);

PreviewsStep.propTypes = {
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }).isRequired,
    deckIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired
};

const CardsHelp = props => {
    const {
        activeDeckId,
        content,
        isRtl,
        locale,
        onActivateDeckFactory,
        onCloseCards,
        onDrag,
        onStartDrag,
        onEndDrag,
        onShowAll,
        onNextStep,
        onPrevStep,
        step,
        expanded,
        ...posProps
    } = props;
    let {x, y} = posProps;

    if (activeDeckId === null) return;

    const cardHorizontalDragOffset = 560;
    const cardVerticalDragOffset = expanded ? 257 : 0; // ~80% of card height, if expanded
    const menuBarHeight = 48;
    if (x === 0 && y === 0) {
        x += 0;
        y = window.innerHeight - 400 - menuBarHeight;
    }
    
    const steps = content[activeDeckId].steps;

    const [backImageLoaded, setBackImageLoaded] = useState(null);

    const loadingTranslateBackImage = async () => {
        const WAIT_MSECS = [10];
        for (let i = 0; i < WAIT_MSECS.length; i++) {
            await new Promise(resolve => setTimeout(resolve, WAIT_MSECS[i]));

            const loadingImage = steps[step].backImage; // translateImage(steps[step].backImage, locale);
            if (typeof loadingImage !== 'undefined') {
                setBackImageLoaded(loadingImage);
                return loadingImage;
            }
        }
        setBackImageLoaded(false);
    };

    useEffect(() => {
        const loadImageAsync = async () => {
            try {
                await loadingTranslateBackImage();
            } catch (error) {
                throw new Error('Image translation failed');
            }
        };
        loadImageAsync();
    }, [backImageLoaded, step, steps]);

    return (
    // Custom overlay to act as the bounding parent for the draggable, using values from above
        <>
            {backImageLoaded && (
                <BackImageStep
                    image={backImageLoaded}
                />
            )}
            <div
                className={styles.cardPlayableContainerOverlay}
                style={{
                    width: `${window.innerWidth + (2 * cardHorizontalDragOffset)}px`,
                    height: `${window.innerHeight - menuBarHeight + cardVerticalDragOffset}px`,
                    top: `${menuBarHeight}px`,
                    left: `${-cardHorizontalDragOffset}px`
                }}
            />
            
            <div
                className={styles.draggableLimit}
            >
                <Rnd
                    bounds="parent"
                    default={{
                        x: x,
                        y: y,
                        width: 540,
                        height: 400
                    }}
                    enableResizing={{
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        topRight: true,
                        bottomRight: true,
                        bottomLeft: true,
                        topLeft: true
                    }}
                    minWidth={560}
                    minHeight={400}
                    onDrag={onDrag}
                    onDragStart={onStartDrag}
                    onDragStop={onEndDrag}
                    className={styles.cardContainer}
                >
                    <div className={styles.card}>
                        <CardHeader
                            expanded={expanded}
                            step={step}
                            totalSteps={steps.length}
                            onCloseCards={onCloseCards}
                            onShowAll={onShowAll}
                        />
                        <div className={expanded ? styles.stepBody : styles.hidden}>
                            {steps[step].deckIds ? (
                                <PreviewsStep
                                    content={content}
                                    deckIds={steps[step].deckIds}
                                    onActivateDeckFactory={onActivateDeckFactory}
                                    onShowAll={onShowAll}
                                />
                            ) : (
                                (
                                    content[activeDeckId].useVideo ? (
                                        <VideoStep
                                            video={steps[step].video /* translateImage(steps[step].video, locale)*/}
                                            title={steps[step].title}
                                        />
                                    ) :
                                        <ImageStep
                                            image={steps[step].image /* translateImage(steps[step].image, locale)*/}
                                            title={steps[step].title}
                                        />
                                )
                            )}
                            {steps[step].trackingPixel && steps[step].trackingPixel}
                        </div>
                        <NextPrevButtons
                            expanded={expanded}
                            isRtl={isRtl}
                            onNextStep={step < steps.length - 1 ? onNextStep : null}
                            onPrevStep={step > 0 ? onPrevStep : null}
                        />
                    </div>
                </Rnd>
            </div>
        </>
    );
};

CardsHelp.propTypes = {
    activeDeckId: PropTypes.string.isRequired,
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }),
    dragging: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onShowAll: PropTypes.func,
    onShrinkExpandCards: PropTypes.func.isRequired,
    onStartDrag: PropTypes.func,
    showVideos: PropTypes.bool,
    step: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number
};

CardsHelp.defaultProps = {
    showVideos: true
};

export {
    CardsHelp as default,
    // Others exported for testability
    ImageStep
};
