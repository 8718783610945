/* eslint-disable prefer-template */
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {intlShape} from 'react-intl';
import bowser from 'bowser';
import {alert, confirm} from './overlay.js';
import {connectDB} from './indexedDB.jsx';
import downloadBlob from './download-blob';
import Minizip from 'minizip-asm.js';
import dotenv from 'dotenv';

dotenv.config();
const env = process.env;
const ProjectAlertHOC = function (WrappedComponent) {
    class projectAlertHocComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'handleBeforeUnload',
                'createDebugZip'
            ]);
        }


        componentDidMount () {
            if (!bowser.mac) window.onbeforeunload = this.handleBeforeUnload;

            window.ownAlert = text => this.props.alert(text);
            window.ownConfirm = text => this.props.confirm(text);

            // デバッグ用
            document.addEventListener('errorLoadProject', async () => {
                //const accept = await window.ownConfirm(
                //    <p>
                //        セーブデータのロードに<ruby>失敗<rt>しっぱい</rt></ruby>しました。<br />
                //        デバッグ<ruby>用<rt>よう</rt></ruby>のデータを<ruby>作成<rt>さくせい</rt></ruby>しますか？
                //    </p>
                //);
                await window.ownAlert(
                    <p>
                        セーブデータのロードに<ruby>失敗<rt>しっぱい</rt></ruby>しました。<br />
                        デバッグ<ruby>用<rt>よう</rt></ruby>のデータを<ruby>作成<rt>さくせい</rt></ruby>しますか？
                    </p>
                );
                //if (accept) {
                //    this.createDebugZip();
                //}
            });
        }

        componentWillUnmount () {
            if (!bowser.mac) window.onbeforeunload = () => { };
            window.ownAlert = () => { };
            window.ownConfirm = () => { };
        }

        createDebugZip () {
            return new Promise(async resolve => {

                const db = await connectDB();

                const projectObjStore = db.transaction(['projectData'], 'readwrite').objectStore('projectData');
                const projectRequest = projectObjStore.getAll();


                const mzip = new Minizip();

                await new Promise(projectResolve => {
                    projectRequest.onsuccess = async () => {
                        const promises = [];
                        const datas = projectRequest.result;

                        if (datas.length > 0) {
                            datas.forEach(data => {
                                const promise = new Promise(async resolve1 => {
                                    mzip.append(data.id, await data.Data.arrayBuffer(), {compressLevel: 0, password: env.ZIP_DEBUG_UNLOCK_KEY});
                                    resolve1();
                                });
                                promises.push(promise);
                            });

                            await Promise.all(promises);
                        } else {
                            mzip.append('non-blank.txt', 'zip-file for Debug', {compressLevel: 0, password: env.ZIP_DEBUG_UNLOCK_KEY});
                        }
                        projectResolve();
                    };
                });

                const playerObjStore = db.transaction(['playerData'], 'readwrite').objectStore('playerData');
                const playerRequest = playerObjStore.getAll();
                await new Promise(playerResolve => {
                    playerRequest.onsuccess = async () => {
                        const promises = [];
                        const datas = playerRequest.result;
                        if (datas.length > 0) {
                            datas.forEach(data => {
                                const promise = new Promise(async dres => {
                                    mzip.append(`${data.id}.txt`, await JSON.stringify(data), {compressLevel: 0, password: env.ZIP_DEBUG_UNLOCK_KEY});
                                    dres();
                                });
                                promises.push(promise);
                            });

                            await Promise.all(promises);
                        } else {
                            mzip.append('non-blank2.txt', 'zip-file for Debug', {compressLevel: 0, password: env.ZIP_DEBUG_UNLOCK_KEY});
                        }
                        playerResolve();
                    };
                });


                downloadBlob('ポケモンプログラミングスタートキット_デバッグ用データ.zip', new Blob([mzip.zip()]));
                resolve();

            });
        }

        handleBeforeUnload (event) {
            event.preventDefault();
            event.returnValue = '';
        }

        render () {
            const {
                projectChanged,
                isActiveAutosave,
                alert,
                confirm,
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }
    
    projectAlertHocComponent.propTypes = {
        intl: intlShape.isRequired,
        projectChanged: PropTypes.bool,
        autosaveState: PropTypes.bool,
        alert: PropTypes.func,
        confirm: PropTypes.func
        // projectId: PropTypes.string
    };

    const mapStateToProps = state => ({
        projectChanged: state.scratchGui.projectChanged,
        isActiveAutosave: state.scratchGui.autosaveState
    });

    const mapDispatchToProps = dispatch => ({
        alert: text => alert(dispatch, text),
        confirm: text => confirm(dispatch, text)
    });

    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );

    return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(projectAlertHocComponent);
};

export {
    ProjectAlertHOC as default
};
