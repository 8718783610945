import projectData from './project-data';

/* eslint-disable import/no-unresolved */

/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
    const projectJson = projectData(translator);
    return [{
        id: 0,
        assetType: 'Project',
        dataFormat: 'JSON',
        data: JSON.stringify(projectJson)
    }
    ];
};


export default defaultProject;
