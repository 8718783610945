// Action types
const SET_SELECTED_CLASS_MODE = 'scratch-gui/selected-mode/SET_SELECTED_CLASS_MODE';

// Available modes
export const ClassModeActionTypes = {
    FREE: 'FREE',
    CLASS_MAIN: 'CLASS_MAIN',
    CHARIZARD: 'CHARIZARD',
    BOOK: 'BOOK'
};

// Initial state
const initialState = 'FREE';

// Reducer function
const reducer = (state, action) => {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_SELECTED_CLASS_MODE:
        return action.mode;
    default:
        return state;
    }
};

// Action creators
const setSelectedClassMode = modeId => ({
    type: SET_SELECTED_CLASS_MODE,
    mode: modeId
});

export {
    reducer as default,
    initialState as selectedClassModeInitialState,
    setSelectedClassMode
};
