export function connectDB (){
    return new Promise((resolve, reject) => {
        var isValidIndexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB
        if(!isValidIndexedDB){
            console.log('indexedDBが使用できません。Chrome,Edge,Safariなどの推奨端末をお使いください。')
            return reject('Invalid indexedDB');
        }

        const request = indexedDB.open('scratchGui');

        request.onsuccess = ev => resolve(ev.target.result);
        request.onerror = ev => reject('fails to open db/error');
        request.onblock = ev => reject('fails to open db/block');

        request.onupgradeneeded = event = function (event) {
            const db = event.target.result;

            db.createObjectStore('projectData', {keyPath: 'id'});
            db.createObjectStore('playerData', {keyPath: 'id'});
        };
    });
}
