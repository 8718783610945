/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* titleElement内、<span id="highlightedCardTitle"> </span>で囲めば強調された大きさになります */
import React from 'react';

export const data = [
    {
        image: 'static/1c5d78b0a3f9e24cd6b54fd2e890120a.png',
        id: 'classmode_1',
        name: 'classmode_1',
        mode: 'CLASS_MAIN',
        titleEng: 'scratchPokemonGame',
        titleJa: 'ポケモンのゲームを作ってみよう',
        titleElement: (<>ポケモンのゲームを<br /><span><ruby>作<rt>つく</rt></ruby>ってみよう</span></>),
        descriptionTableElements: {
            target: (<><ruby>小学<rt>しょうがく</rt></ruby>3<ruby>年生<rt>ねんせい</rt></ruby>～</>),
            subject: (<><ruby>総合的<rt>そうごうてき</rt></ruby>な<ruby>学習<rt>がくしゅう</rt></ruby>の<ruby>時間<rt>じかん</rt></ruby></>),
            time: (<>1～2<ruby>時間分<rt>じかんぶん</rt></ruby></>),
            goal: (<>プログラミングの<ruby>基本<rt>きほん</rt></ruby>を<br />ゲーム<ruby>作<rt>づく</rt></ruby>りを<ruby>通<rt>とお</rt></ruby>して<ruby>学<rt>まな</rt></ruby>ぼう</>)

        },
        implemented: true
    },
    {
        image: 'static/e7908b45d6af34c2be9178d0f562c3e1.png',
        id: 'classmode_2',
        name: 'classmode_2',
        mode: 'CHARIZARD',
        titleEng: 'flyCharizard',
        titleJa: '多角形と円 ～ポケモンと一緒にコンピュータで図形を描こう～',
        titleElement: (<><span><ruby>多角形<rt>たかくけい</rt></ruby>と<ruby>円<rt>えん</rt></ruby></span><br />～ポケモンと<ruby>一緒<rt>いっしょ</rt></ruby>にコンピュータで<br /><ruby>図形<rt>ずけい</rt></ruby>を<ruby>描<rt>か</rt></ruby>こう～</>),
        descriptionTableElements: {
            target: (<><ruby>小学<rt>しょうがく</rt></ruby>5<ruby>年生<rt>ねんせい</rt></ruby></>),
            subject: (<><ruby>算数<rt>さんすう</rt></ruby></>),
            time: (<>1～2<ruby>時間分<rt>じかんぶん</rt></ruby></>),
            goal: (<>ポケモンと<ruby>一緒<rt>いっしょ</rt></ruby>に<ruby>多角形<rt>たかくけい</rt></ruby>を<br />プログラミングして<br /><ruby>描<rt>か</rt></ruby>いてみよう</>)
        },
        implemented: true
    },
    {
        image: 'static/96428a1d3e6bc7f59f5d02c8a174b3e6.png',
        id: 'classmode_3',
        name: 'classmode_3',
        mode: 'BOOK',
        titleEng: 'pokemonStoryMaking',
        titleJa: 'ポケモンたちと物語を作ってみよう',
        titleElement: (<>ポケモンたちと<br /><ruby>物語<rt>ものがたり</rt></ruby>を<ruby>作<rt>つく</rt></ruby>ってみよう<br /></>),
        descriptionTableElements: {
            target: (<><ruby>小学<rt>しょうがく</rt></ruby>4<ruby>年生<rt>ねんせい</rt></ruby>～</>),
            subject: (<><ruby>国語<rt>こくご</rt></ruby>・<ruby>総合的<rt>そうごうてき</rt></ruby>な<ruby>学習<rt>がくしゅう</rt></ruby>の<ruby>時間<rt>じかん</rt></ruby></>),
            time: (<>6～7<ruby>時間分<rt>じかんぶん</rt></ruby></>),
            goal: (<>ポケモンたちが<ruby>出<rt>で</rt></ruby>てくる<br />ものがたりをプログラミングで<br />つくってみよう</>)
        },
        implemented: true
    }
];
